import {SearchOutlined, FilterFilled} from '@ant-design/icons';
import {Button, Checkbox, Input, Space} from 'antd';
import {AuthContext} from 'context/ContextProvider';
import React, {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';

const ServiceRiskSearchCustomFilter = (filterColumn, index) => {
  return {
    // filtered: true,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const dispatch = useDispatch();
      const {filterList, filteredData: data} = useSelector(
        (state) => state.srCols,
      );
      const [choices, setChoices] = React.useState([]);
      const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
      const [inputValue, setInputValue] = React.useState('');
      const [isSearchClicked, setIsSearchClicked] = React.useState(false);
      const {country} = useContext(AuthContext);
      React.useEffect(() => {
        if (isSearchClicked === false) {
          setSelectedCheckboxes(
            filterList[index].length != 0 ? [...filterList[index]] : [],
          );
          setSelectedKeys(
            filterList[index].length != 0 ? [...filterList[index]] : [],
          );

          //confirm();
        }
      }, [filterList[index]]);

      React.useEffect(() => {
        if (data != null && data != undefined && data?.length > 0) {
          setChoices(
            Array.from(
              new Set(
                data
                  ?.filter((e) => e.ctry === country || country === 'ALL')
                  ?.map((item) => {
                    return item[index] !== null ? item[index] : '';
                  }),
              ),
            ),
          );
        }
      }, [data, country]);
      // console.log(country,choices[0],"efefwewefwfwfoijwefwoefihn");
      const options = inputValue
        ? choices.filter((value) =>
            value.toLowerCase().includes(inputValue.toLowerCase()),
          )
        : choices;

      const handleReset = () => {
        setInputValue('');
        //setOptions(choices);
        dispatch(
          serviceRiskActions.updateFilterList({
            filterList: {
              [index]: [],
            },
          }),
        );
        setSelectedCheckboxes([]); // Uncheck all checkboxes
        setSelectedKeys([]);
        clearFilters();
        //confirm({closeDropdown: false});
      };
      const handleSearch = () => {
        setIsSearchClicked(true);
        dispatch(
          serviceRiskActions.updateFilterList({
            filterList: {
              [index]: [...selectedKeys],
              //[index]: selectedKeys[0]?.toString() || '',
            },
          }),
        );
        setIsSearchClicked(false);
      };
      React.useEffect(() => {
        setSelectedKeys([...selectedCheckboxes]);
      }, [selectedCheckboxes]);
      // console.log(
      //   selectedCheckboxes,
      //   'selectedCheckboxes',
      //   inputValue,
      //   'inputValue',
      // );
      return (
        <div style={{padding: 8, maxHeight: '500px'}}>
          <div>
            <Input
              placeholder={`Search ${filterColumn}`}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              style={{width: '100%', marginBottom: 8, display: 'block'}}
            />
          </div>

          <Space style={{width: '100%'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                maxHeight: '300px',
                // overflowX: 'scroll',
                overflowY: 'auto',
                minWidth: '250px',
              }}>
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  width: '100%',
                  gap: '8px',
                }}>
                <Checkbox
                  checked={
                    selectedCheckboxes.length === options.length &&
                    options.length !== 0
                  }
                  onChange={(e) => {
                    if (
                      selectedCheckboxes.length === 0 ||
                      selectedCheckboxes.length < options.length
                    ) {
                      setSelectedCheckboxes(options);
                    } else {
                      setSelectedCheckboxes([]);
                    }
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    Select All
                  </div>
                </Checkbox>
              </div>
              {
                // sort alphabetically
                options?.sort()?.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      width: '100%',
                      gap: '8px',
                    }}>
                    <Checkbox
                      value={option}
                      checked={selectedCheckboxes.includes(option)}
                      onChange={(e) => {
                        if (selectedCheckboxes.includes(e.target.value)) {
                          setSelectedCheckboxes(
                            selectedCheckboxes.filter(
                              (item) => item !== e.target.value,
                            ),
                          );
                        } else {
                          setSelectedCheckboxes((prev) => [
                            ...prev,
                            e.target.value,
                          ]);
                        }
                      }}
                      style={{
                        marginRight: '8px',
                        width: '100%',
                        height: '16px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          // minWidth: '200px',
                        }}>
                        {option}
                      </div>
                    </Checkbox>
                  </div>
                ))
              }
            </div>
          </Space>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Button
              type='primary'
              onClick={handleSearch}
              icon={<SearchOutlined />}
              size='small'
              style={{width: 90}}>
              Search
            </Button>
            <Button onClick={handleReset} size='small' style={{width: 90}}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    onFilter: (value, record) => {
      return Array.isArray(value)
        ? value.includes(record[index].toString().toLowerCase())
        : record[index].toString() == value;
    },
    filterIcon: () => {
      const {filterList} = useSelector((state) => state.srCols);
      return React.createElement(
        index == 'prod_desc' ? SearchOutlined : FilterFilled,
        {
          style: {color: filterList[index] != '' ? '#1890ff' : '#bfbfbf'},
        },
      );
    },
  };
};

export default ServiceRiskSearchCustomFilter;
