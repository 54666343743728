import React from 'react';

const PercentageBar = ({percentage, filledColor, unfilledColor, record}) => {
  const clampedPercentage = Math.min(percentage, 100);
  const showLine = percentage >= 100;
  const filledStyle = {
    width: `${clampedPercentage}%`,
    backgroundColor: filledColor,
    position: 'relative',
  };
  const unfilledStyle = {
    width: `${100 - clampedPercentage}%`,
    position: 'relative',
    backgroundColor: unfilledColor,
  };
  return (
    <>
      <div style={{width: 104, height: '49px', position: 'absolute', top: 0}}>
        <div
          className='vertical-line'
          onMouseDown={(e) => e.stopPropagation()}
          style={{
            left: `${String(record['mmtd_day'])}` + `%`,
            zIndex: 1,
            height: '49px',
            top: 0,
            position: 'absolute',
          }}
        />
      </div>
      <div className='percentage-bar'>
        <div
          className='filled'
          style={{
            width: '100%',
            height: '100%',
            background: unfilledColor,
            position: 'relative',
          }}>
          <div
            style={{
              width: `${clampedPercentage}%`,
              height: '100%',
              background: filledColor,
              position: 'relative',
            }}
          />
        </div>
        <div
          style={{
            width: 3,
            height: '100%',
            background: 'white',
            position: 'relative',
          }}
        />
        <div
          className='unfilled'
          style={{
            width: '30px',
            height: '100%',
            background: percentage > 100 ? filledColor : unfilledColor,
          }}></div>
        {/* {showLine && <div className='indicator-line'></div>} */}
        <span className='percentage'>{`${percentage}%`}</span>
      </div>
    </>
  );
};

export default React.memo(PercentageBar);
