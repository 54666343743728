import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
import {store} from 'redux/store';
import {FilterFilled} from '@ant-design/icons';
const ServiceRiskStockInHandFilter = (index) => {
  return {
    filters: [],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const dispatch = useDispatch();
      const {filterList} = useSelector((state) => state.srCols);
      const [rangeKeys, setRangeKeys] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      });
      // console.log('selectedKeys2: ', selectedKeys);
      // console.log('rangeKeys 2: ', rangeKeys);
      useEffect(() => {
        if (filterList[index].length > 0) {
          setRangeKeys({
            1: filterList[index].includes('1'),
            2: filterList[index].includes('2'),
            3: filterList[index].includes('3'),
            4: filterList[index].includes('4'),
            5: filterList[index].includes('5'),
          });
        }
      }, [filterList[index]]);
      return (
        <div style={{padding: 8, display: 'flex', flexDirection: 'column'}}>
          <Checkbox
            checked={rangeKeys[1]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                1: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(1),
                  height: 15,
                  width: 15,
                }}
              />
              Inv. Equal or Below 0
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[2]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                2: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(2),
                  height: 15,
                  width: 15,
                }}
              />
              Inv. Between 0 - Minimum
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[3]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                3: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(3),
                  height: 15,
                  width: 15,
                }}
              />
              Inv. Between Min - Target
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[4]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                4: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(4),
                  height: 15,
                  width: 15,
                }}
              />
              Inv. Between Target - Max
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[5]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                5: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(5),
                  height: 15,
                  width: 15,
                }}
              />
              Inv. Above Maximum
            </div>
          </Checkbox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            }}>
            <Button
              type='primary'
              onClick={() => {
                setSelectedKeys(
                  Object.keys(rangeKeys).filter((key) => rangeKeys[key]),
                );
                // currStockInHandFilter = Object.keys(rangeKeys).filter(
                //   (key) => rangeKeys[key],
                // );
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: Object.keys(rangeKeys).filter(
                        (key) => rangeKeys[key],
                      ),
                    },
                  }),
                );
                if (
                  !rangeKeys[1] &&
                  !rangeKeys[2] &&
                  !rangeKeys[3] &&
                  !rangeKeys[4] &&
                  !rangeKeys[5]
                ) {
                  setSelectedKeys([]);
                }

                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Search
            </Button>
            <Button
              onClick={() => {
                // currStockInHandFilter = [];
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: [],
                    },
                  }),
                );
                setRangeKeys({
                  1: false,
                  2: false,
                  3: false,
                  4: false,
                  5: false,
                });
                setSelectedKeys([]);
                clearFilters();
                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () => {
      const {filterList} = useSelector((state) => state.srCols);
      return React.createElement(FilterFilled, {
        style: {color: filterList[index].length > 0 ? '#1890ff' : '#bfbfbf'},
      });
    },
    // onFilter: (value, record) => {
    //   const state = store.getState();
    //   const filterList = state.srCols.filterList;
    //   return filterList.curr_stock_in_hand
    //     .map((e) => parseInt(e))
    //     .includes(parseInt(record['w1_cc']));
    // },
    filterSearch: true,
  };
};

export default ServiceRiskStockInHandFilter;
