import React, {useEffect} from 'react';
import LocaleContextProvider from './LocaleContextProvider';

import {createContext} from 'react';

const AuthContext = createContext({});

const AuthContextProvider = ({children}) => {
  const [auth, setAuth] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [country, setCountry] = React.useState('');
  const [countryList, setCountryList] = React.useState([]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loading,
        setLoading,
        country,
        setCountry,
        countryList,
        setCountryList,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const AppContextProvider = ({children}) => {
  return (
    <AuthContextProvider>
      <LocaleContextProvider>{children}</LocaleContextProvider>
    </AuthContextProvider>
  );
};

export {AuthContext};

export default React.memo(AppContextProvider);
