import {Select} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

const BrandDropDown = ({onChange}) => {
  const {headerDropDowns} = useSelector((state) => state.prSims);
  const updateBrandFun = React.useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <>
      <Select
        value={headerDropDowns.selectedBrand}
        placeholder={'Select Brand'}
        style={{
          width: 140,
        }}
        onChange={updateBrandFun}
        options={headerDropDowns.brandsList
          .map((brand, index) => ({
            key: index,
            value: brand.value,
            label: brand.label,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
      />
    </>
  );
};

export default React.memo(BrandDropDown);
