import axios from 'components/services/lib/axios';
import {GetCommentsURL} from 'components/services/lib/URLs';
import * as XLSX from 'xlsx';

const downloadComment = async (countryList, country) => {
  console.log('country: ', country);
  try {
    const {data} = await axios.post(`${GetCommentsURL}`, {
      countryList,
    });

    let formattedData = data;
    if (country !== 'ALL') {
      formattedData = processData(data, country);
    }

    const ws = XLSX.utils.json_to_sheet(formattedData);
    ws['!cols'] = [
      {wch: 10},
      {wch: 10},
      {wch: 10},
      {wch: 10},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 10},
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const currDate = new Date();

    XLSX.writeFile(
      wb,
      `Comments (${currDate.getDay().toString().padStart(2, '0')}-${(
        currDate.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-${currDate
        .getFullYear()
        .toString()
        .padStart(4, '0')}).xlsx`,
      {
        bookType: 'xlsx',
        type: 'blob',
      },
    );
  } catch (error) {
    console.error('Error fetching or processing data:', error);
  }
};

const processData = (data, selectedCountry) => {
  return data
    .filter((item) => {
      return item.locationID === selectedCountry;
    })
    .map((item) => {
      const timestamp = parseInt(item.updatedAt, 10);
      const formattedDate = new Date(timestamp).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      return {
        Material: item.productID,
        LocationID: item.locationID,
        Panda: item.Panda,
        GMC: item.GMC,
        Description: item.Description,
        Comments: item.Comments,
        UpdatedBy: item.updatedBy,
        UpdatedAt: formattedDate,
      };
    });
};

export default downloadComment;
