import React, {useState} from 'react';
import InventoryFrame from './InventoryFrame';
import InventoryMovementDetail from './InventoryMovementDetail';
import InventoryStatusDetail from './InventoryStatusDetail';
import DelayIndicators from './DelayIndicators';
import ExpireDate from './ExpireDate';
import Pda from './Pda';
import {CheckCircleOutlined} from '@ant-design/icons';
import {ReactComponent as BlackDot} from 'assets/icon/BlackDot.svg';
import {DatePicker, Empty} from 'antd';

import axiosConfig from 'components/services/lib/axios';
import {useParams} from 'react-router-dom';
import {useContext} from 'react';
import {AvlbStockTooltip} from '../KpiStatus/AvailableStockToolTip';
import {AuthContext} from 'context/ContextProvider';

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

export const InventoryDeliveryDataContext = React.createContext([
  [],
  () => {},
  () => {},
]);

const InventoryDeliveryDataProvider = ({children}) => {
  const [statusData, setStatusData] = useState([]);
  const {simId} = useParams();
  const [projId, locId] = simId.split(',');
  const fetchDeliveryData = () => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post('/deliverydata', {material: projId, location_id: locId})
        .then((res) => {
          if (res?.data) {
            const updateUniqueKeys = (data, keyGenerator) => {
              data.forEach((item) => {
                item.key = keyGenerator(); // Update the key with a new unique value
                if (item.children && item.children.length > 0) {
                  updateUniqueKeys(item.children, keyGenerator); // Recursively update child keys
                }
              });
            };
            let currentKey = 1;
            const generateUniqueKey = () => {
              return currentKey++;
            };
            updateUniqueKeys(res?.data, generateUniqueKey);
            setStatusData(res?.data);
          } else {
            setStatusData([]);
          }
          resolve(res?.data);
          // fetchData();
        })
        .catch((err) => {
          console.error('Error fetching data from API:', err);
          reject(err);
        });
    });
  };

  return (
    <InventoryDeliveryDataContext.Provider
      value={[statusData, setStatusData, fetchDeliveryData]}>
      <InventoryMovement />
    </InventoryDeliveryDataContext.Provider>
  );
};
const InventoryMovement = () => {
  const {auth} = useContext(AuthContext);
  const [tabSelection, setTabSelection] = React.useState('2');
  const updateTabSelection = (key) => {
    setTabSelection(key);
  };
  const [statusData, setStatusData, fetchDeliveryData] = useContext(
    InventoryDeliveryDataContext,
  );
  const data = [
    {
      key: 1,
      srno: '1',
      orderId: 'SLS_ORD_NO20234',
      quantity: '40000',
      uom: 'Package',
      eta: '12 June 2023',
      omptargetdate: 'No Data',
      delayindicator: '28 days',

      items: [
        {
          title: 'GI / Flight Booking',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'finish',
          icon: (
            <CheckCircleOutlined style={{color: '#3a8d00', fontSize: '32px'}} />
          ),
        },
        {
          title: 'Take Off',
          description: 'No Data',
          status: 'finish',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'Landing',
          description: 'No Data',
          status: 'finish',
          icon: (
            <CheckCircleOutlined style={{color: '#F5AC25', fontSize: '32px'}} />
          ),
        },
        {
          title: 'Customs Clearance',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'process',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'GR',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QC Testing',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QA Release',
          description: 'No Data',
          status: 'wait',
        },
      ],
    },
    {
      key: 2,
      srno: '2',
      orderId: 'SLS_ORD_NO20234',
      quantity: '40000',
      uom: 'Package',
      eta: '12 June 2023',
      omptargetdate: 'No Data',
      delayindicator: '28 days',
      items: [
        {
          title: 'GI / Flight Booking',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'finish',
        },
        {
          title: 'Take Off',
          description: 'No Data',
          status: 'finish',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'Landing',
          description: 'No Data',
          status: 'finish',
        },
        {
          title: 'Customs Clearance',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'process',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'GR',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QC Testing',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QA Release',
          description: 'No Data',
          status: 'wait',
        },
      ],
    },
    {
      key: 3,
      srno: '3',
      orderId: 'SLS_ORD_NO20234',
      quantity: '40000',
      uom: 'Package',
      eta: '12 June 2023',
      omptargetdate: 'No Data',
      delayindicator: '28 days',
      items: [
        {
          title: 'GI / Flight Booking',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'finish',
        },
        {
          title: 'Take Off',
          description: 'No Data',
          status: 'finish',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'Landing',
          description: 'No Data',
          status: 'finish',
        },
        {
          title: 'Customs Clearance',
          description: <DatePicker onChange={onChange} bordered={false} />,
          status: 'process',
          icon: <BlackDot style={{color: '#000000'}} />,
        },
        {
          title: 'GR',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QC Testing',
          description: 'No Data',
          status: 'wait',
        },
        {
          title: 'QA Release',
          description: 'No Data',
          status: 'wait',
        },
      ],
    },
  ];
  const [loading, setLoading] = useState(false);
  const {simId} = useParams();
  const [projId, locId] = simId.split(',');

  React.useEffect(() => {
    setLoading(true);
    axiosConfig
      .post('/deliverydata', {
        material: projId,
        location_id: locId,
        userName: auth.tokenData.name,
      })
      .then((res) => {
        setLoading(false);

        if (res?.data) {
          const updateUniqueKeys = (data, keyGenerator) => {
            data.forEach((item) => {
              item.key = keyGenerator(); // Update the key with a new unique value
              if (item.children && item.children.length > 0) {
                updateUniqueKeys(item.children, keyGenerator); // Recursively update child keys
              }
            });
          };
          let currentKey = 1;
          const generateUniqueKey = () => {
            return currentKey++;
          };
          updateUniqueKeys(res?.data, generateUniqueKey);
          setStatusData(res?.data);
        } else {
          setStatusData([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error fetching data from API:', err);
      });
  }, [locId, projId]);

  return (
    <div style={{marginLeft: '24px', marginRight: '24px'}}>
      <InventoryFrame onTabSelection={updateTabSelection} />

      {tabSelection === '1' ? (
        <>
          {statusData?.map((record, index) => (
            <div key={index}>
              <InventoryMovementDetail record={record} />
            </div>
          ))}
          {statusData.length === 0 && (
            <div>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}

          <DelayIndicators />
        </>
      ) : (
        <>
          <InventoryStatusDetail />
          <ExpireDate />
          <Pda />
        </>
      )}
    </div>
  );
};

export default React.memo(InventoryDeliveryDataProvider);
