import axios from 'axios';

export default axios.create({
  baseURL: window.location.href.includes('localhost')
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL,
});

// import axios from 'axios';

// export default axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// });
