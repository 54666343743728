import Checkbox from 'antd/es/checkbox';
import axios from 'components/services/lib/axios';
import useProjectionData from 'pages/ServiceRiskSimulation/Table/useProjectionData';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {RunSimulationDataContext} from '../Comments/operations/modal/RunSimulationModal';
import Tooltip from 'antd/es/tooltip';

const KeysFrame = ({style, showKeys, keysFilter, setKeysFilter, record}) => {
  const [keysFilterLocal, setKeysFilterLocal] = useState({
    bel: false,
    min: false,
    tar: false,
    max: false,
    abv: false,
    none: false,
  });

  useEffect(() => {
    if (setKeysFilter !== undefined) {
      setKeysFilter({...keysFilterLocal});
    }
  }, [keysFilterLocal, setKeysFilter]);

  const [loading, setLoading] = useState(true);
  const [projectionTableData, fetchProjectionData] = useProjectionData(
    loading,
    setLoading,
    record,
  );

  useEffect(() => {
    fetchProjectionData();
  }, []);

  const [projectionKey, setProjectionKey] = React.useState(null);
  const {simId} = useParams();
  // console.log('simId: ', simId);
  let [projId, locId] = [];
  if (record) {
    console.log(record, 'record');
  }
  [projId, locId] = simId
    ? simId.split(',')
    : record
    ? [record.product_id, record.location_id]
    : ['', ''];

  useEffect(() => {
    const fetchProjectionKey = async () => {
      // console.log('projId: ', locId, projId);
      try {
        const res = await axios.post('/getsimprojection', {
          material: projId,
          location_id: locId,
        });
        const desiredObject = res.data.find(
          (item) => item.vmi === 'days_of_supply',
        );
        setProjectionKey(desiredObject);
      } catch (err) {
        console.error('Error fetching data from API:', err);
        throw err;
      }
    };

    fetchProjectionKey();
  }, [projId, locId]);
  // console.log('fetchProjectionKey: ', projectionKey);

  return (
    <div
      className={'keys-frame ' + (showKeys ? '' : 'hide-checkbox')}
      style={{...style}}>
      <div className='keys-label'>Keys:</div>
      <div className='keys-container'>
        <div className='keys-group'>
          <div className='keys-item'>
            {/* <input
              type='checkbox'
              className='keys-checkbox'
              checked={keysFilterLocal.bel}
              onChange={(e) => {
                setKeysFilterLocal({...keysFilterLocal, bel: e.target.checked});
              }}
            /> */}
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.bel}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    bel: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-1' />
            <div className='keys-text'>Inv. Equal or Below 0</div>
          </div>
          <div className='keys-item'>
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.min}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    min: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-2' />
            <div className='keys-text'>
              Inv. Between 0 - Min
              <span style={{fontWeight: 'bold'}}>
                {' '}
                <Tooltip title='DOS by calendar days'>
                  ({projectionKey?.min_coverage_time || 'N/A'})
                </Tooltip>
              </span>
            </div>
          </div>
          <div className='keys-item'>
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.tar}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    tar: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-3' />
            <div className='keys-text'>
              Inv. Between Min - Target
              <span style={{fontWeight: 'bold'}}>
                {' '}
                <Tooltip title='DOS by calendar days'>
                  ({projectionKey?.target_coverage_time || 'N/A'})
                </Tooltip>
              </span>
            </div>
          </div>
          <div className='keys-item'>
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.max}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    max: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-4' />
            <div className='keys-text'>
              Inv. Between Target - Max
              <span style={{fontWeight: 'bold'}}>
                <Tooltip title='DOS by calendar days'>
                  ({projectionKey?.max_coverage_time || 'N/A'})
                </Tooltip>
              </span>
            </div>
          </div>
          <div className='keys-item'>
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.abv}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    abv: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-5' />
            <div className='keys-text'>
              Inv. Above Max
              <span style={{fontWeight: 'bold'}}>
                <Tooltip title='DOS by calendar days'>
                  ({projectionKey?.max_coverage_time || 'N/A'})
                </Tooltip>
              </span>
            </div>
          </div>
          <div className='keys-item'>
            {showKeys && (
              <Checkbox
                checked={keysFilterLocal.none}
                onChange={(e) =>
                  setKeysFilterLocal({
                    ...keysFilterLocal,
                    none: e.target.checked,
                  })
                }
              />
            )}
            <div className='keys-color keys-color-6' />
            <div className='keys-text'>Equal to 0 and No Target Inv.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeysFrame;
