import React, {useEffect, useState} from 'react';
import {Tooltip} from 'antd';
import axios from 'components/services/lib/axios';
import {useParams} from 'react-router-dom';

// const AvlbStockTooltip = React.useContext({});
// export {AvlbStockTooltip};
const AvailableStockToolTip = (props) => {
  const [toolTipData, setToolTipData] = useState(null);
  const {simId} = useParams();
  const [projId, locId] = simId.split(',');

  const fetchData = async () => {
    try {
      console.log(projId, locId);
      const response = await axios.post('/proj_astock_tooltip', {
        material: projId,
        location_id: locId,
      });
      setToolTipData(response.data);
    } catch (error) {
      console.error('Error fetching tooltip data:', error);
    }
  };
  const handleMouseEnter = () => {
    fetchData();
  };

  return (
    <Tooltip
      overlayInnerStyle={{padding: '0px', background: '#333333'}}
      title={
        <div
          style={{
            display: 'flex',
            padding: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // gap: '16px',
            borderRadius: '2px',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
          }}>
          <div>
            <span style={{color: '#888B8D'}}>{toolTipData?.dos}</span>
          </div>
          <div>
            <span style={{color: '#FFF'}}>{toolTipData?.slfe}</span>
            <br />
            <span style={{color: '#888B8D'}}>{'<50%:'}</span>

            <span style={{color: '#FFF'}}>
              {toolTipData ? String(toolTipData['<50% :']) : 'N/A'}
            </span>
            <br />
            <span style={{color: '#888B8D'}}>{'50 - 75%:'}</span>

            <span style={{color: '#FFF'}}>
              {toolTipData ? String(toolTipData['50 - 70% :']) : 'N/A'}
            </span>
            <br />
            <span style={{color: '#888B8D'}}>{'>75%:'}</span>

            <span style={{color: '#FFF'}}>
              {toolTipData ? String(toolTipData['70% :']) : 'N/A'}
            </span>
          </div>
        </div>
      }
      placement='top'
      onMouseEnter={handleMouseEnter}>
      {props.children}
    </Tooltip>
  );
};

export default React.memo(AvailableStockToolTip);
