import React from 'react';
import {Tooltip} from 'antd';
import moment from 'moment';
const InventoryStepTooltip = (props) => {
  const {description, est_date, title} = props.description;

  function getActualValue(title, description) {
    return title === 'Pick Up'
      ? description.description.replace('EST:', '')
      : title === 'Landing'
      ? description.landingactual
      : title === 'GR'
      ? description.gractual
      : title === 'Document Filling'
      ? description.description.replace('EST:', '')
      : title === 'Good Issue'
      ? description.giactual
      : title === 'Sample Recieved'
      ? description.description.replace('EST:', '')
      : '';
  }
  function getEstimatedValue(title, description) {
    return title === 'Landing'
      ? description.landingestimate.replace('EST:', '')
      : title === 'Good Issue'
      ? description.giestimate
      : title === 'GR'
      ? description.grestimate
      : title === 'QA Release'
      ? description.description.replace('EST:', '')
      : title === 'Pick Up'
      ? 'NA'
      : title === 'Document Filling'
      ? 'NA'
      : title === 'Sample Recieved'
      ? 'NA'
      : '';
  }
  function getGapValue(title, description) {
    return title === 'Landing'
      ? description.landinggap
      : title == 'GR'
      ? description.grgap
      : title === 'Good Issue'
      ? description.gigap
      : // : title === 'QA Release'
        // ? '0'
        '0';
  }
  function getLatestThinkingValue(title, est_date) {
    return title === 'Landing'
      ? props.description.landingestdate
      : title === 'GR'
      ? est_date
        ? moment(new Date(est_date)).format('MM/DD/YYYY')
        : 'NA'
      : title === 'Good Issue'
      ? props.description.giestdate
      : 'NA';
  }
  function getCreatedBy(title, description) {
    return title === 'Landing'
      ? props.description.landingcrby
      : title === 'GR'
      ? props.description.grcrby
      : 'NA';
  }
  function getCreatedDate(title, description) {
    return title === 'Landing'
      ? props.description.landingcrdate
      : title === 'GR'
      ? props.description.grcrdate
      : 'NA';
  }
  const createdBy = getCreatedBy(title, props.description);
  const createdDate = getCreatedDate(title, props.description);

  return (
    <Tooltip
      overlayInnerStyle={{padding: '0px', background: '#333333'}}
      title={
        <div
          style={{
            display: 'flex',
            padding: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '6px',
            borderRadius: '2px',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
          }}>
          <div>
            <span style={{color: '#888B8D'}}>Actual: </span>
            <span style={{color: '#FFF'}}>
              {getActualValue(title, props.description)}
            </span>
          </div>

          <div>
            <span style={{color: '#888B8D'}}>Estimated: </span>
            <span style={{color: '#FFF'}}>
              {getEstimatedValue(title, props.description)}
            </span>
          </div>

          <div>
            <span style={{color: '#888B8D'}}>Gap: </span>
            <span style={{color: '#FFF'}}>
              {getGapValue(title, props.description)}
            </span>
          </div>

          <div>
            <span style={{color: '#888B8D'}}>Latest Thinking: </span>
            <span style={{color: '#FFF'}}>
              {getLatestThinkingValue(title, est_date)}
            </span>
          </div>
          {createdBy !== 'NA' &&
            createdBy !== undefined &&
            createdDate !== 'NA' &&
            createdDate !== undefined && (
              <div style={{marginLeft: '14px'}}>
                <div>
                  <span style={{color: '#888B8D'}}>Created By: </span>
                  <span style={{color: '#FFF'}}>{createdBy}</span>
                </div>
                <div>
                  <span style={{color: '#888B8D'}}>Created Date: </span>
                  <span style={{color: '#FFF'}}>{createdDate}</span>
                </div>
              </div>
            )}
        </div>
      }
      placement='top'>
      <div style={{fontWeight: 400}}>{props.children}</div>
    </Tooltip>
  );
};

export default React.memo(InventoryStepTooltip);
