import downloadExcel from 'components/utility/downloadExcel';
import React, {useState, useEffect} from 'react';

const useOptions = (finalData, data, columnData) => {
  const downloadData = () => {
    downloadExcel({data: finalData, columnData});
  };
 
  const shareLink = () => {};
  return [downloadData, shareLink];
};

export default useOptions;
