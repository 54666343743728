import React, {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CloseOutlined, DownOutlined} from '@ant-design/icons';
import {BiCheck} from 'react-icons/bi';
import {
  getServiceRiskData,
  useServiceRiskData,
} from 'redux/actions/serviceRiskActions';
import {projectionSimulationActions} from 'redux/actions/projectionSimulationActions';
import useColumns from './useColumns';
import {
  Input,
  Button,
  Card,
  Segmented,
  Space,
  DatePicker,
  Dropdown,
  Menu,
  Table,
  Modal,
  Radio,
  Select,
  Divider,
} from 'antd';
import moment from 'moment';
import more from '../../../../assets/icon/more.svg';
import KeysFrame from '../KeysFrame';
import Checkbox from 'antd/es/checkbox';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
import {AuthContext} from 'context/ContextProvider';
import downloadComment from 'components/utility/downloadComment';
const {Search} = Input;
const {RangePicker} = DatePicker;
const ServiceRiskTable = () => {
  const {auth, country, countryList} = React.useContext(AuthContext);

  const [customizeModalVisible, setCustomizeModalVisible] =
    React.useState(false);
  const [saveFilterModalVisible, setSaveFilterModalVisible] =
    React.useState(false);
  // const [globalFilterInput, setGlobalFilterInput] = React.useState('');
  const [isDeleteModal, setIsDeleteModal] = React.useState({
    isVisible: false,
    id: null,
  });
  const dispatch = useDispatch();
  const {
    // columns: serviceRiskColumns,
    data: rawData,
    columnData,
    // scrollWidth,
    loading,
    filterList,
    saveGlobalFilterInput,
    globalFilterInput,
  } = useSelector((state) => state.srCols);

  // React.useEffect(() => {
  //   getServiceRiskData();
  // }, []);

  useServiceRiskData({location: auth?.location});

  const [
    serviceRiskColumns,
    scrollWidth,
    searchString,
    setSearchString,
    data,
    // getFinalData,
    downloadData,
    shareLink,
    customizeCols,
    setCustomizeCols,
    saveCustomizeDisplay,
    keysFilter,
    setKeysFilter,
    saveGlobalFilter,
    globalFilterList,
    deleteGlobalFilter,
  ] = useColumns(columnData, rawData);
  // console.log(customizeCols);
  const onCancel = () => {
    setSaveFilterModalVisible(false);
    resetSaveGlobalFilterInput();
  };

  const handleOptionClick = (value) => {
    if (globalFilterInput == value) {
      dispatch(
        serviceRiskActions.updateGlobalFilterInput({
          globalFilterInput: '',
        }),
      );
      // Object.keys(filterList).forEach(async (key, index) => {
      // wait for 100ms * index before setting filter
      // await new Promise((resolve) =>
      //   setTimeout(resolve, 100 * index),
      // );
      const data = {
        prod_desc: '',
        product_id: [],
        ctry: [],
        panda_code: [],
        gmc: [],
        plant: [],
        brand: [],
        mrp_controller: [],
        Shipper_Qty: [],
        Pallet_Qty: [],
        fromlocation: [],
        target_inv: [0, 0],
        min_inv: [0, 0],
        max_inv: [0, 0],
        mmtd_sales: [0, 0],
        inv_movement_qty: [],
        curr_stock_in_hand: [],
        worst_case: [],
      };
      dispatch(
        serviceRiskActions.updateFilterList({
          filterList: data,
        }),
      );
    } else {
      dispatch(
        serviceRiskActions.updateGlobalFilterInput({
          globalFilterInput: value,
        }),
      );
      const data = {
        ...globalFilterList.filter((filter) => filter.filtername == value)[0]
          .filtervalue,
      };
      dispatch(
        serviceRiskActions.updateFilterList({
          filterList: data,
        }),
      );
    }
  };
  const resetSaveGlobalFilterInput = () => {
    dispatch(
      serviceRiskActions.updateSaveGlobalFilterInput({
        saveGlobalFilterInput: '',
      }),
    );
  };
  // download comments
  const selectedCountry = useSelector((state) => state.prsim);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'sticky',
            zIndex: 9,
            top: '45px',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            background: '#FFFFFF',
            height: '57px',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '24px',
              paddingRight: '24px',
              alignSelf: 'stretch',
              width: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '500',
                fontSize: '14px',
                color: '#595959',
              }}>
              <div>{'Global Filters:'}</div>
              <div style={{marginLeft: '12px', marginRight: '12px'}}>
                {/* <Search
                  placeholder='input search text'
                  style={{
                    width: 300,
                  }}
                /> */}
                <Select
                  mode='multiple'
                  placeholder={
                    <span>
                      Select Filters
                      <DownOutlined style={{marginLeft: '62%'}} />
                    </span>
                  }
                  style={{width: 300}}
                  disabled={false}
                  maxLength={1}
                  value={globalFilterInput != '' ? [globalFilterInput] : []}
                  dropdownRender={(menu) => {
                    return globalFilterList?.map((filter, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            backgroundColor:
                              globalFilterInput === filter.filtername
                                ? 'rgb(24, 144, 255,0.25)'
                                : 'transparent',
                            color:
                              globalFilterInput === filter.filtername
                                ? '#000'
                                : '#000',
                            fontWeight:
                              globalFilterInput === filter.filtername
                                ? 800
                                : 400,
                          }}
                          onClick={() => handleOptionClick(filter.filtername)}>
                          <span>{filter.filtername}</span>
                          {globalFilterInput !== filter.filtername ? (
                            <CloseOutlined
                              style={{fontSize: '12px'}}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsDeleteModal((prev) => ({
                                  ...prev,
                                  id: filter.id,
                                  isVisible: true,
                                }));
                              }}
                            />
                          ) : (
                            <BiCheck size={20} color={'rgb(24, 144, 255)'} />
                          )}
                        </div>
                      </div>
                    ));
                  }}
                  onChange={(e) => {
                    if (e.length == 0) {
                      // setGlobalFilterInput('');
                      dispatch(
                        serviceRiskActions.updateGlobalFilterInput({
                          globalFilterInput: '',
                        }),
                      );
                      // Object.keys(filterList).forEach(async (key, index) => {
                      // wait for 100ms * index before setting filter
                      // await new Promise((resolve) =>
                      //   setTimeout(resolve, 100 * index),
                      // );
                      const data = {
                        prod_desc: '',
                        product_id: [],
                        ctry: [],
                        panda_code: [],
                        gmc: [],
                        plant: [],
                        brand: [],
                        mrp_controller: [],
                        Shipper_Qty: [],
                        Pallet_Qty: [],
                        fromlocation: [],
                        target_inv: [0, 0],
                        min_inv: [0, 0],
                        max_inv: [0, 0],
                        mmtd_sales: [0, 0],
                        inv_movement_qty: [],
                        curr_stock_in_hand: [],
                        worst_case: [],
                      };
                      dispatch(
                        serviceRiskActions.updateFilterList({
                          filterList: data,
                        }),
                      );
                      // });
                    } else {
                      // setGlobalFilterInput(e[0]);
                      dispatch(
                        serviceRiskActions.updateGlobalFilterInput({
                          globalFilterInput: e[0],
                        }),
                      );
                      // Object.keys(filterList).forEach(async (key, index) => {
                      // wait for 100ms * index before setting filter
                      // await new Promise((resolve) =>
                      //   setTimeout(resolve, 120 * index),
                      // );
                      const data = {
                        ...globalFilterList.filter(
                          (filter) => filter.filtername == e[0],
                        )[0].filtervalue,
                      };
                      dispatch(
                        serviceRiskActions.updateFilterList({
                          filterList: data,
                        }),
                      );
                      // });
                    }
                  }}
                  options={globalFilterList.map((filter) => ({
                    value: filter.filtername,
                    label: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <span>{filter.filtername}</span>

                        {globalFilterInput !== filter.filtername ? (
                          <CloseOutlined
                            style={{fontSize: '12px'}}
                            onClick={() => {
                              if (globalFilterInput !== filter.filtername) {
                                setIsDeleteModal((prev) => ({
                                  ...prev,
                                  id: filter.id,
                                  isVisible: true,
                                }));
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    ),
                  }))}
                />
              </div>
              <div>
                <Modal
                  footer={null}
                  closeIcon={null}
                  centered
                  width={300}
                  open={isDeleteModal.isVisible}
                  onCancel={(e) =>
                    setIsDeleteModal((prev) => ({
                      ...prev,
                      id: '',
                      isVisible: false,
                    }))
                  }>
                  <div>
                    <span style={{fontWeight: 800, fontSize: 18}}>
                      Delete Filter?
                    </span>
                    <div style={{marginTop: 15}}>
                      Are you sure you want to proceed?
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Button
                        type='text'
                        onClick={() =>
                          setIsDeleteModal((prev) => ({
                            ...prev,
                            id: '',
                            isVisible: false,
                          }))
                        }>
                        Cancel
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          deleteGlobalFilter({id: isDeleteModal.id})
                            .then(() => {
                              setIsDeleteModal((prev) => ({
                                ...prev,
                                id: '',
                                isVisible: false,
                              }));
                            })
                            .catch(() => {
                              setIsDeleteModal((prev) => ({
                                ...prev,
                                id: '',
                                isVisible: false,
                              }));
                            });
                        }}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    setSaveFilterModalVisible(true);
                  }}
                  disabled={false}
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #D9D9D9',
                    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.016)',
                  }}>
                  {'Save Filters'}
                </Button>
                <Modal
                  footer={null}
                  closeIcon={null}
                  centered
                  width={300}
                  open={saveFilterModalVisible}
                  onCancel={onCancel}>
                  <div>
                    <span style={{fontWeight: 800, fontSize: 18}}>
                      Save Filter
                    </span>
                    <div style={{marginTop: 15}}>
                      <Input
                        placeholder='Type name here'
                        value={saveGlobalFilterInput}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue.length <= 25) {
                            dispatch(
                              serviceRiskActions.updateSaveGlobalFilterInput({
                                saveGlobalFilterInput: inputValue,
                              }),
                            );
                          }
                        }}
                        maxLength={25}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Button
                        type='text'
                        onClick={() => {
                          setSaveFilterModalVisible(false);
                          resetSaveGlobalFilterInput();
                        }}>
                        Cancel
                      </Button>
                      <Button
                        type='primary'
                        style={{background: '#1890FF', fontSize: '14px'}}
                        onClick={() => {
                          saveGlobalFilter()
                            .then(() => {
                              setSaveFilterModalVisible(false);
                            })
                            .catch(() => {
                              setSaveFilterModalVisible(false);
                            });
                        }}
                        disabled={saveGlobalFilterInput.trim().length == 0}>
                        Save
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>

            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                lineHeight: '22px',
                color: '#595959',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0px',
                  // width: '100%',
                  height: '32px',
                }}>
                <div></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                  }}>
                  {/* <Segmented
                    options={['Week', 'Month']}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '2px',
                      background: 'rgba(0, 0, 0, 0.06)',
                      borderRadius: '2px',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  /> */}
                  <Space
                    direction='vertical'
                    size={12}
                    style={{
                      marginLeft: '20px',
                      marginRight: '15px',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}>
                    <RangePicker
                      disabled={true}
                      value={[
                        moment(columnData[1]?.value, 'DD.MM.YYYY'),
                        moment(
                          columnData[columnData.length - 1]?.value,
                          'DD.MM.YYYY',
                        ),
                      ]}
                      defaultValue={[
                        moment(columnData[1]?.value, 'DD.MM.YYYY'),
                        moment(columnData[0]?.value, 'DD.MM.YYYY'),
                      ]}
                    />
                  </Space>
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '20px',
                      color: '#000000',
                      marginRight: '15px',
                    }}>
                    <span
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#8C8C8C',
                      }}>
                      {' '}
                      {'Last Update:'}
                    </span>
                    <span> {rawData?.[0]?.lastupdate ?? null}</span>
                  </div>

                  <Modal
                    open={customizeModalVisible}
                    footer={null}
                    closeIcon={null}
                    centered
                    closable={false}
                    bodyStyle={{padding: '0px', borderRadius: '4px'}}
                    style={{padding: '0px', borderRadius: '4px'}}
                    width={600}
                    onOk={() => setCustomizeModalVisible(false)}
                    onCancel={() => setCustomizeModalVisible(false)}>
                    <div
                      style={{
                        padding: '10px 20px',
                        background: '#002766',
                        borderRadius: '4px 4px 0px 0px',
                        color: '#FFFFFF',
                      }}>
                      <span style={{fontSize: 16, fontWeight: 500}}>
                        Customize Display
                      </span>
                    </div>
                    <div
                      className='customize-display-container'
                      style={{
                        padding: '24px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}>
                      {Object.keys(customizeCols).map((key, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: '50%',
                              fontSize: '14px',
                              margin: '0px 0px 8px 0px',
                            }}>
                            <Checkbox
                              checked={customizeCols[key].show}
                              disabled={customizeCols[key].disabled}
                              onChange={(e) => {
                                customizeCols[key].show = e.target.checked;
                                customizeCols[key].index = 0;
                                customizeCols[key].order = 0;
                                setCustomizeCols({...customizeCols});
                              }}>
                              {customizeCols[key].title}
                            </Checkbox>
                          </div>
                        );
                      })}
                    </div>
                    <Divider style={{margin: 0}} />
                    <div
                      style={{
                        padding: '24px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        // fontSize: '14px',
                        flexWrap: 'wrap',
                        gap: '10px',
                      }}>
                      <span>Display heatmap in: </span>
                      <Radio.Group defaultValue='DOS' buttonStyle='solid'>
                        <Radio value='DOS'>DOS</Radio>
                        {/* <Radio value='Volume'>Volume</Radio>
                        <Radio value='Value'>Value</Radio> */}
                      </Radio.Group>
                    </div>
                    <Divider style={{margin: 0}} />
                    <div
                      style={{
                        padding: '24px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '10px',
                        }}>
                        <span>Pin to Left</span>
                        <Select
                          mode='multiple'
                          placeholder='Select Columns'
                          style={{width: '80%'}}
                          onDropdownVisibleChange={(open) => {
                            if (open) {
                              const selectedKeys = Object.keys(
                                customizeCols,
                              ).filter(
                                (key) => customizeCols[key].position === 'left',
                              );

                              const unselectedKeys = Object.keys(
                                customizeCols,
                              ).filter(
                                (key) => customizeCols[key].position !== 'left',
                              );

                              const sortedKeys = [
                                ...selectedKeys,
                                ...unselectedKeys,
                              ];

                              const sortedCols = {};
                              sortedKeys.forEach((key) => {
                                sortedCols[key] = customizeCols[key];
                              });

                              setCustomizeCols(sortedCols);
                            }
                          }}
                          value={Object.keys(customizeCols)
                            .filter((key) => {
                              return customizeCols[key].position === 'left';
                            })
                            .map((key) => {
                              return {
                                value: key,
                                label: customizeCols[key].title,
                              };
                            })}
                          onChange={(e) => {
                            setCustomizeCols((prev) => {
                              const newCols = {...prev};
                              Object.keys(newCols)
                                .filter((key) => {
                                  return newCols[key].position !== 'right';
                                })
                                .filter((key) => {
                                  return !e.includes(key);
                                })
                                .forEach((key) => {
                                  newCols[key].position = false;
                                });
                              e.forEach((key) => {
                                newCols[key].position = 'left';
                              });
                              const columnsInOrder = Object.keys(newCols)
                                .sort((a, b) => {
                                  if (
                                    newCols[a].position === newCols[b].position
                                  ) {
                                    // If positions are the same, maintain the original order
                                    return 0;
                                  }
                                  if (newCols[a].position === 'left') {
                                    return -1; // 'left' comes before 'right' and 'false'
                                  }
                                  return 1; // 'right' comes after 'left' and 'false'
                                })
                                .map((key) => ({key, ...newCols[key]}));
                              return Object.fromEntries(
                                columnsInOrder.map((col) => [col.key, col]),
                              );
                              //return newCols;
                            });
                          }}
                          options={Object.keys(customizeCols).map(
                            (key, index) => {
                              return {
                                value: key,
                                label: customizeCols[key].title,
                              };
                            },
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '10px',
                        }}>
                        <Select
                          mode='multiple'
                          placeholder='Select Columns'
                          style={{width: '80%'}}
                          onDropdownVisibleChange={(open) => {
                            if (open) {
                              const selectedKeys = Object.keys(
                                customizeCols,
                              ).filter(
                                (key) =>
                                  customizeCols[key].position === 'right',
                              );

                              const unselectedKeys = Object.keys(
                                customizeCols,
                              ).filter(
                                (key) =>
                                  customizeCols[key].position !== 'right',
                              );

                              const sortedKeys = [
                                ...selectedKeys,
                                ...unselectedKeys,
                              ];

                              const sortedCols = {};
                              sortedKeys.forEach((key) => {
                                sortedCols[key] = customizeCols[key];
                              });

                              setCustomizeCols(sortedCols);
                            }
                          }}
                          value={Object.keys(customizeCols)
                            .filter((key) => {
                              return customizeCols[key].position === 'right';
                            })
                            .map((key) => {
                              return {
                                value: key,
                                label: customizeCols[key].title,
                              };
                            })}
                          onChange={(e) => {
                            setCustomizeCols((prev) => {
                              const newCols = {...prev};
                              Object.keys(newCols)
                                .filter((key) => {
                                  return newCols[key].position !== 'left';
                                })
                                .filter((key) => {
                                  return !e.includes(key);
                                })
                                .forEach((key) => {
                                  newCols[key].position = false;
                                });
                              e.forEach((key) => {
                                newCols[key].position = 'right';
                              });
                              const columnsInOrder = Object.keys(newCols)
                                .sort((a, b) => {
                                  if (
                                    newCols[a].position === newCols[b].position
                                  ) {
                                    // If positions are the same, maintain the original order
                                    return 0;
                                  }
                                  if (newCols[a].position === 'right') {
                                    return -1; // 'left' comes before 'right' and 'false'
                                  }
                                  return 1; // 'right' comes after 'left' and 'false'
                                })
                                .map((key) => ({key, ...newCols[key]}));
                              return Object.fromEntries(
                                columnsInOrder.map((col) => [col.key, col]),
                              );
                              // return newCols;
                            });
                          }}
                          options={Object.keys(customizeCols).map(
                            (key, index) => {
                              return {
                                value: key,
                                label: customizeCols[key].title,
                              };
                            },
                          )}
                        />
                        <span>Pin to Right</span>
                      </div>
                    </div>
                    <Divider style={{margin: 0}} />
                    <div
                      style={{
                        padding: '24px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}>
                      <div>
                        <Button
                          onClick={() => {
                            saveCustomizeDisplay();
                          }}>
                          Save Changes
                        </Button>
                      </div>
                      <div>
                        <Button
                          type='text'
                          onClick={() => {
                            setCustomizeModalVisible(false);
                          }}>
                          Cancel
                        </Button>
                        <Button
                          type='primary'
                          onClick={() => {
                            setCustomizeModalVisible(false);
                          }}>
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Modal>
                  {
                    // <Button
                    //   icon={<img src={more} />}
                    //   style={{border: 'none'}}
                    // />
                    // dropdown with more ["Customize Display","Share","Download"]
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: 'Customize Display',
                            onClick: () => {
                              setCustomizeModalVisible(true);
                            },
                          },
                          // {
                          //   label: 'Share',
                          //   onClick: () => {},
                          // },
                          {
                            label: 'Download Reports',
                            onClick: () => {
                              downloadData();
                            },
                          },
                          {
                            label: 'Download Comments',
                            onClick: () => {
                              downloadComment(countryList, country);
                            },
                          },
                        ],
                      }}
                      trigger={['click']}>
                      <Button
                        icon={<img src={more} />}
                        style={{border: 'none'}}
                      />
                    </Dropdown>
                  }
                </div>
              </div>
              {/* {'1 USD = 7 RMB'} */}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '10px',
            overflow: 'auto',
          }}>
          <>
            <Card
              className='tab-section'
              bodyStyle={{
                background: '#FFFFFF',
                borderRadius: '4px',
              }}
              style={{
                minHeight: '100%',
                // marginBottom: '20px',
                // overflowY: 'auto',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#FFFFFF',
                  borderRadius: '4px',
                  width: '100%',
                  height: '90%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}>
                  <div
                    className='service-risk-table-container'
                    style={{
                      width: '100%',
                      // height:
                      //   window.screen.height < 800
                      //     ? `${window.screen.height - 400}px`
                      //     : undefined,
                      // overflow: 'hidden',
                    }}>
                    <Table
                      className='service-risk-table'
                      style={{
                        zIndex: 0,
                      }}
                      columns={serviceRiskColumns}
                      dataSource={data}
                      scrollWidth={scrollWidth}
                      loading={loading}
                      rowKey={(record) => record.id}
                      scroll={{
                        y:
                          window.screen.height < 800
                            ? window.screen.height - 500
                            : 490,
                      }}
                      size='middle'
                      pagination={{
                        position: ['none', 'bottomCenter'],
                        size: 'small',
                        showTitle: true,
                        showTotal: (total) => `Total ${total} items`,
                        // style: {marginTop: '95px'},
                        defaultPageSize: 100,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={'keys-frame '} style={{marginTop: '20px'}}>
                <div className='keys-label'>Keys:</div>
                <div className='keys-container'>
                  <div className='keys-group'>
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.bel}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, bel: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-1' />
                      <div className='keys-text'>Inv. Equal or Below 0</div>
                    </div>
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.min}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, min: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-2' />
                      <div className='keys-text'>Inv. Between 0 - Minimum</div>
                    </div>
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.tar}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, tar: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-3' />
                      <div className='keys-text'>Inv. Between Min - Target</div>
                      {/*  */}
                    </div>
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.max}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, max: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-4' />
                      <div className='keys-text'>Inv. Between Target - Max</div>
                    </div>
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.abv}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, abv: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-5' />
                      <div className='keys-text'>Inv. Above Maximum</div>
                    </div>{' '}
                    <div className='keys-item'>
                      <Checkbox
                        checked={keysFilter.none}
                        onChange={(e) =>
                          setKeysFilter((prev) => {
                            return {...prev, none: e.target.checked};
                          })
                        }
                      />
                      <div className='keys-color keys-color-6' />
                      <div className='keys-text'>
                        Equal to 0 and No Target Inv.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                  height: '24px',
                  alignSelf: 'stretch',
                  // marginTop: '35px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    height: '24px',
                    padding: '0px',
                    gap: '6px',
                  }}></div>
              </div> */}
            </Card>
          </>
        </div>
      </div>
    </>
  );
};

export default React.memo(ServiceRiskTable);
