import {Spin} from 'antd';
import LayoutWrapper from 'components/core';
import {LoginURL} from 'components/services/lib/URLs';
import axios from 'axios';
import axiosConfig from './components/services/lib/axios';
import {AuthContext} from 'context/ContextProvider';
import jwtDecode from 'jwt-decode';
import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import routes from 'routes';
import logo from './assets/images/logo.svg';
import './shared/styles/resources.less';
const App = () => {
  const {auth, loading, setLoading, setAuth} = React.useContext(AuthContext);
  const {id} = useParams();
  let timer = null;
  useEffect(() => {
    // var login_data = localStorage.getItem('jwtToken');
    // eslint-disable-next-line no-constant-condition
    if (true) {
      // login_data = JSON.parse(login_data);
      // login_data = {
      //   ...login_data,
      //   access: Array.from(new Set(login_data?.access)),
      //   location: Array.from(new Set(login_data?.location)),
      // };
      // var tokenData = jwtDecode(login_data.access_token);
      setAuth({
        access: ['admin'],
        location: ['Global'],
        tokenData: {
          name: 'Demo User',
          displayName: 'User,Demo',
          givenName: 'Demo,',
        },
      });
      setLoading(false);
      // if (!window.location.href.includes('localhost')) {
      //   timer = setInterval(() => {
      //     axios.get('/webapp/janssen_r10_project').catch((err) => {
      //       console.log(err);
      //       // get status code
      //       if (err.response.status === 401) {
      //         window.location.href = process.env.REACT_APP_LAUNCHPAD_URL;
      //       }
      //     });
      //   }, 3000);
      // }
    } else {
      if (!window.location.href.includes('localhost')) {
        window.location.href = process.env.REACT_APP_LAUNCHPAD_URL;
      } else {
        var data = {
          logonid: 'facd77ee-f21d-4eec-bc52-8520a34ced2c',
          username: process.env.REACT_APP_LOCAL_USERNAME,
          password: process.env.REACT_APP_LOCAL_PASSWORD,
        };
        axiosConfig
          .post(LoginURL, data)
          .then((res) => {
            res.data = {
              ...res?.data,
              access: Array.from(new Set(res?.data?.access)),
              location: Array.from(new Set(res?.data?.location)),
            };
            var tokenData = jwtDecode(res.data.access_token);

            setAuth({...res.data, tokenData});
            setLoading(false);
          })
          .catch((err) => {});
      }
    }
    return () => {
      if (timer !== null) clearInterval(timer);
    };
  }, []);

  if (loading) {
    return (
      <div className='pre-loader-container'>
        <img className='logo-preloader' src={logo} alt='loading' />
        <Spin />
      </div>
    );
  }

  return (
    // <ContextProvider>
    //   <ThemeProvider>
    //     <LocaleProvider>
    <Routes>
      <Route
        path='/*'
        element={
          // <Main>
          <Routes>
            {routes.map((route, index) => {
              if (route.children) {
                return route.children.map((child, index) => {
                  return (
                    <Route
                      key={index}
                      path={child.path}
                      element={<child.component />}
                    />
                  );
                });
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <LayoutWrapper>
                        <route.component />
                      </LayoutWrapper>
                    }
                  />
                );
              }
            })}
            <Route path='*' element={<Navigate to='/ServiceRiskReport' />} />
          </Routes>
          // {/* </Main> */}
        }
      />
    </Routes>
    //     </LocaleProvider>
    //   </ThemeProvider>
    // </ContextProvider>
  );
};
export default App;
