import React from 'react';
import {Tag} from 'antd';
import EllipseGreen from 'assets/icon/EllipseGreen.svg';
import EllipseRed from 'assets/icon/EllipseRed.svg';
import EllipseYellow from 'assets/icon/EllipseYellow.svg';
const DelayIndicators = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '36px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '30px',
        marginBottom: '24px',
      }}>
      <div style={{display: 'flex', alignItems: 'flex-start', gap: '12px'}}>
        <span
          style={{
            color: '#333',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Delay indicators:
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseGreen} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          On time
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseYellow} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Delay, but within
        </span>
        <div style={{display: 'flex', alignItems: 'flex-start', gap: '5px'}}>
          {/* <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            Target Days
          </Tag>
          <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            +
          </Tag> */}
          <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            3 Days
          </Tag>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseRed} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Delay, but over
        </span>
        <div style={{display: 'flex', alignItems: 'flex-start', gap: '5px'}}>
          {/* <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            Target Days
          </Tag>
          <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            +
          </Tag> */}
          <Tag
            style={{
              color: '#333333',
              margin: '0px',
              borderRadius: '2px',
              border: '1px solid #D6D6D6',
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            }}
            color='#F4F4F4'>
            3 Days
          </Tag>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DelayIndicators);
