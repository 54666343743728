import React from 'react';
import {Tooltip} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {getServiceMTDSalesTooltipData} from 'redux/actions/serviceRiskActions';

const MTDSalesTooltip = (props) => {
  const dispatch = useDispatch();
  const {mtdSalesTooltipData} = useSelector((state) => state.srCols);
  const onMouseEnterEvent = () => {
    dispatch(
      getServiceMTDSalesTooltipData({
        material: props.record['product_id'],
        location_id: props.record['location_id'],
      }),
    );
  };

  const revPattern = /^((Rev)-\d+)$/;
  const matchingRevenueKeys = Object.keys(mtdSalesTooltipData)
    .filter((key) => revPattern.test(key))
    .map((key) => ({
      key: key.replace('Rev-', ''),
      value: mtdSalesTooltipData[key],
    }));

  const nonRevPattern = /^((NonRev)-\d+)$/;
  const matchingNonRevenueKeys = Object.keys(mtdSalesTooltipData)
    .filter((key) => nonRevPattern.test(key))
    .map((key) => ({
      key: key.replace('NonRev-', ''),
      value: mtdSalesTooltipData[key],
    }));
  const salesPattern = /^(\d+-\d+\s*Sales)$/;

  const matchingSalesKeys = Object.keys(mtdSalesTooltipData).filter((key) =>
    salesPattern.test(key),
  );
  // console.log('matchingSalesKeys: ', matchingSalesKeys);

  return (
    <Tooltip
      className='h-full'
      onMouseEnter={() => {
        onMouseEnterEvent();
      }}
      trigger={'hover'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        isolation: 'isolate',
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))',
        background: ' rgba(0, 0, 0, 0.75)',
        borderRadius: '2px',
        padding: '12px, 12px',
      }}
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '225px',
            fontWeight: '400',
            fontsize: '12px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
              }}>
              {'Progress'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {mtdSalesTooltipData.Progress != undefined
                ? `${mtdSalesTooltipData.Progress} days`
                : ''}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
              }}>
              {'Actual (vol)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {mtdSalesTooltipData.Actual != undefined
                ? `${mtdSalesTooltipData.Actual} EA`
                : ''}
            </div>
          </div>
          <div
            style={{
              // display: 'flex',
              // flexDirection: 'row',
              // justifyContent: 'space-between',
              // alignItems: 'flex-start',
              padding: '0px',
              // gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
                // marginLeft: '14px',
              }}>
              {matchingSalesKeys &&
                matchingSalesKeys.map((obj, index) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    key={index}>
                    <div
                      style={{
                        marginLeft: '10px',
                      }}>
                      {obj}
                    </div>
                    <div
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontsize: '12px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                      }}>
                      {mtdSalesTooltipData[`${obj}`]} EA
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {matchingRevenueKeys.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  padding: '0px',
                  gap: '10px',
                  alignSelf: 'stretch',
                }}>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontsize: '12px',
                    lineHeight: '20px',
                    fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                    color: '#D9D9D9',
                    marginLeft: 10,
                  }}>
                  <div>Revenue </div>
                </div>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontsize: '12px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}>
                  {mtdSalesTooltipData.Revenue}
                </div>
              </div>
              <div
                style={{
                  padding: '0px',
                  gap: '10px',
                  alignSelf: 'stretch',
                }}>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontsize: '12px',
                    lineHeight: '20px',
                    fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                    color: '#D9D9D9',
                  }}>
                  {matchingRevenueKeys &&
                    matchingRevenueKeys.map((obj) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        key={obj.key}>
                        <div
                          style={{
                            marginLeft: '30px',
                          }}>
                          {obj.key}:
                        </div>
                        <div
                          style={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontsize: '12px',
                            lineHeight: '20px',
                            color: '#FFFFFF',
                          }}>
                          {obj.value} EA
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          {matchingNonRevenueKeys.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  padding: '0px',
                  gap: '10px',
                  alignSelf: 'stretch',
                }}>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontsize: '12px',
                    lineHeight: '20px',
                    fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                    color: '#D9D9D9',
                  }}>
                  {'NonRevenue'}
                </div>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontsize: '12px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}>
                  {mtdSalesTooltipData.NonRevenue}
                </div>
              </div>
              <div
                style={{
                  padding: '0px',
                  gap: '10px',
                  alignSelf: 'stretch',
                }}>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontsize: '12px',
                    lineHeight: '20px',
                    fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                    color: '#D9D9D9',
                  }}>
                  {matchingNonRevenueKeys.map((obj) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      key={obj.key}>
                      <div style={{marginLeft: '30px'}}>{obj.key}:</div>
                      <div
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '500',
                          fontsize: '12px',
                          lineHeight: '20px',
                          color: '#FFFFFF',
                        }}>
                        {obj.value} EA
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
              }}>
              {'Target (vol)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {mtdSalesTooltipData.Target != undefined
                ? `${mtdSalesTooltipData.Target} EA`
                : ''}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
                marginLeft: '30px',
              }}>
              {'Base (vol)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {mtdSalesTooltipData.Base != undefined
                ? `${mtdSalesTooltipData.Base} EA`
                : ''}
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
                marginLeft: '30px',
              }}>
              {'Event (vol)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {mtdSalesTooltipData.Event != undefined
                ? `${mtdSalesTooltipData.Event} EA`
                : ''}
            </div>
          </div>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
              }}>
              {'Actual ($)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {'102k'}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '10px',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontsize: '12px',
                lineHeight: '20px',
                fontFeatureSettings: `'tnum' on, 'lnum' on'`,
                color: '#D9D9D9',
              }}>
              {'Target ($)'}
            </div>
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontsize: '12px',
                lineHeight: '20px',
                color: '#FFFFFF',
              }}>
              {'202k'}
            </div>
          </div> */}
        </div>
      }>
      <div>{props.children}</div>
    </Tooltip>
  );
};

export default React.memo(MTDSalesTooltip);
