import React from 'react';
import {Button, Avatar} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {getServiceRiskCommentList} from 'redux/actions/serviceRiskActions';
import avatar from './../../../assets/icon/Avatar.svg';
import AddCommentModal from 'pages/ServiceRiskReport/components/Comments/operations/modal/AddCommentModal';
const RecentComments = ({projId, locId, record}) => {
  const dispatch = useDispatch();
  const {commentList} = useSelector((state) => state.srCols);
  const [isAddCommentModal, setIsAddCommentModal] = React.useState(false);
  const onCloseModal = () => {
    setIsAddCommentModal(false);
  };
  const getCommentsFun = React.useCallback(() => {
    dispatch(
      getServiceRiskCommentList({
        material: projId,
        location: locId,
      }),
    );
  }, [dispatch, locId, projId]);
  React.useEffect(() => {
    getCommentsFun();
  }, [getCommentsFun]);

  return (
    <>
      <div style={{width: '100%'}}>
        <div
          style={{
            display: 'flex',
            marginTop: '20px',
            paddingLeft: '20px',
            paddingRight: '11px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span
            style={{
              fontFamily: 'Inter',
              color: '#1D2129',
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '28px',
            }}>
            Recent Comments
          </span>
          <Button
            style={{
              borderRadius: '4px',
              border: ' 1px solid #D2D2D2',
              background: '#FAFAFA',
            }}
            onClick={() => {
              setIsAddCommentModal(true);
            }}>
            <span
              style={{
                color: '#5F5F5F',
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '12px',
                fontStyle: 'normal',
                lineHeight: '16px',
              }}>
              Add Comment
            </span>
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '15px',
            paddingLeft: '22px',
            paddingRight: '12px',

            height: '500px',
          }}>
          <div
            style={{
              maxHeight: '500px',
              width: '100%',
              overflowY: 'auto',
            }}>
            <ul style={{listStyle: 'none', padding: '0px', margin: '0px'}}>
              {commentList.map((obj, index) => (
                <li key={index} style={{width: '99%'}}>
                  <div
                    style={{
                      display: 'flex',
                      padding: '16px 0px',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      borderBottom: '1px solid #F0F0F0',
                    }}>
                    <img
                      src={avatar}
                      style={{minHeight: '32px', minWidth: '32px'}}
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        padding: '0px 32px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '4px',
                        alignSelf: 'stretch',
                      }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '6px',
                        }}>
                        <span
                          style={{
                            fontFamily: 'Roboto',
                            color: '#D9D9D9',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                          }}>
                          {obj.updatedBy}
                        </span>
                        <span
                          style={{
                            fontFamily: 'Roboto',
                            color: 'rgba(0, 0, 0, 0.45)',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                          }}>
                          {obj.updatedAt}
                        </span>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          // alignSelf: 'stretch',
                          fontFamily: 'Roboto',
                          // content wrap
                          color: 'rgba(0, 0, 0, 0.85))',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '22.001px',
                          overflowWrap: 'anywhere',
                        }}>
                          <span>

                        {obj.comment}
                          </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <AddCommentModal
        visible={isAddCommentModal}
        onCloseModal={onCloseModal}
        record={record}
      />
    </>
  );
};

export default React.memo(RecentComments);
