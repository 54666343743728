import React from 'react';
import EllipseGreen from 'assets/icon/EllipseGreen.svg';
import EllipseRed from 'assets/icon/EllipseRed.svg';
import EllipseYellow from 'assets/icon/EllipseYellow.svg';

import EllipseOrange from 'assets/icon/EllipseOrange.svg';

const Pda = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '36px',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '20px',
        marginBottom: '30px',
      }}>
      <div style={{display: 'flex', alignItems: 'flex-start', gap: '12px'}}>
        <span
          style={{
            color: '#333',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '18px',
          }}>
          PDA:
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseOrange} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Shelf life &lt; 40%
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'flex-start', gap: '12px'}}>
        <span
          style={{
            color: '#333',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '18px',
          }}>
          EU2:
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseYellow} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Shelf life &lt; 70%
        </span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
        <img src={EllipseRed} width={14} height={14} />
        <span
          style={{
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
          }}>
          Shelf life &lt; 60%
        </span>
      </div>
    </div>
  );
};
export default React.memo(Pda);
