import {AiOutlineHome} from 'react-icons/ai';
import {AiOutlineAlert} from 'react-icons/ai';
import {IoMdNotificationsOutline} from 'react-icons/io';
import {AiOutlineBook} from 'react-icons/ai';
import {Badge, Breadcrumb, Skeleton} from 'antd';
import Home from 'pages/Home';
import ServiceRiskReport from 'pages/ServiceRiskReport';
import Reports from 'pages/Reports';
import Notifications from 'pages/Notifications';
import ServiceRiskSimulation from 'pages/ServiceRiskSimulation';
//import ProjectionSimulation from 'pages/ProjectionSimulation';
import ServiceRiskSimulationHeader from 'components/utility/ServiceRiskSimulationHeader';
const routes = [
  // {
  //   id: 'Home',
  //   title: 'Homepage',
  //   subtitle: 'Home',
  //   path: '/',
  //   component: Home,
  //   icon: <AiOutlineHome style={{ fontSize: 18 }} />,
  // },
  {
    id: 'servicerisk-simulation',
    title: ServiceRiskSimulationHeader,
    hidden: true,
    subtitle: '',
    path: '/ServiceRiskReport/:simId',
    component: ServiceRiskSimulation,
    //component: ProjectionSimulation,
  },
  {
    id: 'servicerisk',
    title: 'Service Risk Report',
    subtitle: 'Service Risk Report',
    path: '/ServiceRiskReport',
    component: ServiceRiskReport,
    icon: <AiOutlineAlert style={{fontSize: 18}} />,
  },
  // {
  //   id: 'notifications',
  //   title: 'Notification Center',
  //   subtitle: 'Notifications',
  //   // subtitle: (
  //   //   <>
  //   //     <div
  //   //       style={{
  //   //         display: 'flex',
  //   //         flexDirection: 'row',
  //   //         justifyContent: 'space-between',
  //   //         alignItems: 'center',
  //   //         marginRight: '48px',
  //   //       }}>
  //   //       Notifications Center
  //   //       {/* <Badge
  //   //         count={99}
  //   //         style={{
  //   //           backgroundColor: '#FF4D4F',
  //   //           width: '31px',
  //   //           height: '20px',
  //   //           fontFamily: 'Roboto Mono',
  //   //           fontStyle: 'normal',
  //   //           fontWeight: '400',
  //   //           fontSize: '12px',
  //   //           color: '#FFFFFF',
  //   //         }}
  //   //       /> */}
  //   //     </div>
  //   //   </>
  //   // ),
  //   component: Notifications,
  //   path: '/Notifications',
  //   icon: <IoMdNotificationsOutline style={{ fontSize: 18 }} />,
  // },
  // {
  //   id: 'reports',
  //   title: 'Reports',
  //   subtitle: 'Reports',
  //   path: '/Reports',
  //   component: Reports,

  //   icon: <AiOutlineBook style={{ fontSize: 18 }} />,
  // },
];
export default routes;
