export const GetServiceRiskDataURL = '/getServiceRiskData';
export const GetServiceRiskColumnsURL = '/getServiceRiskColumns';
export const LoginURL = '/login';
export const GetServiceRiskDOSTooltipURL = '/getdostooltip';
export const GetServiceRiskMTDSalesTooltipURL = '/getmtdsalestooltip';
export const SaveServiceRiskCommentURL = '/savecomment';
export const GetServiceRiskCommentListURL = '/getcomment';
export const GetServiceRiskCustomizeDisplayURL = '/getserviceriskcust';
export const SaveServiceRiskCustomizeDisplayURL = '/saveserviceriskcust';
export const DeleteServiceRiskCommentURL = '/deletecomment';

export const GetProjectionDataURL = '/getsimprojection';
export const GetSavedSimulationListURL = '/getsimdata';
export const GetCommentsURL = '/downloadcomment';
