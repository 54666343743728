import React from 'react';
import InventoryTabs from './InventoryTabs';

const InventoryFrame = ({onTabSelection}) => {
  const tabSelectionFun = React.useCallback(
    (key) => {
      onTabSelection(key);
    },
    [onTabSelection],
  );
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
      }}>
      <span
        style={{
          fontFamily: 'Inter',
          color: '#1D2129',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 900,
          lineHeight: 'normal',
        }}>
        Inventory
      </span>
      <InventoryTabs onTabSelection={tabSelectionFun} />
    </div>
  );
};

export default React.memo(InventoryFrame);
