const getDateMonth = (rawDate) => {
  let raw_date = rawDate?.split('.');
  let date = new Date();
  let title = '';
  let day = '';
  let month = '';
  let year = '';
  if (raw_date.length === 3) {
    date.setFullYear(raw_date[2], raw_date[1], raw_date[0]);
    date.setMonth(parseInt(raw_date[1]) - 1);
    date.setDate(raw_date[0]);
    month = date.toLocaleString('default', {month: 'short'});
    day = date.getDate();
    // day add 0 if less than 10
    day = day < 10 ? '0' + day : day;
    title = month + ' ' + day;
  } else if (raw_date.length === 2) {
    date.setFullYear(raw_date[1]);
    date.setMonth(raw_date[0] - 1);
    month = date.toLocaleString('default', {month: 'short'});
    year = date.getFullYear().toString().slice(-2);
    title = month + ' ' + year;
  }
  return title;
};

export default getDateMonth;
