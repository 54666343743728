import React from 'react';
import {Button, Input, Modal} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {updateServiceRiskComment} from 'redux/actions/serviceRiskActions';
const {TextArea} = Input;
const EditCommentModal = ({
  visible,
  comment,
  product_id,
  location_id,
  type,
  brand,
  comment_id,
  user,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {saveCommentLoading} = useSelector((state) => state.srCols);

  const [commentValue, setCommentValue] = React.useState('');
  const updateCommentValue = React.useCallback(() => {
    setCommentValue(comment);
  }, [comment]);
  React.useEffect(() => {
    updateCommentValue();
  }, [updateCommentValue]);
  const updateCommentFun = () => {
    dispatch(
      updateServiceRiskComment({
        comment: commentValue,
        product_id: product_id,
        location_id: location_id,
        type: type,
        brand: brand,
        comment_id: comment_id,
        user: user,
      }),
    );
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <>
      <Modal
        centered
        open={visible}
        footer={null}
        closeIcon={null}
        closable={false}
        width={400}
        bodyStyle={{
          padding: '0px',
          maxHeight: '600px',
        }}>
        <div
          style={{
            display: 'flex',
            padding: '20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              width: '100%',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                width: '100%',
                color: 'rgba(0, 0, 0, 0.60)',
              }}>
              <TextArea
                value={commentValue}
                onChange={(e) => setCommentValue(e.target.value)}
                autoSize={{
                  minRows: 4,
                  maxRows: 4,
                }}
                placeholder='Type Comments here...'
                disabled={saveCommentLoading === true}></TextArea>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  paddingTop: '20px',
                }}>
                <Button type='text' onClick={onClose}>
                  {'Cancel'}
                </Button>
                <Button
                  type='primary'
                  style={{background: '#1890FF'}}
                  onClick={() => updateCommentFun()}
                  disabled={commentValue.length === 0}
                  loading={saveCommentLoading}>
                  {'Update'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(EditCommentModal);
