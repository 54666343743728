import {Button, Input, Modal, Segmented, Table} from 'antd';
import KeysFrame from 'pages/ServiceRiskReport/components/KeysFrame';
import React from 'react';
import {AiOutlineReload} from 'react-icons/ai';
import useRunSimulationColumns from './useRunSimulationColumns';
import {getSimulationForecastData} from 'redux/actions/projectionSimulationActions';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';

const RunSimulationDataContext = React.createContext({});

export {RunSimulationDataContext};

export const RunSimulationDataProvider = ({
  isVisible,
  onCloseModal,
  record,
}) => {
  const [dynamicData, setDynamicData] = React.useState({});

  return (
    <RunSimulationDataContext.Provider
      value={{dynamicData, setDynamicData, record}}>
      <RunSimulationModal
        isVisible={isVisible}
        onCloseModal={onCloseModal}
        record={record}
      />
    </RunSimulationDataContext.Provider>
  );
};
const {TextArea} = Input;
const RunSimulationModal = ({isVisible, onCloseModal, record}) => {
  const [simulationName, setSimulationName] = React.useState('');
  const [simulationDesc, setSimulationDesc] = React.useState('');
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [saveModalVisible, setSaveModalVisible] = React.useState(false);
  const dispatch = useDispatch();
  const {data} = useSelector((state) => state.srCols);
  getSimulationForecastData({
    product_id: record.product_id,
    location_id: record.location_id,
  });
  const [projectionCols, projectionData, scrollWidth, loading, save, reset] =
    useRunSimulationColumns({record});
  const closeModalFun = React.useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const cancelFun = React.useCallback(() => {
    reset();
    onCloseModal();
  }, [onCloseModal, reset]);
  const saveSimulationFun = React.useCallback(
    async ({simulationName, simulationDesc}) => {
      setSaveLoading(true);
      await save({
        simulationName: simulationName,
        simulationDesc: simulationDesc,
      });
      setSaveLoading(false);
      setSaveModalVisible(false);
      const index = data.findIndex(
        (obj) =>
          obj.product_id === record.product_id &&
          obj.location_id === record.location_id,
      );
      if (index !== -1) {
        // Updating the value
        dispatch(
          serviceRiskActions.updateServiceRiskDataSimFlag({
            index: index,
            value: true,
          }),
        );
      }
      setTimeout(() => {
        onCloseModal();
      }, 1);
    },
    [save, data, record.product_id, record.location_id, dispatch, onCloseModal],
  );
  return (
    <>
      <Modal
        centered
        open={isVisible}
        footer={null}
        closeIcon={null}
        closable={false}
        width={1300}
        onOk={closeModalFun}
        onCancel={closeModalFun}>
        <div
          style={{
            // margin: '24px 0px 24px 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <span
            style={{
              fontWeight: '500',
              fontSize: '20px',
            }}>
            Simulation
          </span>
          {/* <Segmented
            options={['Week', 'Month']}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '2px',
              background: 'rgba(0, 0, 0, 0.06)',
              borderRadius: '2px',
              fontWeight: '400',
              fontSize: '14px',
            }}
          /> */}
        </div>

        <div className='servicerisk-simulationmodal'>
          <Table
            style={{
              width: '100%',
            }}
            scroll={{
              x: scrollWidth,
            }}
            indentSize={0}
            loading={loading}
            columns={projectionCols}
            size='small'
            dataSource={projectionData}
            pagination={false}
          />
          <KeysFrame style={{marginTop: 20}} showKeys={false} record={record} />
        </div>
        <div
          style={{
            width: '100%',
            marginBottom: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 20,
          }}>
          <AiOutlineReload onClick={reset} style={{fontSize: 30}} />
          <Button
            type='text'
            onClick={() => cancelFun()}
            style={{marginRight: 10}}>
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setSaveModalVisible(true);
            }}
            style={{background: '#1890FF'}}>
            Save Simulation
          </Button>
        </div>
        <Modal
          centered
          width={300}
          height={266}
          open={saveModalVisible}
          footer={null}
          closeIcon={null}
          closable={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '10px',
              borderRadius: '4px',
            }}>
            <div
              style={{
                fontWeight: '600',
                fontStyle: 'normal',
                fontSize: '16px',
                color: '#000',
              }}>
              Save simulation
            </div>
            <div style={{width: '100%'}}>
              <Input
                placeholder='Type name here'
                onChange={(e) => setSimulationName(e.target.value)}
                maxLength={40}
                autoFocus={true}
                disabled={saveLoading}
              />
            </div>
            <div style={{width: '100%'}}>
              <TextArea
                value={simulationDesc}
                placeholder='Enter description here'
                onChange={(e) => setSimulationDesc(e.target.value)}
                maxLength={200}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                disabled={saveLoading}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <Button
                type='text'
                onClick={() => setSaveModalVisible(false)}
                style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.85)'}}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={() =>
                  saveSimulationFun({
                    simulationName: simulationName.trim(),
                    simulationDesc: simulationDesc.trim(),
                  })
                }
                style={{background: '#1890FF', fontSize: '14px'}}
                disabled={
                  simulationName.trim().length === 0 ||
                  simulationDesc.trim().length === 0
                }
                loading={saveLoading}>
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </Modal>
    </>
  );
};

export default RunSimulationDataProvider;
