import React from 'react';
import {Dropdown, Modal, Button} from 'antd';
import axios from 'components/services/lib/axios';
import {AuthContext} from 'context/ContextProvider';
import downloadSimulationExcel from './downloadSimulationExcel';
import useSavedSimulationColumns from './useSavedSimulationColumns';
import {SavedSimulationDataContext} from './SavedSimulationModal';
import {DeleteOutlined, DownloadOutlined} from '@ant-design/icons';

const targetRoles = ['Admin', 'Market Leader'];
const DeleteSavedSimulation = (props) => {
  const {auth} = React.useContext(AuthContext);
  const [deleteModalDetail, setDeleteModalDetail] = React.useState({
    isVisible: false,
    loading: false,
  });
  const {dynamicData} = React.useContext(SavedSimulationDataContext);

  const [projectionCols, projectionData] = useSavedSimulationColumns();

  const deleteFunction = async () => {
    setDeleteModalDetail((prev) => {
      return {...prev, loading: true};
    });

    await axios
      .post('/deletesimulation', {
        material: props.material,
        location: props.location,
        version: props.version,
        simname: props.simname,
      })
      .then(() => {
        props.fetchSavedData();
        setDeleteModalDetail((prev) => {
          return {...prev, isVisible: false, loading: false};
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteModalDetail((prev) => {
      return {...prev, isVisible: true};
    });
  };

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    console.log('dynamic data', dynamicData);
    const functionToChangeObjToArray = (obj) => {
      const resultArray = [];
      const originalObj = obj;
      // Iterate through the original object and transform it
      for (const vmi in originalObj) {
        if (originalObj[vmi]) {
          const display = originalObj[vmi].display;
          const newObj = {
            vmi: vmi,
            display: vmi,
          };

          // Add the properties you want to include in the new object
          for (let i = 1; i <= 26; i++) {
            const propKey = `w${i}`;
            const ttKey = `w${i}_date_tt`;
            const ccKey = `w${i}_cc`;
            newObj[propKey] = originalObj[vmi][propKey];
            newObj[ttKey] = originalObj[vmi][ttKey];
            newObj[`${propKey}_cc`] = originalObj[vmi][`${propKey}_cc`];
          }

          resultArray.push(newObj);
        }
      }
      return resultArray;
    };
    const updatedArr = functionToChangeObjToArray(dynamicData);
    downloadSimulationExcel({
      data: updatedArr,
      columnData: projectionCols,
      material: props.material,
      simname: props.simname,
    });
  };

  return (
    <>
      <Modal
        footer={null}
        closeIcon={null}
        centered
        width={300}
        open={deleteModalDetail.isVisible}
        onCancel={(e) => {
          e.stopPropagation();
          setDeleteModalDetail((prev) => {
            return {...prev, isVisible: false};
          });
        }}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <span style={{fontWeight: 800, fontSize: 18}}>
            Delete Simulation?
          </span>
          <div style={{marginTop: 15}}>Are you sure you want to proceed?</div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}>
            <Button
              type='text'
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModalDetail((prev) => {
                  return {...prev, isVisible: false};
                });
              }}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                deleteFunction();
              }}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      {/* {auth.access.some((role) => targetRoles.includes(role)) == false ? ( */}
      <div>
        <div>
          <DeleteOutlined
            onClick={handleDeleteClick}
            style={{
              marginLeft: '10px',
              marginRight: '20px',
              fontWeight: 'bold',
            }}
          />
          <DownloadOutlined
            onClick={handleDownloadClick}
            style={{marginRight: '8px', fontWeight: 'bold'}}
          />
        </div>
        {/* <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModalDetail((prev) => {
                          return {...prev, isVisible: true};
                        });
                      }}>
                      Delete Simulation
                    </div>
                  ),
                },
                {
                  label: (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log('dynamic data', dynamicData);
                        const functionToChangeObjToArray = (obj) => {
                          const resultArray = [];
                          const originalObj = obj;
                          // Iterate through the original object and transform it
                          for (const vmi in originalObj) {
                            if (originalObj[vmi]) {
                              const display = originalObj[vmi].display;
                              const newObj = {
                                vmi: vmi,
                                display: vmi,
                              };

                              // Add the properties you want to include in the new object
                              for (let i = 1; i <= 26; i++) {
                                const propKey = `w${i}`;
                                const ttKey = `w${i}_date_tt`;
                                const ccKey = `w${i}_cc`;
                                newObj[propKey] = originalObj[vmi][propKey];
                                newObj[ttKey] = originalObj[vmi][ttKey];
                                newObj[`${propKey}_cc`] =
                                  originalObj[vmi][`${propKey}_cc`];
                              }

                              resultArray.push(newObj);
                            }
                          }
                          return resultArray;
                        };
                        const updatedArr =
                          functionToChangeObjToArray(dynamicData);
                        downloadSimulationExcel({
                          data: updatedArr,
                          columnData: projectionCols,
                          material: props.material,
                          simname: props.simname,
                        });
                      }}>
                      Download Simulation
                    </div>
                  ),
                },
              ],
            }}
            trigger={['click']}>
            {props.children}
          </Dropdown> */}
      </div>
      {/* ) : ( */}
      {/* <>{props.children}</> */}
      {/* )} */}
    </>
  );
};

export default React.memo(DeleteSavedSimulation);
