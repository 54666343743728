import React from 'react';

const PercentageBar = ({percentage, filledColor, unfilledColor, timeLine}) => {
  const clampedPercentage = Math.min(percentage, 100);
  const showLine = percentage >= 100;
  const filledStyle = {
    width: `${clampedPercentage}%`,
    backgroundColor: filledColor,
  };
  const unfilledStyle = {
    width: `${100 - clampedPercentage}%`,
    backgroundColor: unfilledColor,
  };
  return (
    <div className='kpi-status'>
      <div className='percentage-bar'>
        <div className='filled' style={filledStyle}></div>
        <div className='unfilled' style={unfilledStyle}></div>
        {showLine && <div className='indicator-line' />}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: `${String(timeLine)}` + `%`,
            height: '16px',
            width: '1px',
            backgroundColor: '#FA4545',
          }}
        />
        <span className='percentage'>{`${percentage}%`}</span>
      </div>
    </div>
  );
};

export default React.memo(PercentageBar);
