import {useEffect} from 'react';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
import {store} from 'redux/store';
import {FilterFilled} from '@ant-design/icons';
const ServiceRiskInvMovementDelayFilter = (index) => {
  return {
    filters: [],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const dispatch = useDispatch();
      const {filterList} = useSelector((state) => state.srCols);
      const [rangeKeys, setRangeKeys] = useState({
        1: false,
        2: false,
        4: false,
      });

      useEffect(() => {
        if (filterList[index].length > 0) {
          console.log('filterList[index]', filterList[index]);
          setRangeKeys({
            1: filterList[index].includes('1'),
            2: filterList[index].includes('2'),
            4: filterList[index].includes('4'),
          });
        }
      }, [filterList[index]]);

      return (
        <div style={{padding: 8, display: 'flex', flexDirection: 'column'}}>
          <Checkbox
            checked={rangeKeys[1]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                1: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(1),
                  height: 15,
                  width: 15,
                }}
              />
              Delay over 3 days
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[2]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                2: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(2),
                  height: 15,
                  width: 15,
                }}
              />
              Delay within 3 days
            </div>
          </Checkbox>
          <Checkbox
            checked={rangeKeys[4]}
            onChange={(e) => {
              setRangeKeys({
                ...rangeKeys,
                4: e.target.checked,
              });
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  background: getDaySupplyBGColor(4),
                  height: 15,
                  width: 15,
                }}
              />
              On time
            </div>
          </Checkbox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            }}>
            <Button
              type='primary'
              onClick={() => {
                setSelectedKeys(
                  Object.keys(rangeKeys).filter((key) => rangeKeys[key]),
                );
                // currStockInHandFilter = Object.keys(rangeKeys).filter(
                //   (key) => rangeKeys[key],
                // );
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: Object.keys(rangeKeys).filter(
                        (key) => rangeKeys[key],
                      ),
                    },
                  }),
                );
                if (!rangeKeys[1] && !rangeKeys[2] && !rangeKeys[3]) {
                  setSelectedKeys([]);
                }

                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Search
            </Button>
            <Button
              onClick={() => {
                // currStockInHandFilter = [];
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: [],
                    },
                  }),
                );
                setRangeKeys({
                  1: false,
                  2: false,
                  4: false,
                });
                setSelectedKeys([]);
                clearFilters();
                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () => {
      const {filterList} = useSelector((state) => state.srCols);
      return React.createElement(FilterFilled, {
        style: {color: filterList[index].length > 0 ? '#1890ff' : '#bfbfbf'},
      });
    },
    // onFilter: (value, record) => {
    //   const state = store.getState();
    //   const filterList = state.srCols.filterList;
    //   return filterList.curr_stock_in_hand
    //     .map((e) => parseInt(e))
    //     .includes(parseInt(record['w1_cc']));
    // },
    filterSearch: true,
  };
};

export default ServiceRiskInvMovementDelayFilter;
