import {Dropdown} from 'antd';
import React from 'react';
import AddCommentModal from '../modal/AddCommentModal';
import RunSimulationModal from '../modal/RunSimulationModal';
const AddCommentDropdown = (props) => {
  //const navigate = useNavigate();
  const [isAddCommentModal, setIsAddCommentModal] = React.useState(false);
  const [isRunSimulation, setIsRunSimulation] = React.useState(false);
  const onCloseModal = () => {
    setIsAddCommentModal(false);
  };
  const onCloseRunSimulationModal = () => {
    setIsRunSimulation(false);
  };
  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'Add comment',
              onClick: () => {
                setIsAddCommentModal(true);
              },
            },
            {
              label: 'Run Simulation',
              onClick: () => {
                setIsRunSimulation(true);
                // navigate(
                //   `/ServiceRiskReport/${props.record.product_id},${props.record.location_id}`,
                //   {state: {record: props.record, action: true}},
                // );
              },
            },
          ],
        }}
        trigger={['click']}>
        {props.children}
      </Dropdown>
      <AddCommentModal
        visible={isAddCommentModal}
        onCloseModal={onCloseModal}
        record={props.record}
      />
      {isRunSimulation === true ? (
        <RunSimulationModal
          isVisible={isRunSimulation}
          onCloseModal={onCloseRunSimulationModal}
          record={props.record}
        />
      ) : null}
    </>
  );
};
export default React.memo(AddCommentDropdown);
