import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContextProvider, {AuthContext} from 'context/ContextProvider';
import LocaleProvider from 'context/LocaleProvider';
import ThemeProvider from 'context/ThemeProvider';
import {store} from './redux/store/index';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ContextProvider>
    <ThemeProvider>
      <LocaleProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </LocaleProvider>
    </ThemeProvider>
  </ContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
