import {Button, Input, Modal, message, Space, Checkbox, Select} from 'antd';
import {AuthContext} from 'context/ContextProvider';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import React, {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  saveServiceRiskComment,
  getServiceRiskCommentList,
  updateServiceRiskComment,
  deleteServiceRiskComment,
} from 'redux/actions/serviceRiskActions';
import axios from 'axios';
const {TextArea} = Input;
const AddCommentModal = ({visible, onCloseModal, record}) => {
  const [commentValue, setCommentValue] = React.useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const dispatch = useDispatch();
  const {auth} = React.useContext(AuthContext);
  const {saveCommentLoading, commentList} = useSelector(
    (state) => state.srCols,
  );

  const cancelComment = React.useCallback(() => {
    setCommentValue('');
    setEditMode(null);
    onCloseModal();
    setAssignedTo('');
  }, [onCloseModal]);

  const [editMode, setEditMode] = React.useState(null);

  const handleToggleEdit = (index) => {
    setEditMode(index);
    setCommentValue(commentList[index]?.comment || '');
    setAssignedTo('');
  };

  const handleEditChange = (value) => {
    setCommentValue(value);
  };
const handleSelectedValue = (value) => {
  const selectedOption = options.find(option => option.value === value);
  console.log('assignedTo: ', assignedTo);
  if (selectedOption) {
    const label = selectedOption.label;
    console.log('label: ', label);
    setAssignedTo(label);
  }
};
  const saveCommentFun = async () => {
    try {
      if (editMode !== null) {
        await dispatch(
          updateServiceRiskComment({
            comment: commentValue,
            product_id: record['product_id'],
            location_id: record['location_id'],
            type: 'SR',
            brand: record['brand'],
            comment_id: commentList[editMode].comment_id,
            user: `${auth?.tokenData?.givenName} ${
              auth?.tokenData?.displayName.split(',')[0]
            }`,
            assignedTo: assignedTo
          }),
        );
        setEditMode(null);
      } else {
        await dispatch(
          saveServiceRiskComment({
            material: record['product_id'],
            location: record['location_id'],
            type: 'SR',
            brand: record['brand'],
            comment: commentValue,
            user: `${auth?.tokenData?.givenName} ${
              auth?.tokenData?.displayName.split(',')[0]
            }`,
            assignedTo: assignedTo
           
          }),
        );
      }

      setAssignedTo('');
      setCommentValue('');
    } catch (error) {
      console.error('Error saving comment:', error);
    }
  };

  const [commentToDelete, setCommentToDelete] = React.useState(null);
  const [onVisible, setVisible] = React.useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    React.useState(false);

  const showDeleteConfirmationModal = (commentId) => {
    setCommentToDelete(commentId);
    setDeleteConfirmationVisible(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      // Add your delete logic here
      await dispatch(
        deleteServiceRiskComment({
          material: record['product_id'],
          location: record['location_id'],
          commentId: commentToDelete,
        }),
      );

      // Close both modals
      setDeleteConfirmationVisible(false);
      onCloseModal();
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationVisible(false);
  };
  // const handleDeleteComment = async (commentId) => {
  //   try {
  //     await dispatch(
  //       deleteServiceRiskComment({
  //         material: record['product_id'],
  //         location: record['location_id'],
  //         commentId: commentId,
  //       }),
  //     );
  //   } catch (error) {
  //     console.error('Error deleting comment:', error);
  //   }
  // };

  const [isShowAllComment, setIsShowAllComment] = React.useState(false);

  React.useEffect(() => {
    if (saveCommentLoading === false) setCommentValue('');
  }, [saveCommentLoading]);
  const getCommentsFun = React.useCallback(() => {
    dispatch(
      getServiceRiskCommentList({
        material: record['product_id'],
        location: record['location_id'],
      }),
    );
  }, [dispatch, record]);
  React.useEffect(() => {
    if (isShowAllComment === true || visible === true) {
      getCommentsFun();
    }
  }, [getCommentsFun, isShowAllComment, visible]);
  const error = () => {
    message.error('Unauthorized User');
  };

  const options= [
    {
      value: '1',
      label: 'Cbej',
    },
    {
      value: '2',
      label: 'Mukesh',
    },
    {
      value: '3',
      label: 'VarunD',
    },
    {
      value: '4',
      label: 'Xiaon',
    },
    {
      value: '5',
      label: 'SK',
    },
   
  ]
  return (
    <>
      <Modal
        title='Confirm Delete'
        visible={deleteConfirmationVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText='Confirm'
        cancelText='Cancel'
        width={360}
        fontWeight='600'
        bodyStyle={{
          padding: '0px',
          borderRadius: '4px',
          background: '#FFF',
        }}>
        <p
          style={{
            display: 'flex',
            padding: '20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          Are you sure you want to delete?
        </p>
      </Modal>
      <Modal
        centered
        open={visible}
        footer={null}
        closeIcon={null}
        closable={false}
        width={460}
        onCancel={onCloseModal}
        bodyStyle={{
          padding: '0px',
          borderRadius: '4px',
          background: '#FFF',
        }}>
        <div
          style={{
            display: 'flex',
            padding: '20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          <div
            style={{
              color: '#000',
              textAlign: 'center',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
            }}>
            {record != undefined && record != null
              ? `${record['prod_desc']} • ${record['product_id']}`
              : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              width: '100%',
              alignSelf: 'stretch',
            }}>
            <div
              style={{
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                width: '100%',
                color: 'rgba(0, 0, 0, 0.60)',
              }}>
              {'Last added comment:'}
              {isShowAllComment ? (
                <div
                  style={{
                    maxHeight: '300px',
                    width: '100%',
                    overflowY: 'auto',
                  }}>
                  <ul
                    style={{listStyle: 'none', padding: '0px', margin: '0px'}}>
                    {commentList.map((obj, index) => (
                      <li key={index} style={{width: '100%', padding: '0px'}}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '4px',
                            alignSelf: 'stretch',
                            width: '100%',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'space-between',
                              alignSelf: 'stretch',
                            }}>
                            <div
                              style={{
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '22px',
                                color: 'rgba(0, 0, 0, 0.85)',
                              }}>
                              {obj.updatedBy}
                            </div>
                            <span
                              style={{
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '20px',
                                color: '#BFBFBF',
                                display: 'flex',
                                gap: '8px',
                              }}>
                              {obj.updatedAt}
                              <EditOutlined
                                onClick={() => {
                                  if (
                                    obj.updatedBy ===
                                    `${auth?.tokenData?.givenName} ${
                                      auth?.tokenData?.displayName.split(',')[0]
                                    }`
                                  ) {
                                    handleToggleEdit(index);
                                  } else {
                                    error();
                                  }
                                }}
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}
                              />

                              <DeleteOutlined
                                onClick={() => {
                                  if (
                                    obj.updatedBy ===
                                    `${auth?.tokenData?.givenName} ${
                                      auth?.tokenData?.displayName.split(',')[0]
                                    }`
                                  ) {
                                    showDeleteConfirmationModal(obj.comment_id);
                                  } else {
                                    error();
                                  }
                                }}
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px 12px',
                            alignItems: 'flex-start',
                            gap: '10px',
                            alignSelf: 'stretch',
                            width: '100%',
                          }}>
                          <div
                            style={{
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '20px',
                              color: '#000',
                              overflow: 'auto',
                            }}>
                            {obj.comment}
                          </div>
                          {/*  */}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '4px',
                      alignSelf: 'stretch',
                      width: '100%',
                    }}>
                    {commentList.length > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignSelf: 'stretch',
                        }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '22px',
                            color: 'rgba(0, 0, 0, 0.85)',
                          }}>
                          {commentList.length > 0
                            ? commentList[0].updatedBy
                            : null}
                        </div>
                        <span
                          style={{
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px',
                            color: '#BFBFBF',
                            display: 'flex',
                            gap: '8px',
                          }}>
                          {commentList[0].updatedAt && (
                            <>
                              {commentList[0].updatedAt}
                              <EditOutlined
                                onClick={() => {
                                  if (
                                    commentList[0].updatedBy ===
                                    `${auth?.tokenData?.givenName} ${
                                      auth?.tokenData?.displayName.split(',')[0]
                                    }`
                                  ) {
                                    handleToggleEdit(0);
                                  } else {
                                    error();
                                  }
                                }}
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}
                              />
                              <DeleteOutlined
                                onClick={() => {
                                  if (
                                    commentList[0].updatedBy ===
                                    `${auth?.tokenData?.givenName} ${
                                      auth?.tokenData?.displayName.split(',')[0]
                                    }`
                                  ) {
                                    showDeleteConfirmationModal(
                                      commentList[0].comment_id,
                                    );
                                  } else {
                                    error();
                                  }
                                }}
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}
                              />
                            </>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      padding: '10px 12px',
                      alignItems: 'flex-start',
                      gap: '10px',
                      alignSelf: 'stretch',
                      width: '100%',
                    }}>
                    <div
                      style={{
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#000',
                        overflow: 'auto',
                      }}>
                      {commentList.length > 0 ? commentList[0].comment : null}
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{
                  paddingTop: '8px',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#359EFF',
                  cursor: 'pointer',
                  paddingBottom: '20px',
                }}
                onClick={() =>
                  setIsShowAllComment((prev) => {
                    return !prev;
                  })
                }>
                {isShowAllComment ? 'Show less comments' : 'Show all comments'}
              </div>

              <TextArea
                value={commentValue}
                disabled={saveCommentLoading}
                onChange={(e) => handleEditChange(e.target.value)}
                autoSize={{
                  minRows: 4,
                  maxRows: 4,
                }}
                placeholder='Type Comments here...'></TextArea>
              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10, alignItems: 'center'}}>
                <div style={{display: 'flex', gap: 10,}}>
                  <span>
                    <Checkbox />
                  </span>
                  <span style={{fontSize: '14px', color: '#000000'}}>
                    Action needed
                  </span>
                </div>
                <div style={{display: 'flex', gap: 10,}}>
                  <span
                    style={{fontSize: '14px', color: '#000000', marginTop: 5}}>
                    Assign to
                  </span>
                  <span>
                    <Select style={{width: '95px'}} options={options} placeholder="Select" onChange={handleSelectedValue}/>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  paddingTop: '20px',
                }}>
                <Button type='text' onClick={cancelComment}>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  style={{background: '#1890FF'}}
                  onClick={saveCommentFun }
                  loading={saveCommentLoading}
                  disabled={commentValue.length === 0}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(AddCommentModal);
