import {message, Tooltip} from 'antd';
import axios from 'components/services/lib/axios';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {projectionSimulationActions} from 'redux/reducers/projectionSimulationSlice';
import {QuestionCircleOutlined} from '@ant-design/icons';
const useProjectionData = (loading, setLoading, record) => {
  const dispatch = useDispatch();
  const {simId} = useParams();
  const [projId, locId] = simId
    ? simId.split(',')
    : record
    ? [record.product_id, record.location_id]
    : ['', ''];
  const {projectionTableData} = useSelector((state) => state.prSims);
  // console.log('projectionTableData', projectionTableData);
  const fetchProjectionData = () => {
    return new Promise((resolve, reject) => {
      axios
        .post('/getsimprojection', {
          material: projId,
          location_id: locId,
        })
        .then((data) => {
          const children = data?.data?.filter((e) =>
            Object.keys(e).includes('Other'),
          )[0];
          let projData = data?.data
            ?.filter((e) => Object.keys(e).includes('vmi'))
            .map((e, i) => {
              if (e.vmi == 'Other') {
                return {
                  ...e,
                  key: i,
                  children: [...children.Other],
                };
              }
              return {
                ...e,
                key: i,
              };
            });
          // setProjectionData(projData);
          dispatch(
            projectionSimulationActions.updateProjectionTableData({
              data: projData,
            }),
          );
          // console.log(projData);
          resolve(projData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  useEffect(() => {
    fetchProjectionData()
      .then((res) => {
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        message.error('Error while fetching simulation projection data');
        setLoading(false);
      });
    setLoading(true);
  }, [simId]);
  return [projectionTableData, fetchProjectionData];
};

export default useProjectionData;
