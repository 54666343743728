import {Layout} from 'antd';
import React from 'react';
import Header from '../Header';
import Sider from '../Sider';

const Main = ({children}) => {
  return (
    <Layout style={{height: '100vh'}}>
      <Sider />
      <Layout>
        <Header />
        <Layout.Content
          style={{
            background: '#fafafa',
          }}>
          {/* <Routes>
                </Routes> */}
          {children}
          {/* <Routes>
            <Route path={'/*'} element={<Main />} />
          </Routes> */}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(Main);
