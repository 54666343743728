//projectionTable
import {Table, message, Tooltip} from 'antd';
import axios from 'components/services/lib/axios';
import getDateMonth from 'components/utility/getDateMonth';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import KeysFrame from 'pages/ServiceRiskReport/components/KeysFrame';
import React, {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import SavedSimulationModal from '../SavedSimulationHeatMap/SavedSimulationModal';
import {getServiceRiskColumns} from 'redux/actions/serviceRiskActions';
import useProjectionData from './useProjectionData';
import {QuestionCircleOutlined} from '@ant-design/icons';
const ProjectionTable = ({savedSimulation, fetchSavedData}) => {
  const dispatch = useDispatch();
  const {simId} = useParams();

  const {columnData} = useSelector((state) => state.srCols);
  const [loading, setLoading] = React.useState(true);
  // const [projectionData, setProjectionData] = React.useState([]);
  useEffect(() => {
    if (columnData?.length === 0) {
      dispatch(getServiceRiskColumns());
    }
  }, [columnData?.length, dispatch]);

  const [projectionData, fetch] = useProjectionData(loading, setLoading);
  // console.log('projectionData', projectionData);
  // useEffect(() => {
  //   const [projId, locId] = simId.split(',');
  //   setLoading(true);
  //   axios
  //     .post('/getsimprojection', {
  //       material: projId,
  //       location_id: locId,
  //     })
  //     .then((data) => {
  //       const children = data?.data?.filter((e) =>
  //         Object.keys(e).includes('Other'),
  //       )[0];
  //       let projData = data?.data
  //         ?.filter((e) => Object.keys(e).includes('vmi'))
  //         .map((e, i) => {
  //           if (e.vmi == 'Other') {
  //             return {
  //               ...e,
  //               key: i,
  //               children: [...children.Other],
  //             };
  //           }
  //           return {
  //             ...e,
  //             key: i,
  //           };
  //         });
  //       setProjectionData(projData);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       message.error('Error while fetching simulation projection data');
  //       setProjectionData([]);
  //       setLoading(false);
  //     });
  // }, [simId]);
  const [projectionCols, scrollWidth] = useMemo(() => {
    let projectionCols = [];
    let scrollWidth = 0;
    const addCol = (
      title,
      dataIndex,
      width,
      render,
      onHeaderCell = (props) => {
        return {
          style: {
            backgroundColor: '#00000000',
            border: '1px solid #f0f0f0',
            color: '#000000',
            fontWeight: '400',
            textAlign: 'center',
          },
        };
      },
      fixed = false,
    ) => {
      projectionCols.push({
        title,
        dataIndex,
        width,
        render,
        onHeaderCell,
        fixed,
      });
      scrollWidth += width;
    };
    setLoading(false);
    addCol(
      '',
      'vmi',
      200,
      (text, record) => {
        return {
          children:
            record.display == 'New unusable' ? (
              <span>
                {record.display}{' '}
                <Tooltip title='This represent the first week when the a product will become unusable, and total inventory will be reduced'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              record.display
            ),

          props: {
            style: {
              border: 'none',
              borderRight: '1px solid #f0f0f0',
              display: 'flex',
              justifyContent: 'start',
              gap: '10px',
              flexDirection: 'row-reverse',
            },
          },
        };
      },
      (props) => {
        return {
          style: {
            border: 'none',
            backgroundColor: '#fff',
            borderRight: '1px solid #f0f0f0',
          },
        };
      },
      'left',
    );
    /*  addCol('Start', '', 10, (text, record) => {
        if (text.vmi == 'inventory_qty') {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px',
            }}>
            {text.w1}
          </div>
        );
      } else if (text.vmi == 'days_of_supply') {
        return {
          children: null,
          props: {
            style: {
              border: 'none',
              padding: '0px',
            },
          },
        };
      } else {
        return null;
      }
    }); */
    columnData.map((i) =>
      addCol(
        i.value == 'Initial' ? 'Initial' : getDateMonth(i.value),
        i.Week,
        100,
        (text, record) => {
          // if(Object.keys(record).) {
          let clrCode = record[i.Week + '_cc'] ? record[i.Week + '_cc'] : '';

          return {
            children: text,
            props: {
              style: {
                width: '10px',
                padding: '0px',
                // color: clrCode != '' ? '#ffffff' : '#000000',
                color:
                  clrCode != ''
                    ? record[i.Week + '_cc'] === 4
                      ? '#000'
                      : '#fff'
                    : '#000000',

                fontWeight: '400',
                textAlign: 'center',
                backgroundColor:
                  clrCode != '' ? getDaySupplyBGColor(clrCode) : undefined,
              },
            },
          };
        },
        (props) => {
          return {
            style: {
              padding: '10px 30px 10px 30px',
              backgroundColor: '#00000000',
              border: '1px solid #f0f0f0',
              color: '#000000',
              fontWeight: '400',
              textAlign: 'center',
              ...props.style,
            },
          };
        },
      ),
    );
    return [projectionCols, scrollWidth];
  }, [columnData]);
  // console.log('projectionData', projectionData, projectionCols, columnData);
  return (
    <div className='projection-table'>
      <Table
        loading={loading}
        scroll={{x: scrollWidth}}
        pagination={false}
        dataSource={projectionData}
        size='small'
        // row col is 0 add class no-border
        // rowClassName={(record, index) => (index === 0 ? 'no-border' : '')}
        columns={projectionCols}
      />
      <KeysFrame style={{marginTop: 30}} showKeys={false} />

      {savedSimulation.map((obj, index) => {
        return (
          <SavedSimulationModal
            key={index}
            index={index}
            obj={obj}
            fetchSavedData={fetchSavedData}
          />
        );
      })}
    </div>
  );
};

export default ProjectionTable;
