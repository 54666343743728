import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Slider, Button} from 'antd';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
import {store} from 'redux/store';
import {FilterFilled} from '@ant-design/icons';
import {AuthContext} from 'context/ContextProvider';
const formatter = (value) => `${value}%`;
const ServiceRiskMtdSalesFilter = (data, index) => {
  return {
    onFilter: (value, record) => {
      // get filterList from redux in utils function
      const state = store.getState();
      const filterList = state.srCols.filterList;
      return (
        (parseInt(record[index]) >= parseInt(filterList[index][0]) &&
          parseInt(record[index]) <= parseInt(filterList[index][1])) ||
        (filterList[index][0] == 0 && filterList[index][1] == 0)
      );
    },
    filters: [],
    filterSearch: true,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const {country} = useContext(AuthContext);
      const [max, setMax] = useState(0);

      useEffect(() => {
        let arr = data
          .filter((e) => e.ctry == country || country == 'ALL')
          .map((e) => parseFloat(e[index]))
          .filter((e) => !isNaN(e));

        let maxVal = Math.max(...arr);

        setMax(maxVal);
      }, [country, data]);

      // let max = Math.max(...arr);
      const [rangeKeys, setRangeKeys] = useState([0, 0]);
      const {filterList: fl} = useSelector((state) => state.srCols);
      const dispatch = useDispatch();

      useEffect(() => {
        setRangeKeys([
          0,
          rangeKeys[1] === 0 || rangeKeys[1] === -Infinity ? max : rangeKeys[1],
        ]);
      }, [max]);
      useEffect(() => {
        if (
          fl[index][0] !== rangeKeys[0] ||
          (fl[index][1] !== rangeKeys[1] && rangeKeys[1] !== max)
        ) {
          // console.log(fl[index], 'filterList ' + index + ' changed');
          setSelectedKeys(fl[index]);
          setRangeKeys(fl[index]);
          if (fl[index][0] == 0 && fl[index][1] == 0) {
            setSelectedKeys([]);
          }
          // confirm(false);
        }
      }, [fl[index]]);
      // useEffect(() => {
      //   console.log(selectedKeys);
      //   // set a random timeout to confirm filter
      //   setTimeout(() => {
      //     confirm(false);
      //   }, Math.random() * 1000);
      // }, [selectedKeys]);

      return (
        <div style={{padding: 8}}>
          <Slider
            tooltip={
              index == 'mmtd_sales'
                ? {
                    formatter,
                  }
                : null
            }
            range
            value={rangeKeys}
            //max={max < 100 ? 100 : max}
            max={max}
            onChange={(value) => {
              setRangeKeys(value);
            }}
          />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <span>0</span>
              <span>{!isFinite(max) ? 0 : max}</span>
            </div>
            <div
              style={{
                display: 'flex',

                justifyContent: 'space-between',
                marginTop: '8px',
              }}>
              <Button
                type='primary'
                onClick={() => {
                  setSelectedKeys(rangeKeys);
                  if (rangeKeys[0] == 0 && rangeKeys[1] == 0) {
                    setSelectedKeys([]);
                  }
                  dispatch(
                    serviceRiskActions.updateFilterList({
                      filterList: {
                        [index]: rangeKeys,
                      },
                    }),
                  );
                  // confirm();
                }}
                size='small'
                style={{width: 90}}>
                Search
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    serviceRiskActions.updateFilterList({
                      filterList: {
                        [index]: [0, 0],
                      },
                    }),
                  );
                  console.log(max);
                  setRangeKeys([0, max]);
                  setSelectedKeys([0, 0]);
                  clearFilters();
                  // confirm();
                }}
                size='small'
                style={{width: 90}}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      );
    },
    filterIcon: () => {
      const {filterList} = useSelector((state) => state.srCols);
      return React.createElement(FilterFilled, {
        style: {
          color:
            filterList[index][0] != 0 || filterList[index][1] != 0
              ? '#1890ff'
              : '#bfbfbf',
        },
      });
    },
  };
};
export default ServiceRiskMtdSalesFilter;
