import * as XLSX from 'xlsx';
import getDateMonth from 'components/utility/getDateMonth';

const downloadExcel = ({data, columnData}) => {
  try {
    let formattedData = [];
    data.map((obj) => {
      const formattedObj = {
        Description: obj.prod_desc,
        Material: obj.product_id,
      };

      columnData.map((column, index) => {
        if (column.value == 'Initial') {
          formattedObj['Initial'] = obj['w' + String(index + 1)];
          return;
        } else {
          formattedObj[getDateMonth(column.value)] =
            obj['w' + String(index + 1)];
        }
      });
      formattedObj['Market'] = obj.ctry;
      formattedObj['PandaA Code'] = obj.panda_code;
      formattedObj['GMC'] = obj.gmc;
      formattedObj['Brand'] = obj.brand;
      formattedObj['MFG Plant'] = obj.plant;
      formattedObj['MTD Sales'] = obj.mmtd_sales;
      formattedObj['Target Inv. d)'] = obj.target_inv;
      formattedObj['Min Inv. d)'] = obj.min_inv;
      formattedObj['Max Inv. d)'] = obj.max_inv;
      formattedObj['Total Inventory Movement'] = obj.inv_movement_qty;
      formattedObj['Current Stock on Hand'] = obj.inventory_qty;
      formattedObj['Worst Case'] = obj.wrostcase_v;
      formattedData.push(formattedObj);
    });

    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, 'R10-ServiceRisk');
    XLSX.writeFile(wb, 'ServiceRiskReport.xlsx');
  } catch (e) {
    console.log(e);
  }
};
export default downloadExcel;
