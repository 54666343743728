import {Button, Input, Modal, Segmented, Table} from 'antd';
import KeysFrame from 'pages/ServiceRiskReport/components/KeysFrame';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AiOutlineReload} from 'react-icons/ai';
import moreIcon from './../../../assets/icon/more.svg';
import useSavedSimulationColumns from './useSavedSimulationColumns';
import DeleteSavedSimulation from './DeleteSavedSimulation';
import UseSelectionBounds from '../SelectedTableCell/useSelectionBounds';

const SavedSimulationDataContext = React.createContext({});
export {SavedSimulationDataContext};

export const SavedSimulationDataProvider = ({index, obj, fetchSavedData}) => {
  const [dynamicData, setDynamicData] = React.useState({});

  return (
    <SavedSimulationDataContext.Provider
      value={{dynamicData, setDynamicData, obj}}>
      <SavedSimulationModal
        index={index}
        obj={obj}
        fetchSavedData={fetchSavedData}
      />
    </SavedSimulationDataContext.Provider>
  );
};

const SavedSimulationModal = ({index, obj, fetchSavedData}) => {
  const [visible, setVisible] = React.useState(false);
  //const [simulationName, setSimulationName] = React.useState('');
  const [saveLoading, setSaveLoading] = React.useState(false);
  //const [saveModalVisible, setSaveModalVisible] = React.useState(false);

  const [projectionCols, projectionData, scrollWidth, loading, save, reset] =
    useSavedSimulationColumns();

  const saveSimulationFun = React.useCallback(
    async ({simulationName, version, desc}) => {
      setSaveLoading(true);
      await save({simulationName, version, desc});
      setSaveLoading(false);
      //setSaveModalVisible(false);
      setTimeout(() => {
        setVisible(false);
      }, 1);
      fetchSavedData();
    },
    [fetchSavedData, save],
  );
  const cancelFun = () => {
    setVisible(false);
  };

  // selection table
  const {
    setTotalSum,
    totalSum,
    selectionBounds,
    setSelectionBounds,
    selectionArea,
  } = UseSelectionBounds();
  // console.log('selectionBounds in Savesim: ', selectionBounds);
  // console.log('selectionArea in Savesim: ', selectionArea);

  const tableRef = useRef(null);
  const [isSelecting, setIsSelecting] = useState(false);
  // console.log('isSelecting: ', isSelecting);

  const [startCoord, setStartCoord] = useState({x: 0, y: 0});
  const [currentCoord, setCurrentCoord] = useState({x: 0, y: 0});

  const handleMouseDown = useCallback((event) => {
    // console.debug('handle mouse down');
    setIsSelecting(true);
    setStartCoord({x: event.pageX, y: event.pageY});
  }, []);

  const handleMouseUp = useCallback(() => {
    // console.debug('handle mouse up');
    setIsSelecting(false);
    setTotalSum(0);

    if (
      !!startCoord.x &&
      !!currentCoord.x &&
      !!startCoord.y &&
      !!currentCoord.y
    ) {
      setSelectionBounds({
        x1: Math.min(startCoord.x, currentCoord.x),
        y1: Math.min(startCoord.y, currentCoord.y),
        x2: Math.max(startCoord.x, currentCoord.x),
        y2: Math.max(startCoord.y, currentCoord.y),
      });
    }
  }, [currentCoord, startCoord]);

  const handleMouseMove = useCallback((event) => {
    // console.debug('handleMouseMove: ');
    setCurrentCoord({x: event.pageX, y: event.pageY});
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        console.debug('click outside');
        setTotalSum(0);
        setSelectionBounds({
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        });
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [tableRef]);

  return (
    <>
      <Modal
        centered
        open={visible}
        footer={null}
        closeIcon={null}
        closable={false}
        width={1300}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}>
        <div
          style={{
            // margin: '24px 0px 24px 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <span
            style={{
              fontWeight: '500',
              fontSize: '20px',
            }}>
            Simulation
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '24px',
            }}>
            <Segmented
              options={['Week', 'Month']}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '2px',
                background: 'rgba(0, 0, 0, 0.06)',
                borderRadius: '2px',
                fontWeight: '400',
                fontSize: '14px',
              }}
            />
            <div style={{fontSize: '14px', fontStyle: 'normal'}}>
              <span style={{color: 'rgba(0, 0, 0, 0.60)', fontWeight: '400'}}>
                {'Date: '}
              </span>
              <span style={{color: '#00000', fontWeight: '500'}}>
                {obj.updatedAt}
              </span>
            </div>
            <div style={{fontSize: '14px', fontStyle: 'normal'}}>
              <span style={{color: 'rgba(0, 0, 0, 0.60)', fontWeight: '400'}}>
                {'By: '}
              </span>
              <span style={{color: '#00000', fontWeight: '500'}}>
                {obj.updatedBy}
              </span>
            </div>
          </div>
        </div>
        <div
          className='servicerisk-simulationmodal'
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}>
          {/* <span style={{}}> Selection Sum: {totalSum}</span> */}
          <Table
            // ref={tableRef}
            style={{
              width: '100%',
            }}
            scroll={{
              x: scrollWidth,
            }}
            indentSize={0}
            loading={loading}
            columns={projectionCols}
            size='small'
            dataSource={projectionData}
            pagination={false}
          />
          {/* {isSelecting && (
            <div
              style={{
                position: 'fixed',
                top: Math.min(startCoord.y ?? 0, currentCoord.y ?? 0),
                left: Math.min(startCoord.x ?? 0, currentCoord.x ?? 0),
                outline: '1px solid blue',
                backgroundColor: '#33333320',
                height: Math.abs((currentCoord.y ?? 0) - (startCoord.y ?? 0)),
                width: Math.abs((currentCoord.x ?? 0) - (startCoord.x ?? 0)),
              }}
            />
          )} */}
          <KeysFrame style={{marginTop: 20}} showKeys={false} />
        </div>
        <div
          style={{
            width: '100%',
            marginBottom: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 20,
          }}>
          <AiOutlineReload onClick={reset} style={{fontSize: 30}} />
          <Button
            type='text'
            onClick={() => {
              reset();
              cancelFun();
            }}
            style={{marginRight: 10}}>
            Cancel
          </Button>
          <Button
            type='primary'
            onClick={() => {
              //setSaveModalVisible(true);
              saveSimulationFun({
                simulationName: obj.simname,
                version: obj.version,
                desc: obj.desc,
              });
            }}
            style={{background: '#1890FF'}}
            loading={saveLoading}>
            Update Simulation
          </Button>
        </div>
        {/* <Modal
          centered
          width={261}
          heigh={266}
          open={saveModalVisible}
          footer={null}
          closeIcon={null}
          closable={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              borderRadius: '4px',
            }}>
            <div
              style={{
                fontWeight: '600',
                fontStyle: 'normal',
                fontSize: '16px',
                color: '#000',
              }}>
              {'Save simulation'}
            </div>
            <div style={{width: '100%'}}>
              <Input
                placeholder='Type name here'
                onChange={(e) => setSimulationName(e.target.value)}
                maxLength={20}
                autoFocus={true}
                disabled={saveLoading}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <Button
                type='text'
                onClick={() => setSaveModalVisible(false)}
                style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.85)'}}>
                {'Cancel'}
              </Button>
              <Button
                type='primary'
                onClick={() =>
                  saveSimulationFun({
                    simulationName: simulationName,
                    version: obj.version,
                  })
                }
                style={{background: '#1890FF', fontSize: '14px'}}
                disabled={simulationName.length === 0}
                loading={saveLoading}>
                {'Save'}
              </Button>
            </div>
          </div>
        </Modal> */}
      </Modal>

      <div
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          setVisible(true);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          gap: '12px',
          marginLeft: '20px',
          marginTop: '30px',
          cursor: 'pointer',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
            alignSelf: 'stretch',
            borderRadius: '4px',
            background: '#F5F5F5',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'stretch',
              gap: '34px',
              maxWidth: '75%',
            }}>
            <div
              style={{
                color: '#000000',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                display: 'flex',
                alignSelf: 'stretch',
                alignItems: 'center',
              }}>
              <span>
                {obj.simname}
                {' -  '}
                <span
                  style={{
                    fontSize: '14px',
                    alignItems: 'center',
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.60)',
                  }}>
                  {obj.desc}
                </span>
              </span>

              {/* <br />
              <div
                style={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  fontWeight: 400,
                  color: 'rgba(0, 0, 0, 0.60)',
                }}>
                {obj.desc != '' ? obj.desc : null}
              </div> */}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '13px',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '24px',
              }}>
              <div style={{fontSize: '14px', fontStyle: 'normal'}}>
                <span style={{color: 'rgba(0, 0, 0, 0.60)', fontWeight: '400'}}>
                  {'Date: '}
                </span>
                <span style={{color: '#00000', fontWeight: '500'}}>
                  {obj.updatedAt}
                </span>
              </div>
              <div style={{fontSize: '14px', fontStyle: 'normal'}}>
                <span style={{color: 'rgba(0, 0, 0, 0.60)', fontWeight: '400'}}>
                  {'By: '}
                </span>
                <span style={{color: '#00000', fontWeight: '500'}}>
                  {obj.updatedBy}
                </span>
              </div>
            </div>
            <DeleteSavedSimulation
              material={obj.product_id}
              location={obj.location_id}
              version={obj.version}
              simname={obj.simname}
              fetchSavedData={fetchSavedData}>
              <img
                src={moreIcon}
                style={{cursor: 'pointer'}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </DeleteSavedSimulation>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedSimulationDataProvider;
