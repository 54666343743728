import React from 'react';
import {createSlice} from '@reduxjs/toolkit';
const serviceRiskSlice = createSlice({
  name: 'columns',
  initialState: {
    data: [],
    columnData: [],
    filteredData: [],
    columns: [],
    scrollWidth: 0,
    loading: false,
    dosTooltipData: {},
    mtdSalesTooltipData: {},
    commentList: [],
    globalFilterInput: '',
    saveGlobalFilterInput: '',
    filterList: {
      prod_desc: [],
      product_id: [],
      ctry: [],
      panda_code: [],
      gmc: [],
      plant: [],
      brand: [],
      mrp_controller: [],
      Shipper_Qty: [],
      Pallet_Qty: [],
      fromlocation: [],
      target_inv: [0, 0],
      min_inv: [0, 0],
      max_inv: [0, 0],
      mmtd_sales: [0, 0],
      inv_movement_qty: [],
      curr_stock_in_hand: [],
      worst_case: [],
      inv_movement_delay: [],
    },
    saveCommentLoading: false,
  },
  reducers: {
    updateData(state, action) {
      const data = action.payload.data !== undefined ? action.payload.data : [];
      const columnData =
        action.payload.columnData !== undefined
          ? action.payload.columnData
          : [];
      state.data = data;
      state.columnData = columnData;
    },
    initColumns(state, action) {
      state.columns = action.payload.columns;
      state.scrollWidth = action.payload.scrollWidth;
    },
    updateColumns(state, action) {
      const columnData =
        action.payload.columnData !== undefined
          ? action.payload.columnData
          : [];
      state.columnData = columnData;
    },
    updateFilteredData(state, action) {
      const filteredData =
        action.payload.filteredData !== undefined
          ? action.payload.filteredData
          : [];
      state.filteredData = filteredData;
    },
    updateLoading(state, action) {
      state.loading = action.payload.loading;
    },
    updateServiceRiskDataOnly(state, action) {
      state.data = action.payload.data;
    },
    updateDosTooltip(state, action) {
      const dosTooltipData =
        action.payload.dosTooltipData !== undefined
          ? action.payload.dosTooltipData
          : {};
      state.dosTooltipData = dosTooltipData;
    },
    updateMTDSalesTooltip(state, action) {
      const mtdSalesTooltipData =
        action.payload.mtdSalesTooltipData !== undefined
          ? action.payload.mtdSalesTooltipData
          : {};
      state.mtdSalesTooltipData = mtdSalesTooltipData;
    },
    updateCommentList(state, action) {
      state.commentList = action.payload.commentList;
    },
    updateSaveCommentLoading(state, action) {
      state.saveCommentLoading = action.payload.saveCommentLoading;
    },
    saveCommentInData(state, action) {
      let objIndex = state.data?.findIndex(
        (obj) =>
          obj.product_id === action.payload.product_id &&
          obj.location_id === action.payload.location_id &&
          obj.brand === action.payload.brand,
      );
      if (objIndex !== -1) {
        state.data[objIndex]['comment'] = state.commentList[0]['comment'];
        state.data[objIndex]['commentdt'] = state.commentList[0]['updatedAt'];
        state.data[objIndex]['commentby'] = state.commentList[0]['updatedBy'];
        state.data[objIndex]['commflag'] = true;
      }
    },
    deleteCommentInData(state, action) {
      let objIndex = state.data?.findIndex(
        (obj) =>
          obj.product_id === action.payload.product_id &&
          obj.location_id === action.payload.location_id,
      );
      state.commentList
        .splice(
          state.commentList.findIndex(function (map) {
            return map.comment_id === action.payload.comment_id;
          }),
          1,
        )
        .reverse();

      if (objIndex !== -1) {
        if (state.commentList.length === 0) {
          state.data[objIndex]['comment'] = '';
          state.data[objIndex]['commentdt'] = '';
          state.data[objIndex]['commentby'] = '';
          state.data[objIndex]['commflag'] = false;
        } else if (state.commentList.length >= 1) {
          state.data[objIndex]['comment'] = state.commentList[0]['comment'];
          state.data[objIndex]['commentdt'] = state.commentList[0]['updatedAt'];
          state.data[objIndex]['commentby'] = state.commentList[0]['updatedBy'];
        }
      }
    },
    updateCommentInData(state, action) {
      let objIndex = state.commentList?.findIndex(
        (obj) => obj.comment_id === String(action.payload.comment_id),
      );
      if (objIndex !== -1) {
        state.commentList[objIndex]['comment'] = action.payload.comment;
        state.commentList[objIndex]['updatedAt'] = action.payload.updatedAt;
        state.commentList[objIndex]['updatedBy'] = action.payload.user;
        if (objIndex === 0) {
          let dataIndex = state.data?.findIndex(
            (obj) =>
              obj.product_id === action.payload.product_id &&
              obj.location_id === action.payload.location_id &&
              obj.brand === action.payload.brand,
          );
          if (dataIndex !== -1) {
            state.data[objIndex]['comment'] = action.payload.comment;
            state.data[objIndex]['commentby'] = action.payload.user;
            state.data[objIndex]['commentdt'] = action.payload.updatedAt;
          }
        }
      }
    },
    updateGlobalFilterInput(state, action) {
      state.globalFilterInput = action.payload.globalFilterInput;
    },
    updateSaveGlobalFilterInput(state, action) {
      state.saveGlobalFilterInput = action.payload.saveGlobalFilterInput;
    },
    updateFilterList(state, action) {
      state.filterList = {
        ...state.filterList,
        ...action.payload.filterList,
      };
    },
    updateServiceRiskDataSimFlag(state, action) {
      state.data[action.payload.index].simflag = action.payload.value;
    },
    resetSaveGlobalFilterInput(state) {
      state.saveGlobalFilterInput = '';
    },
  },
});
export const serviceRiskActions = serviceRiskSlice.actions;
export default serviceRiskSlice.reducer;
