import {createSlice} from '@reduxjs/toolkit';
const projectionSimulationSlice = createSlice({
  name: 'prsim',
  initialState: {
    forcastData: [],
    projectionData: [],
    projectionTableData: [],
    projectionColumns: [],
    savedSimulationList: [],
    projectionDataLoading: false,
    headerDropDowns: {
      selectedCountry: null,
      countriesList: [
        {
          value: 'AU',
          label: 'AU',
        },
        {
          value: 'CN',
          label: 'CN',
        },
        {
          value: 'JP',
          label: 'JP',
        },
        {
          value: 'IN',
          label: 'IN',
        },
        {
          value: 'KR',
          label: 'KR',
        },
        {
          value: 'NZ',
          label: 'NZ',
        },
      ],
      selectedBrand: null,
      brandsList: [],
      selectedProductDetail: {product: null, desc: null},
      productDetailList: [],
    },
  },
  reducers: {
    updateProjectionTableData(state, action) {
      const data = action.payload.data !== undefined ? action.payload.data : [];
      state.projectionTableData = data;
    },
    updateProjectionData(state, action) {
      const data = action.payload.data !== undefined ? action.payload.data : [];
      state.projectionData = data;
      const columnsData =
        action.payload.columns !== undefined ? action.payload.columns : [];
      state.projectionColumns = columnsData;
    },
    updateProjectionDataLoading(state, action) {
      state.projectionDataLoading = action.payload.loading;
    },
    updateSelectedCountry(state, action) {
      state.headerDropDowns.selectedCountry = action.payload.country;
      state.headerDropDowns.selectedBrand = null;
      state.headerDropDowns.selectedProductDetail = {product: null, desc: null};
      state.headerDropDowns.brandsList = Array.from(
        new Set(
          action.payload.serviceRiskData
            .filter((obj) => obj.ctry == action.payload.country)
            .map((obj) => obj.brand),
        ),
      ).map((brand) => ({
        value: brand,
        label: brand,
      }));
      state.headerDropDowns.productDetailList = action.payload.serviceRiskData
        .filter((obj) => obj.ctry === state.headerDropDowns.selectedCountry)
        .map((obj) => ({
          value: obj.product_id + ',' + obj.prod_desc + ',' + obj.location_id,
          label: obj.product_id + ' ' + obj.prod_desc,
        }));
    },
    updateForcastData(state, action) {
      state.forcastData = action.payload.forecastData;
    },
    updatedSelectedBrand(state, action) {
      state.headerDropDowns.selectedBrand = action.payload.brand;
      state.headerDropDowns.selectedProductDetail = {product: null, desc: null};
      state.headerDropDowns.productDetailList = action.payload.serviceRiskData
        .filter(
          (obj) =>
            obj.ctry === state.headerDropDowns.selectedCountry &&
            obj.brand === state.headerDropDowns.selectedBrand,
        )
        .map((obj) => ({
          value: obj.product_id + ',' + obj.prod_desc + ',' + obj.location_id,
          label: obj.product_id + ' ' + obj.prod_desc,
        }));
    },
    initializeHeaderDropDowns(state, action) {
      // console.log(
      //   'init',
      //   action.payload,
      //   'state',
      //   state.headerDropDowns.selectedBrand,
      //   state.headerDropDowns.selectedCountry,
      // );
      if (
        (state.headerDropDowns.selectedBrand == null &&
          state.headerDropDowns.selectedCountry == null) ||
        (state.headerDropDowns.selectedBrand != action.payload.brand &&
          state.headerDropDowns.selectedBrand != null)
      )
        state.headerDropDowns.selectedBrand = action.payload.brand;
      state.headerDropDowns.selectedCountry = action.payload.country;
      state.headerDropDowns.selectedProductDetail = {
        product: action.payload.product,
        desc: action.payload.desc,
      };
      state.headerDropDowns.brandsList = Array.from(
        new Set(
          action.payload.serviceRiskData
            .filter((obj) => obj.ctry == action.payload.country)
            .map((obj) => obj.brand),
        ),
      ).map((brand) => ({
        value: brand,
        label: brand,
      }));
      state.headerDropDowns.productDetailList = action.payload.serviceRiskData
        .filter(
          (obj) =>
            obj.ctry === action.payload.country &&
            (state.headerDropDowns.selectedBrand === null ||
              obj.brand === action.payload.brand),
        )
        .map((obj) => ({
          value: obj.product_id + ',' + obj.prod_desc + ',' + obj.location_id,
          label: obj.product_id + ' ' + obj.prod_desc,
        }));
    },
    updateSavedSimulationList(state, action) {
      const data = action.payload.list !== undefined ? action.payload.list : [];
      state.savedSimulationList = data;
    },
    // to update the product options
    updateProductOptions(state, action) {
      state.headerDropDowns.productDetailList = action.payload.productOptions;
    },
  },
});
export const projectionSimulationActions = projectionSimulationSlice.actions;
export default projectionSimulationSlice.reducer;
