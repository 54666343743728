import {Select} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
const ProductDropDown = ({onChange}) => {
  const {headerDropDowns} = useSelector((state) => state.prSims);
  const updateProductFun = React.useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );
  // console.log(headerDropDowns.productDetailList);
  return (
    <>
      <Select
        showSearch
        value={
          headerDropDowns.selectedProductDetail.product === null
            ? null
            : headerDropDowns.selectedProductDetail.product +
              ' ' +
              headerDropDowns.selectedProductDetail.desc
        }
        placeholder={'Select Product'}
        style={{
          width: 320,
        }}
        onChange={updateProductFun}
        options={[...headerDropDowns.productDetailList].sort((a, b) =>
          a.label.localeCompare(b.label),
        )}
      />
    </>
  );
};

export default React.memo(ProductDropDown);
