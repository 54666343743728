import React from 'react';
import BrandDropDown from './BrandDropDown';
import ProductDropDown from './ProductDropDown';
import CountryDropDown from './CountryDropDown';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { projectionSimulationActions } from 'redux/reducers/projectionSimulationSlice';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/ContextProvider';
import { getServiceRiskDataOnly } from 'redux/actions/serviceRiskActions';
const HeaderDropDowns = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  //const {record} = location.state;
  const record =
    location?.state && location?.state?.record ? location?.state?.record : null;

  const { data } = useSelector((state) => state.srCols);
  const { simId } = useParams();
  const [projId, locId] = simId.split(',');
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);
  const [countryList, setCountryList] = React.useState([]);

  const updateCountryList = React.useCallback(async () => {
    let countryDetails = [];

    auth?.location.map((value, index) => {
      if (value == 'Global') {
        countryDetails.push(
          {
            value: 'AU',
            label: 'AU',
          },
          {
            value: 'CN',
            label: 'CN',
          },
          {
            value: 'JP',
            label: 'JP',
          },
          {
            value: 'IN',
            label: 'IN',
          },
          {
            value: 'KR',
            label: 'KR',
          },
          {
            value: 'NZ',
            label: 'NZ',
          },
        );
      } else if (value == 'AU') {
        countryDetails.push({
          value: 'AU',
          label: 'AU',
        });
      } else if (value == 'CN') {
        countryDetails.push({
          value: 'CN',
          label: 'CN',
        });
      } else if (value == 'JP') {
        countryDetails.push({
          value: 'JP',
          label: 'JP',
        });
      } else if (value == 'IN') {
        countryDetails.push({
          value: 'IN',
          label: 'IN',
        });
      } else if (value == 'KR') {
        countryDetails.push({
          value: 'KR',
          label: 'KR',
        });
      } else if (value == 'NZ') {
        countryDetails.push({
          value: 'NZ',
          label: 'NZ',
        });
      }
    });
    setCountryList(countryDetails);
  }, [auth?.location, setCountryList]);
  React.useEffect(() => {
    updateCountryList();
  }, [updateCountryList]);
  React.useEffect(() => {
    if (record != null) {
      dispatch(
        projectionSimulationActions.initializeHeaderDropDowns({
          country: record['ctry'],
          brand: record['brand'],
          product: record['product_id'],
          location: record['location_id'],
          desc: record['prod_desc'],
          serviceRiskData: data,
        }),
      );
    } else if (
      record == null &&
      data.length != 0 &&
      projId != null &&
      locId != null
    ) {
      const selectedRecord = data.find(
        (obj) => obj.product_id == projId && obj.location_id == locId,
      );
      dispatch(
        projectionSimulationActions.initializeHeaderDropDowns({
          country: selectedRecord['ctry'],
          brand: selectedRecord['brand'],
          product: selectedRecord['product_id'],
          location: selectedRecord['location_id'],
          desc: selectedRecord['prod_desc'],
          serviceRiskData: data,
        }),
      );
    }
  }, [data, dispatch, locId, projId, record]);

  const updateCountryFun = (value) => {
    dispatch(
      projectionSimulationActions.updateSelectedCountry({
        country: value,
        serviceRiskData: data,
      }),
    );
  };
  const updateBrandFun = (value) => {
    dispatch(
      projectionSimulationActions.updatedSelectedBrand({
        brand: value,
        serviceRiskData: data,
      }),
    );

  };

  const updateProductFun = (value) => {
    const details = value.split(',');
    const prodId = details[0];
    const locId = details[details.length - 1];
    //const [prodId, desc, locId] = value.split(',');
    const record = data.filter(
      (obj) => obj.product_id === prodId && obj.location_id === locId,
    );
    navigate(`/ServiceRiskReport/${prodId},${locId}`, {
      state: { record: record[0] },
      replace: true,
    });
  };

  React.useEffect(() => {
    if (data?.length === 0) {
      dispatch(getServiceRiskDataOnly({ location: auth?.location }));
    }
  }, [dispatch, data?.length, auth?.location]);
  return (
    <>
      <CountryDropDown countryList={countryList} onChange={updateCountryFun} />
      <BrandDropDown onChange={updateBrandFun} />
      <ProductDropDown onChange={updateProductFun} />
    </>
  );
};

export default React.memo(HeaderDropDowns);
