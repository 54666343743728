import {Tooltip} from 'antd';
import {useCallback, useState} from 'react';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
  useNodesState,
  useEdgesState,
  Position,
  addEdge,
  EdgeLabelRenderer,
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';

const initialEdges = [
  {
    id: '1-2',
    source: '1',
    target: '2',
    data: {
      label: 'edge label',
    },
    // markerEnd: {
    //   type: MarkerType.ArrowClosed,
    //   color: '#000000',
    // },
    type: 'custom',
  },
  {
    id: '2-3',
    source: '2',
    target: '3',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: 'black',
    },
    type: 'custom',
  },
  {
    id: '2-4',
    source: '2',
    target: '4',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },
  {
    id: '5-6',
    source: '5',
    target: '6',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },
  {
    id: '5-7',
    source: '5',
    target: '7',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },

  {
    id: '8-9',
    source: '8',
    target: '9',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },
  {
    id: '9-10',
    source: '9',
    target: '10',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },
  {
    id: '9-11',
    source: '9',
    target: '11',
    data: {
      label: 'edge label',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#000000',
    },
    type: 'custom',
  },
];

const nodeTypes = {
  customNd: CustomNode,
};
// x-right side, y - down
const initialNodes = [
  {
    id: '1',
    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$24.55',
      date: '41d',
      width: 125,
      height: 125,
      fontSize: 32,
    },
    position: {x: 170, y: 0},
    size: 140,
  },
  {
    id: '2',
    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$15.87',
      date: '58d',
      width: 105,
      height: 105,
      fontSize: 26,
    },
    position: {x: 98, y: 170},
    size: 120,
  },
  {
    id: '3',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$10.89',
      date: '52d',
      width: 95,
      height: 95,
      fontSize: 20,
    },
    position: {x: 18, y: 348},
    size: 100,
  },
  {
    id: '4',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$10.54',
      date: '36d',
      width: 80,
      height: 80,
      fontSize: 21,
    },
    position: {x: 197, y: 341},
    size: 58,
  },
  {
    id: '5',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$5.89',
      date: '15d',
      width: 140,
      height: 140,
      fontSize: 35,
    },
    position: {x: 470, y: 148},
    size: 90,
  },
  {
    id: '6',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$8.35',
      date: '85d',
      width: 95,
      height: 95,
      fontSize: 19,
    },
    position: {x: 365, y: 313},
    size: 90,
  },
  {
    id: '7',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$9.53',
      date: '28d',
      width: 140,
      height: 140,
      fontSize: 40,
    },
    position: {x: 557, y: 317},
    size: 90,
  },
  {
    id: '8',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$6.22',
      date: '01d',
      width: 135,
      height: 135,
      fontSize: 30,
    },
    position: {x: 664, y: 14},
    size: 90,
  },
  {
    id: '9',
    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$1',
      date: '18d',
      width: 90,
      height: 90,
      fontSize: 25,
    },
    position: {x: 820, y: 137},
  },
  {
    id: '10',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$1.10',
      date: '20d',
      width: 120,
      height: 120,
      fontSize: 35,
    },
    position: {x: 739, y: 307},
  },
  {
    id: '11',

    type: 'customNd',
    data: {
      name: 'Jane Doe',
      price: '$18.55',
      date: '68d',
      width: 120,
      height: 120,
      fontSize: 30,
    },
    position: {x: 905, y: 243},
  },
];

const PotentialRiskFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const edgeTypes = {
    custom: CustomEdge,
  };
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => [...eds, {...params, data: {label: 'edge label'}}]),
    [setEdges],
  );
  const maxPrice = Math.max(
    ...nodes.map((node) => parseFloat(node.data.price.replace('$', ''))),
  );

  return (
    <div className='graph' style={{padding: 10, width: 'auto'}}>
      
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            maxPrice,
          },
        }))}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        edges={edges}
        zoomOnScroll={false}
        nodeTypes={nodeTypes}
        panOnDrag={false}>
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default PotentialRiskFlow;
