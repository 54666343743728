import React from 'react';
import {Table, Tooltip} from 'antd';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import InventorySteps from './InventorySteps';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import {QuestionCircleOutlined} from '@ant-design/icons';
const columns = [
  {
    title: '#',
    dataIndex: 'srno',
    key: '#',
    width: '100',
  },
  {
    title: 'PO No',
    dataIndex: 'po',
    key: 'po',
    width: '100',
  },
  {
    title: 'Delivery Id',
    dataIndex: 'orderId',
    key: 'orderId',
    width: '100',
  },
  {
    title: 'Delivery Item',
    dataIndex: 'delitem',
    key: 'delitem',
    width: '100',
  },
  {
    title: 'Delivery Qty',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '100',
  },
  {
    title: 'Batch',
    dataIndex: 'uom',
    key: 'uom',
    width: '100',
  },
  {
    title: 'Estimated Release Time',
    dataIndex: 'eta',
    key: 'ETA',
    width: '100',
  },
  {
    title: (
      <span>
        OMP Target date
        <Tooltip title='This refer to the Target data in OMP during product shipment'>
          <QuestionCircleOutlined style={{marginLeft: 5}} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'omptargetdate',
    key: 'omptargetdate',
    width: '100',
  },
  {
    title: (
      <span>
        Delay Indicator
        <Tooltip title='Use the latest date between (Today or Estimate release date) - OMP target date'>
          <QuestionCircleOutlined style={{marginLeft: 5}} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'delayindicator',
    key: 'delayindicator',
    render: (text, record) => {
      return {
        props: {
          style: {},
        },
        children: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 10,
            }}>
            <div
              style={{
                height: 15,
                width: 15,
                backgroundColor: getDaySupplyBGColor(record?.delayindicator_cc),
                borderRadius: 50,
              }}
            />
            {text}
          </div>
        ),
      };
    },
    width: '100',
  },
  Table.EXPAND_COLUMN,
];

const InventoryMovementDetail = ({record}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginTop: '30px',
      }}
      className='inventory_movement_table'>
      <Table
        style={{width: '100%'}}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <InventorySteps record={record} />,
          rowExpandable: (record) => record.name !== 'Not Expandable',
          expandIcon: ({expanded, onExpand, record}) =>
            expanded ? (
              <BsChevronUp
                size={20}
                style={{color: '#333333', strokeWidth: '1px'}}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <BsChevronDown
                size={20}
                style={{color: '#333333', strokeWidth: '1px'}}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
        dataSource={[record]}
        pagination={false}
      />
    </div>
  );
};

export default React.memo(InventoryMovementDetail);
