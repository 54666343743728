import React from 'react';
import PercentageBar from 'pages/ServiceRiskReport/components/PercentageBar';
import MTDSalesTooltip from '../tooltip/MTDSalesTooltip';
const MTDSalesRender = ({record}) => {
  return (
    <>
      <MTDSalesTooltip record={record}>
        <PercentageBar
          record={record}
          percentage={record['mmtd_sales']}
          filledColor='#1890FF'
          unfilledColor='#E6F7FF'
        />
      </MTDSalesTooltip>
    </>
  );
};

export default React.memo(MTDSalesRender);
