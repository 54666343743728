import * as XLSX from 'xlsx';

const downloadSimulationExcel = ({data, columnData, material, simname}) => {
  try {
    let formattedData = [];
    data.map((obj) => {
      const formattedObj = {
        ...obj,
        Display: obj['display'],
      };

      delete formattedObj['display'];

      // delete if there target_coverage_time,max_coverage_time,min_coverage_time,age_time
      delete formattedObj['target_coverage_time'];
      delete formattedObj['max_coverage_time'];
      delete formattedObj['min_coverage_time'];
      delete formattedObj['age_time'];

      // remove the properties in formattedObj that start with 'w' and contain _ in it
      Object.keys(formattedObj).map((key) => {
        if (key.startsWith('w') && key.includes('_')) {
          delete formattedObj[key];
        }
      });

      if (obj.children) {
        delete formattedObj.children;
      }

      // in formattedObj, replace the properties that start with 'w' with columnData title which matches dataIndex
      columnData.map((column) => {
        // column.dataIndex is vmi remove
        if (column.dataIndex === 'vmi') {
          delete formattedObj[column.dataIndex];
          return;
        }
        // object has property column.dataIndex
        // console.log(column.dataIndex, Object(obj).hasOwnProperty(column.dataIndex), formattedObj[column.dataIndex]);
        if (
          (formattedObj[column.dataIndex] !== undefined &&
            formattedObj[column.dataIndex] !== null &&
            formattedObj[column.dataIndex] !== '' &&
            !isNaN(formattedObj[column.dataIndex])) ||
          formattedObj[column.dataIndex] === 0
        ) {
          formattedObj[column.title] = formattedObj[column.dataIndex];
          delete formattedObj[column.dataIndex];
        } else {
          // Replace NaN and empty strings with blank cells
          formattedObj[column.title] = '';
          delete formattedObj[column.dataIndex];
        }
      });
      // delete from formattedObj the properties that has key that starts with 'w' and has number greater than 14
      Object.keys(formattedObj).map((key) => {
        if (key.startsWith('w') && Number(key.slice(1)) > 27) {
          delete formattedObj[key];
        }
      });

      formattedData.push(formattedObj);
    });
    console.log('Data:', data);
    console.log(columnData);

    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, 'R10-ServiceRisk');
    XLSX.writeFile(wb, `${simname}-${material}.xlsx`);
  } catch (e) {
    console.log(e);
  }
};
export default downloadSimulationExcel;
