import {Button, Card} from 'antd';
import React, {useState} from 'react';
import ReactApexChart from 'react-apexcharts';

import EllipseGreen from 'assets/icon/EllipseGreen.svg';
import EllipseYellow from 'assets/icon/EllipseYellow.svg';
const currDate = new Date();
const NtsDemandChart = () => {
  const [chartState, setChartState] = useState({
    series: [
      {
        name: 'Demand',
        data: [44, 55, 41, 103, 55, 41, 44, 55, 41, 103, 302, 353],
      },
      {
        name: 'Target',
        data: [53, 32, 33, 44, 55, 41, 103, 55, 41, 302, 353, 44],
      },
      {
        name: 'Actual Sales',
        data: [55, 41, 44, 103, 302, 55, 41, 44, 353, 55, 41, 44],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 430,
      },
      gradient: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true, //label at the end
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['black'],
        },
      },
      // stroke: {
      //   show: true,
      //   width: 1,
      //   colors: ['#fff']
      // },
      // tooltip: {
      //   shared: true,
      //   intersect: false
      // },
      xaxis: {
        categories: [
          'jan',
          'feb',
          'march',
          'april',
          'may',
          'june',
          'jully',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
        axisBorder: {
          show: false,
          color: '#FF1654',
        },
        style: {alignitem: 'center', fontSize: 12},
      },
      legend: {
        formatter: function (seriesName) {
          const seriesObject = chartState.series.find(
            (series) => series.name === seriesName,
          );
          return seriesName;
        },

        position: 'bottom',
        horizontalAlign: 'top',
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        itemMargin: {
          horizontal: 16,
          vertical: 0,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          // fillColors: undefined,
          radius: 12,
        },
      },
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gap: '20px',
      }}>
      <Card style={{width: '1200px'}}>
        <div>
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type='bar'
            height={650}
            border={false}
          />
          <div>
            <div>
              <spam> Demand</spam>
              <span>Target</span>
              <span>Actual Sales</span>
            </div>
            <div>
              <span>UoM</span>
              <span>mm</span>
            </div>
            <div>
              <span>Last Update</span>
              <span>
                {currDate
                  .toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })
                  .replace(/\//g, ' ')}
              </span>
            </div>
          </div>
        </div>
      </Card>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '380px',
          //   gap: '10px'
        }}>
        <span className='service-risk-card-header'> KPI Overview</span>

        <span style={{color: 'black', marginLeft: '95px'}}>Actual</span>
        <span style={{color: 'grey'}}>(Target)</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '35px',
            gap: '28px',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px', fontWeight: 700}}>
              <img src={EllipseGreen} style={{marginRight: '5px'}} />
              Inventory
            </span>
            <span style={{fontSize: '14px', fontWeight: 400}}>
              <span style={{color: 'black'}}>2800 MM</span> (
              <span style={{color: 'grey'}}>2,753 MM</span>)
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px', fontWeight: 700}}>
              <img src={EllipseYellow} style={{marginRight: '5px'}} />
              Dos
            </span>
            <span style={{fontSize: '14px', fontWeight: 400}}>
              <span style={{color: '#000000'}}>132 d</span> (
              <span style={{color: '#818181'}}>135 d</span>)
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px', fontWeight: 700}}>
              <img src={EllipseGreen} style={{marginRight: '5px'}} />
              OTIF-D
            </span>
            <span style={{fontSize: '14px', fontWeight: 400}}>
              <span style={{color: '#000000'}}>132 d</span> (
              <span style={{color: '#818181'}}>135 d</span>)
            </span>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px', fontWeight: 700}}>
              <img src={EllipseGreen} style={{marginRight: '5px'}} />
              Stockout
            </span>
            <span style={{fontSize: '14px', fontWeight: 400}}>
              <span style={{color: '#000000'}}>132 d</span> (
              <span style={{color: '#818181'}}>135 d</span>)
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span style={{fontSize: '14px', fontWeight: 700}}>
              <img src={EllipseGreen} style={{marginRight: '5px'}} />
              MAPE-3
            </span>
            <span style={{fontSize: '14px', fontWeight: 400}}>
              <span style={{color: '#000000'}}>132 d</span> (
              <span style={{color: '#818181'}}>135 d</span>)
            </span>
          </div>
          <div style={{textAlign: 'end'}}>
            {' '}
            <Button style={{}}>See trend graph</Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NtsDemandChart;
