import React, {useState, useEffect, useContext} from 'react';
import {Table, Space, Switch, Button, Checkbox, Input} from 'antd';
import axiosConfig from 'components/services/lib/axios';
import {useParams} from 'react-router-dom';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import {
  FilterFilled,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

export const fetchInvStatus = (projId, locId) => {
  return axiosConfig
    .post('/projinvstatus', {material: projId, location_id: locId})
    .then((res) => {
      if (res?.data) {
        // Process the data as needed
        return res.data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.error('Error fetching data from API:', err);
      throw err; // You can handle the error as needed
    });
};

const InventoryStatusDetailContext = React.createContext({});

const InventoryStatusFilter = (filterColumn, index) => {
  return {
    // filtered: true,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const {
        filterList,
        setFilterList,
        statusData: data,
      } = useContext(InventoryStatusDetailContext);
      const [choices, setChoices] = React.useState([]);
      const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
      const [inputValue, setInputValue] = React.useState('');
      const [isSearchClicked, setIsSearchClicked] = React.useState(false);
      React.useEffect(() => {
        if (isSearchClicked === false) {
          setSelectedCheckboxes(
            filterList[index]?.length != 0 ? [...filterList[index]] : [],
          );
          setSelectedKeys(
            filterList[index]?.length != 0 ? [...filterList[index]] : [],
          );

          //confirm();
        }
      }, [filterList[index]]);

      React.useEffect(() => {
        if (data != null && data != undefined && data?.length > 0) {
          // console.log(data, 'data');
          const finalData = [];
          // append all of data and data.children, data.children.children recursively
          const appendChildren = (data) => {
            data.forEach((item) => {
              finalData.push(item);
              if (item.children && item.children.length > 0) {
                appendChildren(item.children);
              }
            });
          };
          appendChildren(data);
          // const filteredFinalData = finalData.filter(
          //   (item) =>
          //     filterList['qm'].includes(item['qm'].toString()) &&
          //     filterList['totalqty'].includes(item['totalqty'].toString()) &&
          //     filterList['blocked_qty'].includes(
          //       item['blocked_qty'].toString(),
          //     ) &&
          //     item['qm'] !== 0 &&
          //     item['totalqty'] !== 0 &&
          //     item['blocked_qty'] !== 0,
          // );

          setChoices(
            Array.from(
              new Set(
                // data
                //   ?.map((item) => {
                //     return item[index] !== null ? item[index] : '';
                //   }),
                // above doenst consider child nodes and children of children
                finalData
                  ?.flatMap((item) => {
                    if (item.children && item.children.length > 0) {
                      return [
                        item[index],
                        ...item.children.map((child) => child[index]),
                      ];
                    } else {
                      return [item[index].toString()];
                    }
                  })
                  .filter((e) => e !== null && e !== undefined)
                  .map((e) => e.toString()),
              ),
            ),
          );
        }
      }, [data]);

      const options = inputValue
        ? choices.filter((value) =>
            value.toLowerCase().includes(inputValue.toLowerCase()),
          )
        : choices;

      const handleReset = () => {
        setInputValue('');
        //setOptions(choices);
        setFilterList({
          ...filterList,
          [index]: [],
        });
        setSelectedCheckboxes([]); // Uncheck all checkboxes
        setSelectedKeys([]);
        clearFilters();
        //confirm({closeDropdown: false});
      };
      const handleSearch = () => {
        setIsSearchClicked(true);
        setFilterList({
          ...filterList,
          [index]: [...selectedKeys],
        });
        setIsSearchClicked(false);
      };
      React.useEffect(() => {
        setSelectedKeys([...selectedCheckboxes]);
      }, [selectedCheckboxes]);
      // console.log(
      //   selectedCheckboxes,
      //   'selectedCheckboxes',
      //   inputValue,
      //   'inputValue',
      // );
      return (
        <div style={{padding: 8, maxHeight: '500px'}}>
          <div>
            <Input
              placeholder={`Search ${filterColumn}`}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              style={{width: '100%', marginBottom: 8, display: 'block'}}
            />
          </div>

          <Space style={{width: '100%'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                maxHeight: '300px',
                // overflowX: 'scroll',
                overflowY: 'auto',
                minWidth: '250px',
              }}>
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  width: '100%',
                  gap: '8px',
                }}>
                <Checkbox
                  checked={
                    selectedCheckboxes.length === options.length &&
                    options.length !== 0
                  }
                  onChange={(e) => {
                    if (
                      selectedCheckboxes.length === 0 ||
                      selectedCheckboxes.length < options.length
                    ) {
                      setSelectedCheckboxes(options);
                    } else {
                      setSelectedCheckboxes([]);
                    }
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    Select All
                  </div>
                </Checkbox>
              </div>
              {
                // sort alphabetically
                options?.sort()?.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      width: '100%',
                      gap: '8px',
                    }}>
                    <Checkbox
                      value={option}
                      checked={selectedCheckboxes.includes(option)}
                      onChange={(e) => {
                        if (selectedCheckboxes.includes(e.target.value)) {
                          setSelectedCheckboxes(
                            selectedCheckboxes.filter(
                              (item) => item !== e.target.value,
                            ),
                          );
                        } else {
                          setSelectedCheckboxes((prev) => [
                            ...prev,
                            e.target.value,
                          ]);
                        }
                      }}
                      style={{
                        marginRight: '8px',
                        width: '100%',
                        height: '16px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          // minWidth: '200px',
                        }}>
                        {option}
                      </div>
                    </Checkbox>
                  </div>
                ))
              }
            </div>
          </Space>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Button
              type='primary'
              onClick={handleSearch}
              icon={<SearchOutlined />}
              size='small'
              style={{width: 90}}>
              Search
            </Button>
            <Button onClick={handleReset} size='small' style={{width: 90}}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    onFilter: (value, record) => {
      return Array.isArray(value)
        ? value.includes(record[index].toString().toLowerCase())
        : record[index].toString() == value;
    },
    filterIcon: () => {
      const {filterList} = useContext(InventoryStatusDetailContext);
      return React.createElement(
        index == 'prod_desc' ? SearchOutlined : FilterFilled,
        {
          style: {color: filterList[index] != '' ? '#1890ff' : '#bfbfbf'},
        },
      );
    },
  };
};

const InventoryStatusDetailComponent = () => {
  const {
    loading,
    setLoading,
    statusData,
    setStatusData,
    finalData,
    resetFilters,
  } = useContext(InventoryStatusDetailContext);
  const {simId} = useParams();
  const [projId, locId] = simId.split(',');
  const [expandedKeys, setExpandedKeys] = useState([]);

  const toggleExpand = (key) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k) => k !== key));
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
    // console.log('expandedKeys', expandedKeys);
  };

  const columns = [
    {
      title: (
        <div className='flex flex-row'>
          <Button
            style={{
              width: 20,
              height: 20,
              lineHeight: 0,
              verticalAlign: 0,
              marginRight: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (expandedKeys.length === 0) {
                // flatten the array
                setExpandedKeys(
                  statusData.flatMap((item) => {
                    if (item.children && item.children.length > 0) {
                      return [
                        item.key,
                        ...item.children.map((child) => child.key),
                      ];
                    } else {
                      return [item.key];
                    }
                  }),
                );
                resetFilters();

                // setExpandedKeys(statusData.map((item) => item.key));
              } else {
                setExpandedKeys([]);
                resetFilters();
              }
            }}
            icon={
              statusData.flatMap((item) => {
                if (item.children && item.children.length > 0) {
                  return [item.key, ...item.children.map((child) => child.key)];
                } else {
                  return [item.key];
                }
              }).length === expandedKeys.length ? (
                // <MinusOutlined style={{fontSize: 10}} />
                '-'
              ) : expandedKeys.length === 0 ? (
                // <PlusOutlined style={{fontSize: 10}} />
                '+'
              ) : (
                <div
                  style={{width: 10, height: 10, backgroundColor: 'black'}}
                />
              )
            }></Button>
          Location ID /Batch ID
        </div>
      ),
      dataIndex: 'location',
      key: 'locationId',
      width: 300,
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalqty',
      key: 'totalQuantity',
      width: 100,
      ...InventoryStatusFilter('Total Quantity', 'totalqty'),
    },
    {
      title: 'Unrestricted',
      dataIndex: 'unrestricted_qty',
      key: 'unrestricted',
      width: 100,
      ...InventoryStatusFilter('Unrestricted', 'unrestricted_qty'),
    },
    {
      title: 'QM',
      dataIndex: 'qm',
      key: 'qm',
      width: 100,
      ...InventoryStatusFilter('QM', 'qm'),
    },
    {
      title: 'Block',
      dataIndex: 'blocked_qty',
      key: 'block',
      width: 100,
      ...InventoryStatusFilter('Block', 'blocked_qty'),
    },
    {
      title: 'Restricted',
      dataIndex: 'restricted_qty',
      key: 'restricted',
      width: 100,
      ...InventoryStatusFilter('Restricted', 'restricted_qty'),
    },
    {
      title: 'Exp. Date',
      dataIndex: 'expdate',
      key: 'expdate',
      width: 120,
    },
    {
      title: 'MFG. Date',
      dataIndex: 'mfgdate',
      key: 'mfgdate',
      width: 120,
    },
    {
      title: 'Remaining Shelf Life',
      dataIndex: 'days',
      key: 'days',
      width: 140,
    },
    {
      title: 'RSL%',
      dataIndex: 'per',
      key: 'per',
      width: 20,
      render: (text, record) => {
        let backgroundColor = '';

        if (record.per_cc < 0.001) {
          backgroundColor = '';
        } else if (record.per_cc === 1) {
          backgroundColor = '#FA4545';
        } else if (record.per_cc === 2) {
          backgroundColor = getDaySupplyBGColor(2);
        } else if (record.per_cc === 3) {
          backgroundColor = getDaySupplyBGColor(3);
        }

        return {
          props: {
            style: {
              width: 20,
              background: backgroundColor,
            },
          },
          children: <>{text}</>,
        };
      },
    },
    {
      title: 'MAD',
      dataIndex: 'matdate',
      key: 'material_avail_date',
      width: 100,
    },
  ];
  React.useEffect(() => {
    setLoading(true);
    axiosConfig
      .post('/projinvstatus', {material: projId, location_id: locId})
      .then((res) => {
        // console.log('response data from api-', res);
        setLoading(false);

        if (res?.data) {
          const updateUniqueKeys = (data, keyGenerator) => {
            data.forEach((item) => {
              item.key = keyGenerator(); // Update the key with a new unique value
              if (item.children && item.children.length > 0) {
                updateUniqueKeys(item.children, keyGenerator); // Recursively update child keys
              }
            });
          };
          let currentKey = 1;
          const generateUniqueKey = () => {
            return currentKey++;
          };
          updateUniqueKeys(res?.data, generateUniqueKey);
          setStatusData(res?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error fetching data from API:', err);
      });
  }, [locId, projId]);

  // const hideData = (finalData) => {
  //   return finalData.filter(
  //     (record) =>
  //       record.qm !== 0 && record.totalqty !== 0 && record.blocked_qty !== 0,
  //   );
  // };

  // // Filter columns based on the condition
  // const filterData = finalData.filter((record) => {
  //   return !hideData(finalData[0]); // Assuming data is available and not empty
  // });

  return (
    <>
      <Table
        columns={columns}
        dataSource={finalData}
        expandable={{
          expandedRowKeys: expandedKeys,
          // expandedRowRender: (record) => {
          //   return (
          //     <div>{JSON.stringify(record)}</div>
          //   );
          // },
          expandIcon: ({expanded, onExpand, record}) => {
            const {resetFilters} = useContext(InventoryStatusDetailContext);
            return (
              <Space className='mr-4'>
                {record.children && record.children.length > 0 ? (
                  <Button
                    size='small'
                    style={{
                      width: 20,
                      height: 20,
                      lineHeight: 'initial',
                      verticalAlign: 0,
                      marginRight: 10,
                    }}
                    icon={
                      expanded
                        ? // <MinusOutlined style={{fontSize: 10}} />
                          '-'
                        : // <PlusOutlined style={{fontSize: 10}} />
                          '+'
                    }
                    onClick={() => {
                      toggleExpand(record.key);
                    }}
                  />
                ) : null}
              </Space>
            );
          },
        }}
        pagination={false}
        loading={loading}
      />
    </>
  );
};

const InventoryStatusDetail = () => {
  const [loading, setLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [filterList, setFilterList] = useState({
    totalqty: [],
    location: [],
    unrestricted_qty: [],
    qm: [],
    blocked_qty: [],
    restricted_qty: [],
    expdate: [],
    mfgdate: [],
    days: [],
    per: [],
    matdate: [],
  });

  const [finalData, setFinalData] = useState([]);

  const resetFilters = () => {
    setFilterList({
      totalqty: [],
      location: [],
      unrestricted_qty: [],
      qm: [],
      blocked_qty: [],
      restricted_qty: [],
      expdate: [],
      mfgdate: [],
      days: [],
      per: [],
      matdate: [],
    });
  };

  useEffect(() => {
    // below filter is not working for nested children
    // Object.keys(filterList).forEach((key) => {
    //   if (filterList[key].length > 0) {
    //     filteredData = filteredData.filter((item) =>
    //       filterList[key].includes(item[key].toString()),
    //     );
    //   }
    // });

    // filter statusData based on filterList
    // if filterList is empty, set finalData to statusData
    let filteredData = [...statusData];
    let finalFilteredData = [];
    if (Object.keys(filterList).every((key) => filterList[key].length === 0)) {
      setFinalData(statusData);
      return;
    }

    const appendChildren = (data) => {
      data.forEach((item) => {
        finalFilteredData.push(item);
        if (item.children && item.children.length > 0) {
          appendChildren(item.children);
        }
      });
    };
    appendChildren(filteredData);
    // console.log(finalFilteredData);
    Object.keys(filterList).forEach((key) => {
      if (filterList[key].length > 0) {
        finalFilteredData = finalFilteredData.filter((item) =>
          filterList[key].includes(item[key].toString()),
        );
      }
    });
    finalFilteredData = finalFilteredData.map((e) => {
      return {
        ...e,
        children: undefined,
      };
    });
    setFinalData([...finalFilteredData]);
  }, [filterList, statusData]);

  return (
    <InventoryStatusDetailContext.Provider
      value={{
        loading,
        setLoading,
        statusData,
        setStatusData,
        filterList,
        setFilterList,
        finalData,
        setFinalData,
        resetFilters,
      }}>
      <InventoryStatusDetailComponent />
    </InventoryStatusDetailContext.Provider>
  );
};

export default InventoryStatusDetail;
