import React, {useEffect, useRef, useState} from 'react';

function isBetween(a, b, c) {
  return b >= a && b <= c;
}

function isWithin(element, area) {
  if (element === undefined) {
    return false;
  }

  if (!Object.values(area).every((x) => x !== undefined)) {
    return false;
  }

  return (
    (isBetween(area.x1, element.left + window.scrollX, area.x2) ||
      isBetween(area.x1, element.right + window.scrollX, area.x2) ||
      isBetween(element.left, area.x1, element.right)) &&
    (isBetween(area.y1, element.top + window.scrollY, area.y2) ||
      isBetween(area.y1, element.bottom + window.scrollY, area.y2) ||
      isBetween(element.top, area.y1, element.bottom))
  );
}
const Cell = ({value, selectionArea, setTotalSum}) => {
  const ref = useRef(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const cellBbox = ref.current?.getBoundingClientRect();
    const isSelected = isWithin(cellBbox, selectionArea);
    setIsSelected(isSelected);

    if (isSelected) {
      setTotalSum((curr) => curr + value);
    }
  }, [selectionArea, setTotalSum, value]);

  return (
    <div
      ref={ref}
      className={`my-cell ${isSelected ? 'active-cell' : 'in-active-cell'}`}>
      {value !== undefined && <div>{value}</div>}
    </div>
  );
};
export default Cell;
