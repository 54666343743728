import {BellOutlined, CaretDownFilled, UserOutlined} from '@ant-design/icons';
import {Avatar, Badge, Button, Dropdown, Layout, Select} from 'antd';
import {AuthContext} from 'context/ContextProvider';
import HeaderDropDowns from 'pages/ServiceRiskSimulation/HeaderDropDowns';
import React from 'react';
import {matchPath, useLocation, useParams} from 'react-router-dom';
import routes from 'routes';
const Header = () => {
  const {auth, country, setCountry, countryList, setCountryList} =
  React.useContext(AuthContext);
  
  const location = useLocation();
  const id = useParams();
  const pageTitle = routes.filter((route) =>
    matchPath(route, location.pathname),
  )[0];
  const updateCountryList = React.useCallback(async () => {
    let countryDetails = [];

    auth?.location.map((value, index) => {
      if (value == 'Global') {
        countryDetails.push(
          {
            value: 'ALL',
            label: 'ALL',
          },
          {
            value: 'AU',
            label: 'AU',
          },
          {
            value: 'CN',
            label: 'CN',
          },
          {
            value: 'JP',
            label: 'JP',
          },
          {
            value: 'IN',
            label: 'IN',
          },
          {
            value: 'KR',
            label: 'KR',
          },
          {
            value: 'NZ',
            label: 'NZ',
          },
        );
      } else if (value == 'AU') {
        countryDetails.push({
          value: 'AU',
          label: 'AU',
        });
      } else if (value == 'CN') {
        countryDetails.push({
          value: 'CN',
          label: 'CN',
        });
      } else if (value == 'JP') {
        countryDetails.push({
          value: 'JP',
          label: 'JP',
        });
      } else if (value == 'IN') {
        countryDetails.push({
          value: 'IN',
          label: 'IN',
        });
      } else if (value == 'KR') {
        countryDetails.push({
          value: 'KR',
          label: 'KR',
        });
      } else if (value == 'NZ') {
        countryDetails.push({
          value: 'NZ',
          label: 'NZ',
        });
      }
    });
    if (countryDetails.length > 0) {
      setCountryList(countryDetails);
      setCountry(countryDetails[0].value);
    }
  }, [auth?.location, setCountryList, setCountry]);
  React.useEffect(() => {
    updateCountryList();
  }, [updateCountryList]);
  return (
    <Layout.Header
      style={{
        background: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 99,
        height: 45,
      }}>
      <div className='flex-row justify-between align-center'>
        <div
          className='flex-row justify-between align-center'
          style={{
            gap: 20,
          }}>
          <h4
            style={{
              fontWeight: '500',
              fontSize: 20,
              margin: 0,
              lineHeight: '28px',
            }}>
            {typeof pageTitle?.title == 'string' ||
            typeof pageTitle?.title == 'undefined' ? (
              pageTitle?.title
            ) : (
              <pageTitle.title />
            )}
          </h4>
          {pageTitle?.path === '/' ? (
            <div>
              <Select
                style={{
                  width: 80,
                  zIndex: 999,
                }}
                popupClassName='country-select-popup'
                options={countryList}
                defaultValue={['ALL']}
              />
            </div>
          ) : null}
          {pageTitle?.path === '/ServiceRiskReport' ? (
            <div>
              <Select
                value={country}
                style={{
                  width: 80,
                  zIndex: 999,
                }}
                popupClassName='country-select-popup'
                onChange={(e) => {
                  setCountry(e);
                }}
                options={countryList}
              />
            </div>
          ) : null}
          {pageTitle?.path === '/ServiceRiskReport/:simId' ? (
            <HeaderDropDowns />
          ) : null}

          {pageTitle?.path === '/Notifications' ? (
            <div>
              <Select
                value={country}
                style={{
                  width: 80,
                  zIndex: 999,
                }}
                popupClassName='country-select-popup'
                onChange={(e) => {
                  setCountry(e);
                }}
                options={countryList}
              />
            </div>
          ) : null}
           {pageTitle?.path === '/Reports' ? (
            <div>
              <Select
                value={country}
                style={{
                  width: 80,
                  zIndex: 999,
                }}
                popupClassName='country-select-popup'
                onChange={(e) => {
                  setCountry(e);
                }}
                options={countryList}
              />
            </div>
          ) : null}
        </div>

        <div
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {/* <Badge size='small' count={9}>
            <Button
              shape='circle'
              size='small'
              type='text'
              icon={<BellOutlined style={{fontSize: 22}} />}
            />
          </Badge> */}
          <div style={{margin: '0px 0px 0px 30px'}}>
            <Dropdown
              menu={{
                items: [
                  ...auth.access.map((e, i) => ({
                    key: i + '' + e,
                    label: <span className='mx-2'>{e}</span>,
                  })),
                ],
              }}>
              <div>
                <Avatar icon={<UserOutlined />} /> {/* <div> */}
                <span className='mx-2'>
                  {/* {auth?.tokenData?.givenName +
                    ' ' +
                    auth?.tokenData?.displayName.split(',')[0]} */}
                    {auth?.tokenData?.givenName?.charAt(0)  + auth?.tokenData?.displayName?.split(',')[0]?.charAt(0)}
                </span>
                {/* {
                    auth?.access.map((e,i) =>
                      <span key={i} className='mx-2'>{e}</span>
                    )
                  } */}
                {/* </div> */}
                <CaretDownFilled />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      {
        // if page is home
        //use this
        // else page is service risk
        // use this
      }
    </Layout.Header>
  );
};

export default Header;
