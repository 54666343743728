import {Button, Layout, Menu} from 'antd';
import React from 'react';
import {AiOutlineMenuFold, AiOutlineMenuUnfold} from 'react-icons/ai';
import {useLocation, useNavigate} from 'react-router-dom';
import routes from 'routes';
import Logo from '../Logo';

const Sider = () => {
  const [isCollapsed, setCollapsed] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout.Sider
      width={240}
      theme='light'
      collapsed={isCollapsed}
      style={{
        borderRight: '1px solid #f0f0f0',
        backgroundColor: '#ffffff',
      }}>
      <div
        style={{
          height: '72px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          marginRight: -10,
        }}>
        <Logo isCollapsed={isCollapsed} />
        <Button
          style={{
            background: '#FFFFFF',
            color: 'black',
            border: '1px solid #359EFF',
            flex: 'none',
            order: '2',
            flexGrow: '0',
            zIndex: 999,
            padding: '0px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
          type='primary'
          size='small'
          onClick={() => setCollapsed(!isCollapsed)}
          icon={
            isCollapsed ? (
              <AiOutlineMenuUnfold style={{width: '20px', height: '20px'}} />
            ) : (
              <AiOutlineMenuFold style={{width: '20px', height: '20px'}} />
            )
          }
        />
      </div>

      <Menu
        mode='inline'
        style={{marginTop: '18px', fontSize: 14, fontWeight: 400}}
        defaultSelectedKeys={[
          routes.filter((route) => route?.path == location.pathname)[0]?.id,
        ]}>
        {routes
          .filter((route) => !route.hidden)
          .map((route, index) => (
            <Menu.Item
              onClick={() => {
                navigate(route.path);
              }}
              icon={route.icon}
              key={route.id}>
              {route.subtitle}
            </Menu.Item>
          ))}
        {/* <Menu.Item icon={<AiOutlineHome style={{ fontSize: 18 }} />} key='1'>
          Home
        </Menu.Item>
        <Menu.Item icon={<AiOutlineAlert style={{ fontSize: 18 }} />} key='2'>
          Service Risk Report
        </Menu.Item>
        <Menu.Item
          icon={<IoMdNotificationsOutline style={{ fontSize: 21 }} />}
          key='3'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '75px',
            }}>
            <div>Notifications</div> <Badge count={99} />
          </div>
        </Menu.Item>
        <Menu.Item icon={<AiOutlineBook style={{ fontSize: 18 }} />} key='4'>
          Reports
        </Menu.Item> */}
      </Menu>
    </Layout.Sider>
  );
};

export default React.memo(Sider);
