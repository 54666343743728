import axios from 'components/services/lib/axios';
import {projectionSimulationActions} from './../reducers/projectionSimulationSlice';
import {
  GetProjectionDataURL,
  GetServiceRiskColumnsURL,
  GetSavedSimulationListURL,
} from 'components/services/lib/URLs';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';

export const useSimulationForcastData = () => {
  const dispatch = useDispatch();

  const {simId} = useParams();
  const [projId, locId] = simId.split(',');

  useEffect(() => {
    axios
      .post('/getfkdata', {
        material: projId,
        location_id: locId,
      })
      .then((resp) => {
        dispatch(
          projectionSimulationActions.updateForcastData({
            forecastData: resp.data.slice(26),
          }),
        );
        // console.log(resp.data);
        // remove 15 rows from beg resp.data and console.log
        // console.log(resp.data.slice(14));
      });
  }, [projId, locId]);
};
export const getSimulationForecastData = ({product_id, location_id}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post('/getfkdata', {
        material: product_id,
        location_id: location_id,
      })
      .then((resp) => {
        dispatch(
          projectionSimulationActions.updateForcastData({
            forecastData: resp.data.slice(26),
          }),
        );
        // console.log(resp.data);
        // remove 15 rows from beg resp.data and console.log
        // console.log(resp.data.slice(14));
      });
  }, []);
};

export const getProjectionData = ({material, location_id}) => {
  return async (dispatch) => {
    try {
      dispatch(
        projectionSimulationActions.updateProjectionDataLoading({
          loading: true,
        }),
      );
      const fetchData = async () => {
        const resData = await axios.post(GetProjectionDataURL, {
          material: material,
          location_id: location_id,
        });
        const resColumns = await axios.post(GetServiceRiskColumnsURL);
        return {data: resData?.data, columns: resColumns?.data};
      };
      const res = await fetchData();
      if (res?.data && res?.columns) {
        const children = res?.data?.filter((e) =>
          Object.keys(e).includes('Other'),
        )[0];
        let projData = res?.data
          ?.filter((e) => Object.keys(e).includes('vmi'))
          .map((e) => {
            if (e.vmi == 'Other') {
              return {
                ...e,
                children: [...children.Other],
              };
            }
            return {
              ...e,
            };
          });
        dispatch(
          projectionSimulationActions.updateProjectionData({
            data: projData,
            columns: res?.columns,
          }),
        );
      } else {
        dispatch(
          projectionSimulationActions.updateProjectionData({
            data: [],
            columns: [],
          }),
        );
      }
      dispatch(
        projectionSimulationActions.updateProjectionDataLoading({
          loading: false,
        }),
      );
    } catch (e) {
      dispatch(
        projectionSimulationActions.updateProjectionDataLoading({
          loading: false,
        }),
      );
      console.log(e);
    }
  };
};

export const getSavedSimulationList = ({material, location_id }) => {
  return async (dispatch) => {
    try {
      const fetchData = async () => {
        const resData = await axios.post(GetSavedSimulationListURL, {
         
          
          material: material,
          location_id: location_id,
        });
        return {data: resData?.data};
      };
      const res = await fetchData();
      if (res?.data) {
        dispatch(
          projectionSimulationActions.updateSavedSimulationList({
            list: res?.data,
          }),
        );
      } else {
        dispatch(
          projectionSimulationActions.updateSavedSimulationList({
            list: [],
          }),
        );
      }
    } catch (e) {
      dispatch(
        projectionSimulationActions.updateSavedSimulationList({
          list: [],
        }),
      );
      console.log(e);
    }
  };
};
