import React, {useContext} from 'react';
import {Tabs} from 'antd';
import {InventoryDeliveryDataContext} from '.';
const InventoryTabs = ({onTabSelection}) => {
  const [statusData, setStatusData, fetchDeliveryData] = useContext(
    InventoryDeliveryDataContext,
  );

  // console.log(
  //   'statusData',
  //   statusData
  //     .map((item) => parseInt(item.quantity))
  //     .reduce((a, b) => a + b, 0),
  // );

  const onTabSelectionFun = React.useCallback(
    (key) => {
      onTabSelection(key);
    },
    [onTabSelection],
  );
  return (
    <>
      <Tabs
        defaultActiveKey='2'
        tabBarStyle={{margin: 0}}
        tabBarGutter={32}
        onChange={(e) => {
          onTabSelectionFun(e);
        }}>
        <Tabs.TabPane
          tab={
            <div
              style={{
                display: 'flex',
                fontFamily: 'Verdana',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              Inventory Movement (
              {statusData
                .map((item) => parseInt(item.quantity))
                .reduce((a, b) => a + b, 0)}
              )
              <div>
                {/* value */}
                {/* &nbsp;{'('}{')'}&nbsp; */}
              </div>
            </div>
          }
          key='1'
        />
        <Tabs.TabPane
          tab={
            <div
              style={{
                display: 'flex',
                fontFamily: 'Verdana',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              Inventory Status
            </div>
          }
          key='2'
        />
      </Tabs>
    </>
  );
};

export default React.memo(InventoryTabs);
