import React from 'react';
import {ConfigProvider} from 'antd';
import AppLocale from '../../shared/localization';
import {useLocaleContext} from '../ContextProvider/LocaleContextProvider';

const ThemeProvider = (props) => {
  const {locale} = useLocaleContext();
  const {antLocale} = AppLocale[locale.locale];
  return <ConfigProvider locale={antLocale}>{props.children}</ConfigProvider>;
};

export default React.memo(ThemeProvider);
