import React from 'react';
import {IntlProvider} from 'react-intl';
import AppLocale from '../../shared/localization';
import {useLocaleContext} from '../ContextProvider/LocaleContextProvider';
import {IntlGlobalProvider} from '../../components/utility/intlGlobalProvider';
const AppLocaleProvider = (props) => {
  const {locale} = useLocaleContext();
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <IntlGlobalProvider>{props.children}</IntlGlobalProvider>
    </IntlProvider>
  );
};

export default React.memo(AppLocaleProvider);
