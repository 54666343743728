import React, {useState, useContext} from 'react';
import {Steps, DatePicker, message} from 'antd';
import {CheckCircleOutlined, SaveOutlined} from '@ant-design/icons'; // Import Ant Design icons
import {ReactComponent as BlackDot} from 'assets/icon/BlackDot.svg';
import InventoryStepTooltip from './InventoryStepTooltip';
import {Link, useParams} from 'react-router-dom';
import axios from 'components/services/lib/axios';
import {InventoryDeliveryDataContext} from '.';
import moment from 'moment';
import useProjectionData from '../Table/useProjectionData';
import Arrow from 'assets/icon/Arrow.png';
import {AuthContext} from 'context/ContextProvider';
const {Step} = Steps;

const renderIcon = (iconName, gigap_cc, landinggap_cc, grgap_cc, item) => {
  // Initialize a default color
  let color = '';

  if (iconName === 'CheckCircleOutlined') {
    if (gigap_cc !== undefined) {
      if (gigap_cc === 1) {
        color = '#FA4545'; // yellow
      } else if (gigap_cc === 2) {
        color = '#f5ac25'; // red
      } else if (gigap_cc === 4) {
        color = '#3a8d00'; // green
      }
    }
    if (landinggap_cc !== undefined) {
      if (landinggap_cc === 1) {
        color = '#FA4545'; // yellow
      } else if (landinggap_cc === 2) {
        color = '#f5ac25'; // red
      } else if (landinggap_cc === 4) {
        color = '#3a8d00'; // green
      }
    }
    if (grgap_cc !== undefined) {
      if (grgap_cc === 1) {
        color = '#FA4545'; // yellow
      } else if (grgap_cc === 2) {
        color = '#f5ac25'; // red
      } else if (grgap_cc === 4) {
        color = '#3a8d00'; // green
      }
    }
  }

  return iconName === 'CheckCircleOutlined' ? (
    <CheckCircleOutlined style={{color, fontSize: '32px'}} />
  ) : iconName === 'BlackDot' ? (
    <BlackDot style={{fontSize: '32px'}} />
  ) : null;
};

const InventorySteps = ({record}) => {
  const demo = 'Landing' || 'Pick Up';
  const [selectedDate, setSelectedDate] = useState();
  const dateFormatList = ['MM/DD/YYYY'];
  const {auth} = React.useContext(AuthContext);

  const user = auth?.tokenData?.name;

  const {simId} = useParams();
  const [projId, locId] = simId.split(',');

  const containerRef = React.useRef(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(null);
  const [scrollLeft, setScrollLeft] = React.useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };
  const [_, __, fetchDeliveryData] = useContext(InventoryDeliveryDataContext);
  // console.log();
  let gr = _.filter((e) => e.key == record.key)[0].items.filter(
    (e) => e.title == 'GR',
  )[0];
  let landing = _.filter((e) => e.key == record.key)[0].items.filter(
    (e) => e.title == 'Landing',
  )[0];
  let goodIssue = _.filter((e) => e.key == record.key)[0].items.filter(
    (e) => e.title == 'Good Issue',
  )[0];

  // console.log('good issue', goodIssue);
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the scroll speed here if needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const [___, fetchProjData] = useProjectionData(false, () => {});
  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const orderId = record.orderId;
  const deliveryItem = record.delitem;

  const fetchInvMovData = (item) => {
    const estDateToPass = selectedDate ? selectedDate : '';
    // if (!selectedDate || !item.title) {
    //   message.success('Select date');
    //   return;
    // }
    axios
      .post('/updateinvdetails', {
        material: projId,
        location_id: locId,
        type: item.title,
        est_date: estDateToPass,
        orderId: orderId,
        delitem: deliveryItem,
        user: user,
      })
      .then(async (res) => {
        // console.log(fetchDeliveryData);

        if (res?.data?.length > 0) {
          // setSelectedDate(res.data.date);
        } else {
          setSelectedDate([]);
        }
        await fetchDeliveryData();
        fetchProjData();
      })
      .catch((e) => {});
  };

  const handleDateChange = (data) => {
    setSelectedDate(data?.toDate());
  };
  // console.log(gr?.est_date)
  return (
    <div
      className='scroll-container'
      ref={containerRef}
      onMouseDown={handleMouseDown}>
      <Steps current={-1} responsive={true} style={{width: '100%'}}>
        {record.items.map((item, itemIndex) => {
          if (item.description === 'No Data') {
            return null; // Skip rendering this step
          }
          return (
            <Step
              key={itemIndex}
              title={
                <InventoryStepTooltip description={item || {}}>
                  {item.title}
                </InventoryStepTooltip>
              }
              description={
                <div style={{fontWeight: 400, width: '200px'}}>
                  {item.description}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '150px',
                    }}>
                    {(item.giestflag === 0 ||
                      item.landingestflag === 1 ||
                      item.grestflag === 1) && (
                      <>
                        <DatePicker
                          style={{paddingLeft: '0px'}}
                          onChange={handleDateChange}
                          bordered={false}
                          defaultValue={
                            item.title == 'GR'
                              ? gr?.est_date != '' &&
                                gr?.est_date != 'NoData' &&
                                gr?.est_date != 'NA'
                                ? moment(gr?.est_date)
                                : null
                              : item.title == 'Landing'
                              ? landing?.landingestdate != '' &&
                                landing?.landingestdate != 'NoData' &&
                                landing?.landingestdate != 'NA'
                                ? moment(landing?.landingestdate)
                                : null
                              : item.title == 'Good Issue'
                              ? goodIssue?.giestdate != '' &&
                                goodIssue?.giestdate != 'NoData' &&
                                goodIssue?.giestdate != 'NA'
                                ? moment(goodIssue?.giestdate)
                                : null
                              : null
                          }
                          format={dateFormatList}
                        />
                        <button
                          style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => fetchInvMovData(item)}>
                          <a>
                            <SaveOutlined />
                          </a>
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    {['Landing', 'Pick Up'].includes(item.title) && (
                      <Link
                        to='https://sso.kuehne-nagel.com/authorization/login'
                        target='_blank'>
                        <div style={{fontSize: '12px'}}>
                          Check In Kuehne+Nagel
                          <img src={Arrow} alt='Arrow' />
                        </div>
                      </Link>
                    )}
                    {['Document Filling', 'Sample Recieved'].includes(
                      item.title,
                    ) && (
                      <Link
                        to='https://idiscover.jnj.com/#/site/cn/views/IPRO/ReleaseAutoMonitoring?:iid=1'
                        target='_blank'>
                        <div style={{fontSize: '12px'}}>
                          Check In IPRO
                          <img
                            src={Arrow}
                            alt='Arrow'
                            style={{fontSize: '12px'}}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              }
              icon={renderIcon(
                item.iconName,
                item.gigap_cc,
                item.grgap_cc,
                item.landinggap_cc,
              )}
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default React.memo(InventorySteps);
