import {Breadcrumb, Skeleton, message} from 'antd';
import axios from 'components/services/lib/axios';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const ServiceRiskSimulationHeader = () => {
  const {simId} = useParams();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [desc, setDesc] = React.useState('');
  const navigate = useNavigate();
  const [projId, locId] = simId.split(',');
  const {data} = useSelector((state) => state.srCols);
  const [currentSimulation, setCurrentSimulation] = useState({
    panda_code: '',
    gmc: '',
  });
  useEffect(() => {
    setCurrentSimulation(
      data?.find((obj) => obj.product_id == projId && obj.location_id == locId),
    );
  }, [projId, locId, data?.length]);
  useEffect(() => {
    axios
      .post('/getmatdesc', {
        material: projId,
      })
      .then((res) => {
        //setDesc(res.data[0].matl_shrt_desc);
        setDesc(res.data[0].productid_desc);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error('Error while fetching material description');
        navigate('/ServiceRiskReport');
      });
  }, [simId]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            navigate('/ServiceRiskReport');
          }}>
          Service Risk Report
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {loading ? (
            <Skeleton.Input
              style={{width: 100, height: 20, borderRadius: 20}}
              size='large'
              active={true}
            />
          ) : (
            // projId + '-' + desc + ' ' + panda_code
            <>
              <span>
                {projId}-{desc}{' '}
              </span>
              <br />
              <span>
                PandaA Code-{currentSimulation?.panda_code}, GMC-
                {currentSimulation?.gmc}
              </span>
            </>
          )}
          {/* <Skeleton.Input style={{ width: 100, height: 20, borderRadius: 20 }} size="large" active={true} /> */}
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default ServiceRiskSimulationHeader;
