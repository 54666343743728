import React from 'react';
import logo from '../../../assets/images/logo-new-big.png';
import logoIcon from '../../../assets/images/logo-new-small.png';

const Logo = ({isCollapsed}) => {
  return isCollapsed === false ? (
    <div style={{padding: '1px 10px 0px 0px', marginTop: '14px'}}>
      <span></span>
      <div style={{width:120,height:30}}></div>
      {/* <img src={logo} width={120} alt='logo' /> */}
    </div>
  ) : (
    <div
      style={{
        marginLeft: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {/* <img src={logoIcon} width={46} height={24} alt='logo' /> */}
      <div style={{width:46,height:24}}></div>
    </div>
  );
};

export default React.memo(Logo);
