import {Popover, Tooltip, message} from 'antd';
import {GetServiceRiskCustomizeDisplayURL} from 'components/services/lib/URLs';
import axios from 'components/services/lib/axios';
import {AuthContext} from 'context/ContextProvider';
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import QuestionCircle from '../../../../assets/icon/questionCircle.png';
import ServiceRiskMarketFilter from './ServiceRiskMarketFilter';
import ServiceRiskRangeFilter from './ServiceRiskRangeFilter';
import ServiceRiskMtdSalesFilter from './ServiceRiskMtdSalesFilter';
import ServiceRiskSearchCustomFilter from './ServiceRiskSearchCustomFilter';
import ServiceRiskSearchFilter from './ServiceRiskSearchFilter';
import ServiceRiskStockInHandFilter from './ServiceRiskStockInHandFilter';
import ServiceRiskWorstCaseFilter from './ServiceRiskWorstCaseFilter';
import ServiceRiskInvMovementDelayFilter from './ServiceRiskInvMovementDelayFilter';
import DaysSupplyColumn from './operations/columns/DaysSupplyColumn';
import ActionRender from './operations/render/ActionRender';
import CurrentStockRender from './operations/render/CurrentStockRender';
import MTDsalesRender from './operations/render/MTDsalesRender';
import WorstCaseRender from './operations/render/WorstCaseRender';
import useFilters from './useFilters';
import useOptions from './useOptions';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import {FilterFilled} from '@ant-design/icons';

const useColumns = (columnData, data) => {
  const {filterList, saveGlobalFilterInput} = useSelector(
    (state) => state.srCols,
  );
  const [customizeCols, setCustomizeCols] = useState({
    prod_desc: {
      show: true,
      title: 'Description',
      disabled: true,
      position: 'left',
    },
    product_id: {
      show: true,
      title: 'Product Id',
      position: false,
    },
    ctry: {
      show: true,
      title: 'Market',
      position: false,
    },
    panda_code: {
      show: true,
      title: 'PandaA Code',
      position: false,
    },
    gmc: {
      show: true,
      title: 'GMC',
      position: false,
    },
    brand: {
      show: true,
      title: 'Brand',
      position: false,
    },
    plant: {
      show: true,
      title: 'MFG Plant',
      position: false,
    },
    mmtd_sales: {
      show: true,
      title: 'MTD Sales',
      position: false,
    },
    mrp_controller: {
      show: true,
      title: 'MRP controller',
      position: false,
    },
    Shipper_Qty: {
      show: true,
      title: 'Shipper Qty',
      position: false,
    },
    Pallet_Qty: {
      show: true,
      title: 'Pallet Qty',
      position: false,
    },
    fromlocation: {
      show: true,
      title: 'From Location',
      position: false,
    },

    target_inv: {
      show: true,
      title: 'Target Inv. (d)',
      position: false,
    },
    min_inv: {
      show: true,
      title: 'Min Inv. (d)',
      position: false,
    },
    max_inv: {
      show: true,
      title: 'Max Inv. (d)',
      position: false,
    },
    inv_movement_qty: {
      show: true,
      title: 'Total Inventory Movement',
      position: false,
    },
    curr_stock: {
      show: true,
      title: 'Current Stock on Hand',
      position: false,
    },
    worst_case: {
      show: true,
      title: 'Worst Case',
      position: 'right',
    },
    dc_risk: {
      show: true,
      title: 'DC risk',
      position: 'right',
    },
    inv_movement_delay: {
      show: true,
      title: 'Inventory Movement Delay',
      position: 'right',
    },
    action: {
      show: true,
      title: 'Action',
      disabled: true,
      position: 'right',
    },
  });

  const {country, auth} = React.useContext(AuthContext);
  // const [finalData, setFinalData] = useState(data);
  const [keysFilter, setKeysFilter] = useState({
    bel: false,
    min: false,
    tar: false,
    max: false,
    abv: false,
  });
  const [globalFilterList, setGlobalFilterList] = useState([]);
  // const [saveglobalFilterInput, setSaveGlobalFilterInput] = useState('');

  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [columns, scrollWidth] = useMemo(() => {
    let scrollWidth = 0;
    let columns = [];
    var addColumn = ({
      title,
      dataIndex,
      width,
      fixed,
      render,
      className,
      children,
      ellipsis,
      onFilter,
      filterSearch,
      filters,
      filteredValue,
      filterIcon,
      filterDropdown,
      sorter,
      key,
      shouldCellUpdate,
    }) => {
      columns.push({
        className,
        title: title,
        dataIndex: dataIndex,
        width: width,
        fixed: fixed,
        render: render,
        children: children,
        ellipsis: ellipsis,
        onFilter: onFilter,
        filterSearch: filterSearch,
        filters: filters,
        filteredValue: filteredValue,
        filterDropdown: filterDropdown,
        filterIcon: filterIcon,
        sorter: sorter,
        key: key,
        shouldCellUpdate: shouldCellUpdate,
      });
      scrollWidth += width;
    };
    addColumn({
      title: 'Description',
      dataIndex: 'prod_desc',

      // filter with search box
      render: (text, record) => {
        return {
          props: {
            style: {
              textAlign: 'left',
            },
          },
          children: (
            <div
              onClick={() => {
                navigate(
                  `/ServiceRiskReport/${record.product_id},${record.location_id}`,
                  {state: {record: record, action: false}},
                );
              }}
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
                cursor: 'pointer',
              }}>
              {text}
            </div>
          ),
        };
      },
      ...ServiceRiskSearchCustomFilter('Description', 'prod_desc'),
      // ...ServiceRiskSearchFilter('Description', 'prod_desc'),
      // filtered: filterList.prod_desc.length > 0,
      width: 200,

      fixed: 'left',
      key: 'description',
      // filterIcon: (filtered) => (
      //   <SearchOutlined
      //     style={{
      //       color: filtered ? '#1890ff' : undefined,
      //     }}
      //   />
      // ),
    });
    addColumn({
      title: 'Product ID',
      dataIndex: 'product_id',
      width: 132,
      key: 'material',
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      ...ServiceRiskSearchCustomFilter('Material', 'product_id'),
      //...ServiceRiskSearchFilter('Material', 'product_id'),
      /* filters: Array.from(
        new Set(data.map((item) => item.product_id + ' ' + item.prod_desc)),
      ).map((obj) => {
        return {
          text: obj,
          value: obj.split(' ')[0],
        };
      }),
      filterSearch: true,
      onFilter: (value, record) =>
        value !== null
          ? record.product_id?.indexOf(value) === 0
          : record.product_id === null, */
    });
    DaysSupplyColumn({data: columnData, addColumn});
    addColumn({
      title: 'Market',
      dataIndex: 'ctry',
      width: 80,
      key: 'city',
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      ...ServiceRiskMarketFilter(data, 'ctry'),
    });
    addColumn({
      title: 'PandaA Code',
      dataIndex: 'panda_code',
      width: 115,
      render: (text, record) => {
        return {
          props: {
            style: {
              color: '#1890FF',
              textAlign: 'left',
            },
          },
          children: (
            <div
              style={{
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      key: 'panda',
      //...ServiceRiskSearchFilter('PandaA Code', 'panda_code'),
      ...ServiceRiskSearchCustomFilter('PandaA Code', 'panda_code'),
      /* filters: Array.from(new Set(data.map((item) => item.panda_code))).map(
        (obj) => {
          return {
            text: obj,
            value: obj,
          };
        },
      ),
      filterSearch: true,
      onFilter: (value, record) =>
        value !== null
          ? record.panda_code?.indexOf(value) === 0
          : record.panda_code === null, */
    });
    addColumn({
      title: 'GMC',
      dataIndex: 'gmc',
      width: 80,
      key: 'gmc',
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      //...ServiceRiskSearchFilter('GMC', 'gmc'),
      ...ServiceRiskSearchCustomFilter('GMC', 'gmc'),
      /* filters: Array.from(new Set(data.map((item) => item.gmc))).map((obj) => {
        return {
          text: obj,
          value: obj,
        };
      }),
      filterSearch: true,
      onFilter: (value, record) =>
        value.length !== 0
          ? record.gmc?.indexOf(value) === 0
          : record?.gmc?.length === 0, */
    });
    addColumn({
      title: 'Brand',
      dataIndex: 'brand',
      width: 150,
      render: (text, record) => {
        return {
          props: {
            style: {
              color: '#1890FF',
              textAlign: 'left',
            },
          },
          children: (
            <div
              style={{
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      key: 'brand',
      //...ServiceRiskSearchFilter('Brand', 'brand'),
      ...ServiceRiskSearchCustomFilter('Brand', 'brand'),
      /*  filters: Array.from(new Set(data.map((item) => item.brand))).map(
        (obj) => {
          return {
            text: obj,
            value: obj,
          };
        },
      ),
      filterSearch: true,
      onFilter: (value, record) =>
        value !== null
          ? record.brand?.indexOf(value) === 0
          : record.brand === null, */
    });
    addColumn({
      title: 'MFG Plant',
      dataIndex: 'plant',
      width: 100,
      key: 'mfgPlant',
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
      //...ServiceRiskSearchFilter('MFG Plant', 'plant'),
      ...ServiceRiskSearchCustomFilter('MFG Plant', 'plant'),

      /*  filters: Array.from(new Set(data.map((item) => item.plant))).map(
        (obj) => {
          return {
            text: String(obj),
            value: String(obj),
          };
        },
      ),
      filterSearch: true,
      onFilter: (value, record) => {
        return value.length !== 0
          ? record.plant?.indexOf(value) === 0
          : record?.plant?.length === 0;
      }, */
    });
    addColumn({
      title: 'MTD Sales',
      dataIndex: 'mmtd_sales',
      width: 164,
      key: 'mtdSales',
      ...ServiceRiskMtdSalesFilter(data, 'mmtd_sales'),
      render: (text, record) => {
        return {
          props: {
            style: {},
          },
          children: (
            // <div className='vertical-line-wrapper'>
            //   <div
            //     className='vertical-line'
            //     onMouseDown={(e) => e.stopPropagation()}
            //     style={{left: `${String(record['mmtd_day'])}` + `%`}}
            //   />
            // <span className='vertical-line-content'>
            <MTDsalesRender record={record} />
            // </span>
            // </div>
          ),
        };
      },
    });
    // new column
    addColumn({
      title: 'MRP controller',
      dataIndex: 'mrp_controller',
      width: 100,
      key: 'mrp_controller',
      ...ServiceRiskSearchCustomFilter('MRP controller', 'mrp_controller'),

      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });
    addColumn({
      title: 'Shipper Qty',
      dataIndex: 'Shipper_Qty',
      width: 100,
      key: 'Shipper_Qty',
      ...ServiceRiskSearchCustomFilter('Shipper Qty', 'Shipper_Qty'),

      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });
    addColumn({
      title: 'Pallet Qty',
      dataIndex: 'Pallet_Qty',
      width: 100,
      key: 'Pallet_Qty',
      ...ServiceRiskSearchCustomFilter('Pallet Qty', 'Pallet_Qty'),

      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });
    addColumn({
      title: 'From Location',
      dataIndex: 'fromlocation',
      width: 100,
      key: 'fromlocation',
      ...ServiceRiskSearchCustomFilter('From Location', 'fromlocation'),

      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });

    addColumn({
      title: 'Target Inv. (d)',
      dataIndex: 'target_inv',
      width: 100,
      key: 'targetInv',
      ...ServiceRiskRangeFilter(data, 'target_inv'),
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });

    addColumn({
      title: 'Min Inv. (d)',
      dataIndex: 'min_inv',
      width: 100,
      key: 'minInv',
      ...ServiceRiskRangeFilter(data, 'min_inv'),
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });
    addColumn({
      title: 'Max Inv. (d)',
      dataIndex: 'max_inv',
      width: 100,
      key: 'maxInv',
      ...ServiceRiskRangeFilter(data, 'max_inv'),
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },
    });
    addColumn({
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
            flexGrow: '1',
          }}>
          Tot. Inv. Movement
          <Tooltip title='Total Inventory Movement across 6 month'>
            <span>
              <img src={QuestionCircle} />{' '}
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'inv_movement_qty',
      width: 115,
      key: 'totInvMov',
      render: (text, record) => {
        return {
          props: {
            style: {textAlign: 'left'},
          },
          children: (
            <div
              style={{
                color: '#141414',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '400',
              }}>
              {text}
            </div>
          ),
        };
      },

      // ...ServiceRiskSearchFilter(
      //   'Total Inventory Movement',
      //   'inv_movement_qty',
      // ),
      ...ServiceRiskSearchCustomFilter(
        'Total Inventory Movement',
        'inv_movement_qty',
      ),

      /* filters: Array.from(
        new Set(data.map((item) => item.inv_movement_qty)),
      ).map((obj) => {
        return {
          text: obj,
          value: obj,
        };
      }),
      filterSearch: true,
      onFilter: (value, record) =>
        value !== null
          ? record.inv_movement_qty?.indexOf(value) === 0
          : record.inv_movement_qty === null, */
    });
    addColumn({
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
            flexGrow: '1',
          }}>
          Current Week DOS
          <Tooltip title='Current Week Stock on hands and coverage time'>
            <span>
              <img src={QuestionCircle} />{' '}
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'curr_stock',
      width: 120,
      key: 'currStock',
      render: (text, record) => {
        return (
          <CurrentStockRender
            backColor={record['w2_cc']} // ...{color: record[item.Week + '_cc'] == 4 ? '#000' : '#fff'},
            stockDays={record['w2']}
            stock={record.inventory_qty}
          />
        );
      },
      ...ServiceRiskStockInHandFilter('curr_stock_in_hand'),
    });

    addColumn({
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
            flexGrow: '1',
          }}>
          Worst Case
          <Tooltip title='This column shows the worst Situation across the all period in R10'>
            <span>
              <img src={QuestionCircle} />{' '}
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: 'worst_case',
      width: 110,
      key: 'worstCase',
      fixed: 'right',
      render: (text, record) => {
        return (
          <WorstCaseRender
            backColor={record['wrostcase_c']}
            worstDays={record['wrostcase_d']}
            worstValue={record['wrostcase_v']}
          />
        );
      },
      ...ServiceRiskWorstCaseFilter('worst_case'),
    });
    addColumn({
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
            flexGrow: '1',
          }}>
          DC risk
        </div>
      ),
      dataIndex: 'dc_risk',
      width: 110,
      key: 'dcrisk',
      fixed: 'right',
      render: (text, record) => {},
      // ...ServiceRiskWorstCaseFilter('dc_risk'),
    });

    addColumn({
      title: 'Inv. Movement Delay',
      dataIndex: 'inv_movement_delay',
      width: 130,
      render: (text, record) => {
        return {
          props: {
            style: {
              color: '#141414',
              fontSize: '12px',
              lineHeight: '20px',
              fontWeight: '400',
            },
          },
          children:
            record['Inv_delay_count'] > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 10,
                }}>
                <div
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: 15,
                    backgroundColor: getDaySupplyBGColor(
                      record['Inv_delay_cc'],
                    ),
                  }}
                />
                <span style={{fontSize: 12}}>{record['Inv_delay']} days</span>
                {record['Inv_delay_count'] > 0 ? (
                  <Tooltip
                    placement='top'
                    title={(props) => {
                      const [data, setData] = useState([]);
                      useEffect(() => {
                        axios
                          .post('/delv_gettooltip', {
                            material: record.product_id,
                            location_id: record.location_id,
                          })
                          .then((resp) => {
                            setData(resp.data);
                            console.log(resp.data);
                          })
                          .catch((err) => {
                            console.log('err', err);
                          });
                      }, [props]);
                      return (
                        <>
                          <div style={{fontSize: 12}}>
                            {data.map((sku, i) => {
                              return (
                                <div key={i}>
                                  <div>{i + 1} DN:</div>
                                  <div
                                    style={{
                                      marginLeft: 30,
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}>
                                    {Object.keys(sku).map((key, j) => (
                                      <span key={j}>{sku[key]}</span>
                                    ))}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    }}>
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        border: '1px solid #D6D6D6',
                        textAlign: 'center',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <span>+ {record['Inv_delay_count']}</span>
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    style={{
                      height: 32,
                      width: 32,
                      border: '1px solid #D6D6D6',
                      textAlign: 'center',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <span>+ {record['Inv_delay_count']}</span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            ),
        };
      },
      key: 'worstCase',
      fixed: 'right',
      ...ServiceRiskInvMovementDelayFilter('inv_movement_delay'),
    });
    addColumn({
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      key: 'worstCase',
      fixed: 'right',
      render: (text, record) => {
        return <ActionRender record={record} />;
      },
    });

    columns = columns.filter((item) => {
      if (customizeCols[item.dataIndex] === undefined) {
        return true;
      }
      return customizeCols[item.dataIndex]?.show;
    });

    columns = columns.map((item) => {
      return {
        ...item,
        fixed: customizeCols[item.dataIndex]?.position,
      };
    });

    // sort columns item.fixed = 'left' to the front in the order of Object.keys(customizeCols).filter((key) => customizeCols[key]?.position === 'left')
    const leftFixedColumns = Object.keys(customizeCols)
      .filter((key) => customizeCols[key]?.position === 'left')
      .map((key) => {
        return columns.find((item) => item.dataIndex === key);
      })
      .reduce((acc, curr) => {
        if (curr) {
          acc.push(curr);
        }
        return acc;
      }, []);

    const rightFixedColumns = Object.keys(customizeCols)
      .filter((key) => customizeCols[key]?.position === 'right')
      .map((key) => {
        return columns.find((item) => item.dataIndex === key);
      })
      .reduce((acc, curr) => {
        if (curr) {
          acc.push(curr);
        }
        return acc;
      }, []);
    const normalColumns = columns
      .filter(
        (item) =>
          !Object.keys(customizeCols).includes(item.dataIndex) ||
          customizeCols[item.dataIndex]?.position === false,
      )
      .reduce((acc, curr) => {
        if (curr) {
          acc.push(curr);
        }
        return acc;
      }, []);
    columns = [...leftFixedColumns, ...normalColumns, ...rightFixedColumns];
    return [columns, scrollWidth];
  }, [columnData, customizeCols]);
  const finalData = useFilters(data, searchString, country, keysFilter);

  const [downloadData, shareLink] = useOptions(finalData, data, columnData);
  const getGlobalFilterList = () => {
    return new Promise((res, rej) => {
      axios
        .post('/getglobalfilterlist', {user: auth.tokenData.name})
        .then((resp) => {
          res(resp.data);
          // console.log('resp', resp);
          // setGlobalFilterList(resp.data);
        })
        .catch((err) => {
          rej(err);
          // console.log('err', err);
        });
    });
  };
  useEffect(() => {
    axios
      .post(GetServiceRiskCustomizeDisplayURL, {
        user: auth.tokenData.name,
      })
      .then((resp) => {
        if (resp?.data?.[0].savecolumn) {
          if (Object.keys(resp.data[0].savecolumn).length != 0) {
            setCustomizeCols(resp.data[0].savecolumn);
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
    getGlobalFilterList().then((data) => {
      setGlobalFilterList(data);
    });
  }, []);

  const saveCustomizeDisplay = () => {
    // localStorage.setItem('customizeCols', JSON.stringify(customizeCols));
    // TODO: save to backend
    axios
      .post('/saveserviceriskcust', {
        data: customizeCols,
        user: auth.tokenData.name,
      })
      .then((resp) => {
        message.success('Save Successfully');
      })
      .catch((err) => {
        message.error('Save Failed');
      });
  };

  const saveGlobalFilter = () => {
    return new Promise((res, rej) => {
      axios
        .post('/saveglobalfilter', {
          data: filterList,
          user: auth.tokenData.name,
          filtername: saveGlobalFilterInput.trim(),
        })
        .then((resp) => {
          message.success('Filter Saved Successfully');
          getGlobalFilterList().then((data) => {
            setGlobalFilterList(data);
            res(true);
          });
        })
        .catch((err) => {
          message.error('Save Failed');
          rej(err);
        });
    });
  };
  const deleteGlobalFilter = ({id}) => {
    return new Promise((res, rej) => {
      res(true);
      axios
        .post('/deleteglobalfilter', {
          id: id,
        })
        .then((resp) => {
          message.success('Filter Deleted Successfully');
          getGlobalFilterList().then((data) => {
            setGlobalFilterList(data);
            res(true);
          });
        })
        .catch((err) => {
          message.error('Delete Failed');
          rej(err);
        });
    });
  };
  return [
    columns,
    scrollWidth,
    searchString,
    setSearchString,
    finalData,
    // getFinalData,
    downloadData,
    shareLink,
    customizeCols,
    setCustomizeCols,
    saveCustomizeDisplay,
    keysFilter,
    setKeysFilter,
    saveGlobalFilter,
    globalFilterList,
    deleteGlobalFilter,
  ];
};

export default useColumns;
