import {Button, Card, Input, Segmented, Row, Col} from 'antd';
import axios from 'components/services/lib/axios';
import React, {useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';

import {AiOutlineSetting} from 'react-icons/ai';
import SimulationModal from './SimulationHeatMap/SimulationModal';
import ProjectionTable from './Table';
import RecentComments from './RecentComments';
import GrossSalesYearToGoGraph from './GrossSalesYearToGoGraph';
import {useSimulationForcastData} from 'redux/actions/projectionSimulationActions';
import {useSelector} from 'react-redux';
import InventoryMovement from './InventoryMovement';
import KpiStatus from './KpiStatus';
import {AuthContext} from 'context/ContextProvider';
const {Search} = Input;
const ServiceRiskReport = () => {
  const [savedSimulationData, setSavedSimulationData] = React.useState([]);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const {simId} = useParams();
  const [projId, locId] = simId.split(',');
  const location = useLocation();
  const {data} = useSelector((state) => state.srCols);
  // const {countryData} = useSelector((state) =>state.prsim.selectedCountry);
  // console.log('countryData: ', countryData);

  const record =
  location?.state && location?.state?.record ? location?.state?.record : null;
  const country =record.ctry;
  const fetchSavedSimulation = React.useCallback(() => {
    axios
      .post('/getsimdata', {
        country: country,
        material: projId,
        location_id: locId,
      })
      .then((res) => {
        if (res?.data?.length > 0) {
          setSavedSimulationData(res.data);
        } else {
          setSavedSimulationData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [locId, projId]);

  useSimulationForcastData();
  useEffect(() => {
    fetchSavedSimulation();
  }, [fetchSavedSimulation]);
  React.useEffect(() => {
    if (record == null && projId != null && locId != null && data?.length > 0) {
      setSelectedRecord(
        data.find(
          (obj) => obj.product_id == projId && obj.location_id == locId,
        ),
      );
    }
  }, [record, projId, locId, data?.length]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'sticky',
          top: '0',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          background: '#FFFFFF',
          height: '20px',
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '24px',
            paddingRight: '24px',
            alignSelf: 'stretch',
            width: '100%',
          }}>
          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: '500',
              fontSize: '14px',
              color: '#595959',
            }}>
            <div
              style={{
                marginLeft: '12px',
                marginRight: '12px',
                visibility: 'hidden',
              }}>
              <Search
                placeholder='Search'
                style={{
                  width: 376,
                }}
              />
            </div>
          </div> */}
          {/* <div
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              color: '#595959',
            }}>
            {'1 USD = 7 RMB'}
          </div> */}
        </div>
      </div>

      <div
        style={{
          height: '100%',
          padding: '10px',
          background: '#fafafa',
          overflow: 'auto',
        }}>
        <KpiStatus
          record={
            record != null && record != undefined ? record : selectedRecord
          }
        />
        <>
          <Card className=''>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#FFFFFF',
                borderRadius: '4px',
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px',
                    width: '100%',
                    height: '35px',
                  }}>
                  <span className='service-risk-card-header'>Projection</span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '24px',
                    }}>
                    {/* <Segmented
                      options={['Week', 'Month']}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '2px',
                        background: 'rgba(0, 0, 0, 0.06)',
                        borderRadius: '2px',
                        fontWeight: '400',
                        fontSize: '14px',
                      }}
                    /> */}
                    <SimulationModal fetchSavedData={fetchSavedSimulation} />
                    {/* <Button
                      type='default'
                      
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                      }}>
                      <AiOutlineSetting />
                      Run Demand simulation
                    </Button> */}
                  </div>
                </div>
                <div
                  className='service-risk-table-container'
                  style={{marginTop: '42px', width: '100%'}}>
                  {/* <ServiceRiskTable /> */}
                  <ProjectionTable
                    savedSimulation={savedSimulationData}
                    fetchSavedData={fetchSavedSimulation}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card style={{marginTop: 20}} bodyStyle={{padding: '0px'}}>
            <InventoryMovement />
          </Card>
          <Row style={{marginTop: '20px'}} gutter={16}>
            <Col span={8}>
              <Card bodyStyle={{padding: '0px', maxHeight: '579px'}}>
                <RecentComments
                  projId={projId}
                  locId={locId}
                  record={record != null ? record : selectedRecord}
                />
              </Card>
            </Col>
            <Col span={16}>{/* <GrossSalesYearToGoGraph /> */}</Col>
          </Row>
        </>
      </div>
    </div>
  );
};

export default ServiceRiskReport;
