import React, {useEffect, useState} from 'react';
import {Tooltip} from 'antd';
import axios from 'components/services/lib/axios';

import {useParams} from 'react-router-dom';
const InventoryTooltip = (props) => {
  const [toolTipData, setToolTipData] = useState(null);

  const {simId} = useParams();
  const [projId, locId] = simId.split(',');

  const fetchData = async () => {
    try {
      console.log(projId, locId);
      const response = await axios.post('/proj_inv_tooltip', {
        material: projId,
        location_id: locId,
      });
      setToolTipData(response.data);
    } catch (error) {
      console.log('Error fetching tooltip data', error);
    }
  };
  const handleMouseEnter = () => {
    fetchData();
  };

  return (
    <Tooltip
      overlayInnerStyle={{padding: '0px', background: '#333333'}}
      title={
        <div
          style={{
            display: 'flex',
            padding: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            borderRadius: '2px',
            fontFamily: 'Verdana',
            fontSize: '12px',
            fontStyle: 'normal',
          }}>
          <div>
            <div>
              <span style={{color: '#888B8D'}}>Unrestricted: </span>
              <span style={{color: '#FFF'}}>
                {toolTipData?.Unrestricted !== undefined
                  ? toolTipData.Unrestricted
                  : 0}
              </span>
            </div>
            <div>
              <span style={{color: '#888B8D'}}>Restricted: </span>
              <span style={{color: '#FFF'}}>
                {toolTipData?.Restricted !== undefined
                  ? toolTipData.Restricted
                  : 0}
                {/* {toolTipData.Rsestricted} */}
              </span>
            </div>
            <div>
              <span style={{color: '#888B8D'}}>QM: </span>
              <span style={{color: '#FFF'}}>
                {toolTipData?.OM !== undefined ? toolTipData.OM : 0}
              </span>
            </div>
            <div>
              <span style={{color: '#888B8D'}}>Blocked: </span>
              <span style={{color: '#FFF'}}>
                {toolTipData?.Blocked !== undefined ? toolTipData.Blocked : 0}
              </span>
            </div>
          </div>
        </div>
      }
      placement='top'
      onMouseEnter={handleMouseEnter}>
      {props.children}
    </Tooltip>
  );
};

export default React.memo(InventoryTooltip);
