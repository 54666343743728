import React from 'react';
import {getDaySupplyBGColor} from '../../../../../../components/utility/getDaySupplyBGColor';
const CurrentStockRender = ({backColor, stockDays, stock}) => {
  // console.log('stock', backColor, stockDays, stock);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 12,
      }}>
      <div
        className='rounded-sm shadow-1'
        style={{
          width: '68px',
          textAlign: 'center',
          color: '#fff',
          fontWeight: '400',
          fontSize: '12px',
          borderRadius: '2px',
          height: '20px',
          background: getDaySupplyBGColor(backColor),
        }}>
        {' '}
        <div
          style={{
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '20px',
            color: backColor === '4' ? '#000' : '#fff',
          }}>
          {stockDays} Days
        </div>
      </div>
      {
        <div
          style={{
            paddingBottom: '0px',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#000000',
          }}>
          {stock}
        </div>
      }
    </div>
  );
};

export default React.memo(CurrentStockRender);
