export const getDaySupplyBGColor = (code) => {
  if (code === '1' || code === 1) return '#C51E38'; //Red
  if (code === '2' || code === 2) return '#F37820'; //Orange
  if (code === '3' || code === 3) return '#F5AC25'; //yellow
  if (code === '4' || code === 4) return '#A0EFBA'; //green
  if (code === '5' || code === 5) return '#1C75BC'; //blue
  if (code === '6' || code === 6) return '#888B8D'; //blue
  return '#1C75BC';
};

/* export const getDaySupplyBGColor = (code) => {
  if (code === '1' || code === 1) return '#CF2500';
  if (code === '2' || code === 2) return '#FAAD14';
  if (code === '3' || code === 3) return '#FFEC3D';
  if (code === '4' || code === 4) return '#189542';
  if (code === '5' || code === 5) return '#408CFF';
  return '#408CFF';
}; */
//1  red
//2. orange
//3. yellow
//4. green
//5. blue
