import React from 'react';
import InfoCircle from 'assets/icon/InfoCircle.svg';

import AvailableStockToolTip from './AvailableStockToolTip';
import InventoryTooltip from './InventoryTooltip';
import Percentagebar from './Percentagebar';
import MTDSalesTooltip from 'pages/ServiceRiskReport/components/Table/operations/tooltip/MTDSalesTooltip';
import useProjectionData from '../Table/useProjectionData';
const KpiStatus = ({record}) => {
  const [projData, _] = useProjectionData(false, () => {});
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        background: '#FFF',
        height: '40px',
        border: '1px solid #F0F0F0',
        margin: '5px 0px 20px 0px',
        borderRadius: '2px',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginLeft: '11px',
          marginRight: '36px',
          marginTop: '11px',
          marginBottom: '9px',
        }}>
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <span
            style={{
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#888B8D',
            }}>
            Available Stock
          </span>
          <span
            style={{
              textAlign: 'center',
              fontFamily: 'Verdana',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#333',
            }}>
            {projData[0]?.avilablestock}
          </span>
          <AvailableStockToolTip>
            <img src={InfoCircle} />
          </AvailableStockToolTip>
        </div>
        <div style={{borderLeft: '1px solid #D6D6D6', height: '20px'}} />
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <span
            style={{
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#888B8D',
            }}>
            Inventory (Total)
          </span>
          <span
            style={{
              textAlign: 'center',
              fontFamily: 'Verdana',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#333',
            }}>
            {projData[0]?.totalinv}
          </span>
          <InventoryTooltip>
            <img src={InfoCircle} />
          </InventoryTooltip>
        </div>
        <div style={{borderLeft: '1px solid #D6D6D6', height: '20px'}} />
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <span
            style={{
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#888B8D',
            }}>
            Sales (MTD)
          </span>
          <MTDSalesTooltip record={record}>
            <Percentagebar
              percentage={record?.mmtd_sales}
              filledColor='#00A0DF'
              unfilledColor='#E5F5FC'
              timeLine={record?.mmtd_day}
            />
          </MTDSalesTooltip>
        </div>
        <div style={{borderLeft: '1px solid #D6D6D6', height: '20px'}} />
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <span
            style={{
              fontFamily: 'Verdana',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#888B8D',
            }}>
            Estimated recovery
          </span>
          <span
            style={{
              textAlign: 'center',
              fontFamily: 'Verdana',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#333',
            }}>
            {record?.est_rec}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(KpiStatus);
