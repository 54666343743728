import React from 'react';
import {Modal, Divider, Tooltip} from 'antd';
import displaycommentornotIcon from './../../../../../../assets/icon/displaycommentornot.svg';
import editIcon from './../../../../../../assets/icon/edit.svg';
import deleteIcon from './../../../../../../assets/icon/delete.svg';
import {useDispatch, useSelector} from 'react-redux';
import {
  getServiceRiskCommentList,
  deleteServiceRiskComment,
} from 'redux/actions/serviceRiskActions';
import EditCommentModal from './EditCommentModal';
import {AuthContext} from 'context/ContextProvider';
const SeeAllComments = (props) => {
  const dispatch = useDispatch();
  const {auth} = React.useContext(AuthContext);
  const user =
    auth?.tokenData?.givenName +
    ' ' +
    auth?.tokenData?.displayName.split(',')[0];
  const {commentList} = useSelector((state) => state.srCols);
  const [selectedCommentDetail, setSelectedCommentDetail] = React.useState({
    isVisible: false,
    product_id: '',
    location_id: '',
    type: 'SR',
    brand: '',
    comment: '',
    comment_id: '',
  });
  const getCommentsFun = React.useCallback(() => {
    dispatch(
      getServiceRiskCommentList({
        material: props.record['product_id'],
        location: props.record['location_id'],
      }),
    );
  }, [dispatch, props.record]);
  React.useEffect(() => {
    if (props.visible === true) {
      getCommentsFun();
    }
  }, [getCommentsFun, props.visible]);

  const deleteCommentFun = React.useCallback(
    ({material, location, commentId}) => {
      dispatch(
        deleteServiceRiskComment({
          material: material,
          location: location,
          commentId: commentId,
        }),
      );
    },
    [dispatch],
  );
  return (
    <>
      <EditCommentModal
        visible={selectedCommentDetail.isVisible}
        comment={selectedCommentDetail.comment}
        product_id={selectedCommentDetail.product_id}
        location_id={selectedCommentDetail.location_id}
        type={selectedCommentDetail.type}
        brand={selectedCommentDetail.brand}
        comment_id={selectedCommentDetail.comment_id}
        user={user}
        onClose={() =>
          setSelectedCommentDetail((prev) => {
            return {...prev, isVisible: false};
          })
        }
      />
      <Modal
        centered
        open={props.visible}
        footer={null}
        closeIcon={null}
        closable={false}
        width={523}
        onCancel={props.onCloseModal}
        bodyStyle={{
          padding: '0px',
          maxHeight: '600px',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '0px',
            alignItems: 'flex-end',
            gap: '12px',
          }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '10px 20px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '10px',
              alignSelf: 'stretch',
              borderRadius: '4px 4px 0px 0px',
              background: '#002766',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
                color: '#ffffff',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  flex: '1 0 0',
                }}>
                <span
                  style={{
                    alignSelf: 'stretch',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}>
                  {`${props.record['product_id']} ${props.record['prod_desc']}`}
                </span>
              </div>
            </div>
          </div>
          <div style={{maxHeight: '523px', width: '100%', overflowY: 'auto'}}>
            <ul style={{listStyle: 'none', padding: '0px', margin: '0px'}}>
              {commentList.map((obj, index) => (
                <li key={index} style={{width: '99%'}}>
                  <div
                    style={{
                      display: 'flex',
                      padding: '0px 20px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '4px',
                      alignSelf: 'stretch',
                      width: '100%',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        width: '100%',
                        alignSelf: 'stretch',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '4px',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '22px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: '0px',
                          }}>
                          {obj.updatedBy}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            fontSize: '14px',
                          }}>
                          {obj.isLatest === true ? (
                            <Tooltip title={'Display comment on Heatmap'}>
                              <img
                                src={displaycommentornotIcon}
                                width={12}
                                height={12}
                                style={{cursor: 'pointer'}}
                              />
                            </Tooltip>
                          ) : null}

                          <img
                            src={editIcon}
                            width={12}
                            height={12}
                            style={
                              user === obj['updatedBy']
                                ? {cursor: 'pointer'}
                                : {visibility: 'hidden'}
                            }
                            onClick={() => {
                              setSelectedCommentDetail((prev) => {
                                return {
                                  ...prev,
                                  product_id: String(obj.product_id),
                                  location_id: String(obj.location_id),
                                  type: 'SR',
                                  brand: props.record['brand'],
                                  comment: obj.comment,
                                  comment_id: obj.comment_id,
                                  isVisible: true,
                                };
                              });
                            }}
                          />
                          <img
                            src={deleteIcon}
                            width={12}
                            height={12}
                            style={
                              user === obj['updatedBy']
                                ? {cursor: 'pointer'}
                                : {visibility: 'hidden'}
                            }
                            onClick={() => {
                              deleteCommentFun({
                                material: String(obj.product_id),
                                location: String(obj.location_id),
                                commentId: String(obj.comment_id),
                              });
                            }}
                          />
                          <span style={{fontSize: '12px', color: '#BFBFBF'}}>
                            {obj.updatedAt}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px 12px',
                          alignItems: 'flex-start',
                          gap: '10px',
                          alignSelf: 'stretch',
                        }}>
                        <span
                          style={{
                            flex: '1 0 0',
                            color: '#000',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px',
                            overflow: 'auto',
                          }}>
                          {obj.comment}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{strokeWidth: '2px', padding: '0px', margin: '4px'}}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(SeeAllComments);
