import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import {getDaySupplyBGColor} from 'components/utility/getDaySupplyBGColor';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
import {store} from 'redux/store';
import {FilterFilled} from '@ant-design/icons';
const ServiceRiskMarketFilter = (data, index) => {
  return {
    filters: [],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const dispatch = useDispatch();
      const {filterList} = useSelector((state) => state.srCols);
      const [rangeKeys, setRangeKeys] = useState({});
      useEffect(() => {
        const marketFilterJson = {
          ...Object.keys(filterList[index])
            .map((item) => {
              return {[filterList[index][item]]: true};
            })
            .reduce((acc, curr) => {
              return {...acc, ...curr};
            }, {}),
        };
        const rangeKeysArray = Object.keys(rangeKeys).filter(
          (key) => rangeKeys[key],
        );

        if (rangeKeysArray.length != filterList[index].length) {
          let equal = true;
          // compare if stings in both arrays are equal
          for (let i = 0; i < filterList[index].length; i++) {
            let subEql = false;
            for (let j = 0; j < rangeKeysArray.length; j++) {
              if (rangeKeysArray[j] == filterList[index][i]) {
                subEql = true;
                break;
              }
            }
            if (!subEql) {
              equal = false;
              break;
            }
          }
          if (
            (rangeKeysArray.length == 0 && filterList[index].length != 0) ||
            (rangeKeysArray.length != 0 && filterList[index].length == 0)
          ) {
            equal = false;
          }
          if (!equal) {
            setRangeKeys(marketFilterJson);
            setSelectedKeys(
              filterList[index].length > 0 ? filterList[index] : [],
            );
          }
        }

        //   for (let i = 0; i < rangeKeysArray.length; i++) {
        //     let subEql = false;
        //     for (let j = 0; j < filterList[index].length; j++) {
        //       if (rangeKeysArray[i] == filterList[index][j]) {
        //         equal = true;
        //         break;
        //       }
        //     }
        //     if (!equal) {
        //       break;
        //     }
        // }

        // console.log(marketFilterJson, filterList[index]);
      }, [filterList[index]]);

      return (
        <div style={{padding: 8, display: 'flex', flexDirection: 'column'}}>

          {Array.from(new Set(data.map((item) => item.ctry))).map(
            (item, iterator) => (
              <Checkbox
                key={iterator}
                checked={rangeKeys[item] || false}
                onChange={(e) => {
                  setRangeKeys({
                    ...rangeKeys,
                    [item]: e.target.checked,
                  });
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {item}
                </div>
              </Checkbox>
            ),
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            }}>
            <Button
              type='primary'
              onClick={() => {
                setSelectedKeys(
                  Object.keys(rangeKeys).filter((key) => rangeKeys[key]),
                );
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: Object.keys(rangeKeys).filter(
                        (key) => rangeKeys[key],
                      ),
                    },
                  }),
                );
                if (
                  Object.keys(rangeKeys).filter((key) => rangeKeys[key])
                    .length == 0
                ) {
                  setSelectedKeys([]);
                }

                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Search
            </Button>
            <Button
              onClick={() => {
                // currStockInHandFilter = [];
                dispatch(
                  serviceRiskActions.updateFilterList({
                    filterList: {
                      [index]: [],
                    },
                  }),
                );
                setRangeKeys({
                  1: false,
                  2: false,
                  3: false,
                  4: false,
                  5: false,
                });
                setSelectedKeys([]);
                clearFilters();
                // confirm();
              }}
              size='small'
              style={{width: 90}}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () => {
      const {filterList} = useSelector((state) => state.srCols);
      return React.createElement(FilterFilled, {
        style: {color: filterList[index].length > 0 ? '#1890ff' : '#bfbfbf'},
      });
    },
    onFilter: (value, record) => {
      const state = store.getState();
      const filterList = state.srCols.filterList;
      return filterList.ctry.includes(record.ctry);
    },
    filterSearch: true,
  };
};

export default ServiceRiskMarketFilter;
