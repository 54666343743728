import React from 'react';
import {
  Input,
  Button,
  Card,
  Segmented,
  Space,
  DatePicker,
  Dropdown,
} from 'antd';
import moment from 'moment';
import more from '../../assets/icon/more.svg';
import ServiceRiskTable from './components/Table';
import KeysFrame from './components/KeysFrame';
import {useSelector} from 'react-redux';
import downloadExcel from 'components/utility/downloadExcel';
const {Search} = Input;
const {RangePicker} = DatePicker;
const ServiceRiskReport = () => {
  const {
    columns: serviceRiskColumns,
    data,
    columnData,
    scrollWidth,
    loading,
  } = useSelector((state) => state.srCols);
  return (
    <>
      <ServiceRiskTable />
    </>
  );
};

export default ServiceRiskReport;
