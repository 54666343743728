import {useEffect, useState} from 'react';
// import workerJs from './workers/filterWorker';
import {useDispatch, useSelector} from 'react-redux';
import {serviceRiskActions} from 'redux/reducers/serviceRiskSlice';
export const filterDataWithWorker = (worker, eventData) => {
  if (worker == null)
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  return new Promise((resolve, reject) => {
    if (!window.Worker) {
      reject(new Error('Web Workers are not supported in this environment.'));
      return;
    }

    // Create a new Web Worker instance

    // Set up the message handler for resolving the Promise when the result is received
    worker.onmessage = function (event) {
      resolve(event.data);
      // Clean up the worker after resolving the Promise
      //   worker.terminate();
    };

    // Set up the message handler for rejecting the Promise in case of an error
    worker.onerror = function (error) {
      reject(error);
      // Clean up the worker after rejecting the Promise
      //   worker.terminate();
    };

    // Post the data to the Web Worker
    worker.postMessage(eventData);
  });
};

const useFilters = (finalData, searchString, country, keysFilter) => {
  const dispatch = useDispatch();
  const {data, filterList} = useSelector((state) => state.srCols);
  // console.log('data: ', data);
  const [worker, setWorker] = useState(null);
  const [filteredData, setFilteredData] = useState(finalData);
  useEffect(() => {
    setWorker(new Worker('filterWorker.js'));
  }, []);
  const filterData = () => {
    filterDataWithWorker(worker, {
      data,
      searchString,
      country,
      keysFilter,
      filterList,
    }).then((data) => {
      setFilteredData(data);
      dispatch(
        serviceRiskActions.updateFilteredData({
          filteredData: data,
        }),
      );
    });
  };
  useEffect(() => {
    filterData();
  }, [data, searchString, country, keysFilter, filterList]);
  return filteredData;
};

export default useFilters;
