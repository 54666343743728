import React from 'react';
import {Tooltip, Modal} from 'antd';
import SeeAllCommentsModal from '../modal/SeeAllCommentsModal';
const CommentToolTip = (props) => {
  const [isSeeAllCommentModal, setIsSeeAllCommentModal] = React.useState(false);
  const onCloseModal = () => {
    setIsSeeAllCommentModal(false);
  };
  return (
    <>
      <Tooltip
        color={'rgba(0, 0, 0, 0.85)'}
        title={
          <div
            style={{
              display: 'inline-flex',
              padding: '0px',
              flexDirection: 'column',
              alignItems: 'center',
              height: '199px',
              width: '180px',
            }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                padding: '8px 8px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '5px',
                borderRadius: '2px',
                justifyContent: 'space-between',
              }}>
              <div>
                <span
                  style={{
                    fontSize: '12px',
                    color: 'rgba(255, 255, 255, 0.60)',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '18px',
                  }}>
                  {'Note:'}
                </span>
                <div
                  style={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '18px',
                    maxHeight: '80px',
                    overflowY: 'auto',
                    overflowWrap: 'anywhere',
                  }}>
                  {props.record['comment']}
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '22px',
                  }}>
                  <span style={{color: 'rgba(255, 255, 255, 0.60)'}}>
                    {'Date: '}
                  </span>
                  {props.record['commentdt']}
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '22px',
                  }}>
                  <span style={{color: 'rgba(255, 255, 255, 0.60)'}}>
                    {'By: '}
                  </span>
                  {props.record['commentby']}
                </div>
                <div
                  style={{
                    color: '#359EFF',
                    fontWeight: '400',
                    lineHeight: '20px',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsSeeAllCommentModal(true)}>
                  {'See all comments'}
                </div>
              </div>
            </div>
          </div>
        }>
        {props.children}
      </Tooltip>
      <SeeAllCommentsModal
        visible={isSeeAllCommentModal}
        onCloseModal={onCloseModal}
        record={props.record}
      />
    </>
  );
};

export default React.memo(CommentToolTip);
