import {useState, useMemo} from 'react';

const UseSelectionBounds = () => {
  const [totalSum, setTotalSum] = useState(0);

  const [selectionBounds, setSelectionBounds] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const selectionArea = useMemo(
    () => ({
      x1: selectionBounds.x1,
      y1: selectionBounds.y1,
      x2: selectionBounds.x2,
      y2: selectionBounds.y2,
    }),
    [selectionBounds],
  );
  return {
    totalSum,
    setTotalSum,
    selectionBounds,
    setSelectionBounds,
    selectionArea,
  };
};

export default UseSelectionBounds;
