import ToolTipBar from '../tooltip/index';
import {getDaySupplyBGColor} from './../../../../../../components/utility/getDaySupplyBGColor';
const DaysSupplyRender = ({item, title}) => {
  return {
    render: (text, record) => {
      return {
        props: {
          style: {
            // color: '#fff',
            ...{color: record[item.Week + '_cc'] == 4 ? '#000' : '#fff'},

            fontWeight: 'bold',
            textAlign: 'center',
            background: getDaySupplyBGColor(record[item.Week + '_cc']),
          },
        },
        children: (
          <ToolTipBar
            title={title}
            material={record['product_id']}
            location_id={record['location_id']}
            week={item.week_num}
            month={item.month_value}>
            <div
              style={{
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                fontStyle: 'normal',
              }}>
              {text}
            </div>
          </ToolTipBar>
        ),
      };
    },
  };
};

export default DaysSupplyRender;
