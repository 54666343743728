import {AuthContext} from 'context/ContextProvider';
import AddCommentDropdown from 'pages/ServiceRiskReport/components/Comments/operations/dropdown/AddCommentDropdown';
import CommentToolTip from 'pages/ServiceRiskReport/components/Comments/operations/tooltip/CommentToolTip';
import React from 'react';
import {useDispatch} from 'react-redux';
import {saveServiceRiskComment} from 'redux/actions/serviceRiskActions';
import More from './../../../../../../assets/icon/ActionMore.svg';
import Comment from './../../../../../../assets/icon/Comment.svg';
import DotOnIcon from './../../../../../../assets/icon/DotOnIcon.svg';
import {useNavigate} from 'react-router-dom';

const ActionRender = ({record}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {auth} = React.useContext(AuthContext);
  const SaveCommentFun = React.useCallback(
    ({commentValue}) => {
      dispatch(
        saveServiceRiskComment({
          material: record['product_id'],
          location: record['location_id'],
          type: 'SR',
          brand: record['brand'],
          comment: commentValue,
          user:
            auth?.tokenData?.givenName +
            ' ' +
            auth?.tokenData?.displayName.split(',')[0],
        }),
      );
    },
    [
      dispatch,
      auth?.tokenData?.displayName,
      auth?.tokenData?.givenName,
      record,
    ],
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <img
        src={DotOnIcon}
        style={
          record['simflag'] === true
            ? {cursor: 'pointer'}
            : {visibility: 'hidden'}
        }
        onClick={() =>
          navigate(
            `/ServiceRiskReport/${record['product_id']},${record['location_id']}`,
            {state: {record: record, action: false}},
          )
        }
      />
      {record['commflag'] === true ? (
        <CommentToolTip record={record}>
          <img src={Comment} style={{cursor: 'pointer'}} />
        </CommentToolTip>
      ) : (
        <img src={Comment} style={{display: 'none'}} />
      )}

      <AddCommentDropdown record={record} onSave={SaveCommentFun}>
        <img src={More} style={{cursor: 'pointer'}} />
      </AddCommentDropdown>
    </div>
  );
};

export default ActionRender;
