import {Select} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

const CountryDropDown = ({countryList, onChange}) => {
  const {headerDropDowns} = useSelector((state) => state.prSims);
  const updateCountryFun = React.useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );
  return (
    <>
      <Select
        value={headerDropDowns.selectedCountry}
        placeholder={'Select Country'}
        style={{
          width: 120,
        }}
        onChange={updateCountryFun}
        options={countryList}
      />
    </>
  );
};

export default React.memo(CountryDropDown);
