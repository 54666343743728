import React from 'react';
import { Tag } from 'antd';

const ExpireDate = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '36px',
                marginLeft: '24px',
                marginRight: '24px',
                marginTop: '30px',
                marginBottom: '30px',
            }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                <span
                    style={{
                        color: '#333',
                        fontFamily: 'Verdana',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}>
                    Expire Date:
                </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '5px' }}>
                <Tag
                    style={{
                        color: '#333333',
                        margin: '0px',
                        borderRadius: '2px',
                        border: '1px solid #D6D6D6',
                        fontFamily: 'Verdana',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}
                    color='#F4F4F4'>
                    Reminding /Total Shelf life in Days
                </Tag>

                <Tag
                    style={{
                        color: '#333333',
                        margin: '0px',
                        borderRadius: '2px',
                        border: '1px solid #D6D6D6',
                        fontFamily: 'Verdana',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}
                    color='#F4F4F4'>
                    Reminding /Total Shelf life in %
                </Tag>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                <span
                    style={{
                        color: '#333',
                        fontFamily: 'Verdana',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}>
                    MAD - Material Available Date
                </span>
            </div>

        </div>
    )

}
export default React.memo(ExpireDate)