import DaysSupplyRender from '../render/DaysSupplyRender';
const DaysSupplyColumn = ({data, addColumn}) => {
  return data.map((item) => {
    let raw_date = item.value?.split('.');
    let date = new Date();
    let title = '';
    let day = '';
    let month = '';
    let year = '';
    if (raw_date.length === 3) {
      date.setFullYear(raw_date[2], raw_date[1], raw_date[0]);
      date.setMonth(parseInt(raw_date[1]) - 1);
      date.setDate(raw_date[0]);
      month = date.toLocaleString('default', {month: 'short'});
      day = date.getDate();
      day = day < 10 ? '0' + day : day;

      title = month + ' ' + day;
    } else if (raw_date.length === 2) {
      date.setFullYear(raw_date[1]);
      date.setMonth(raw_date[0] - 1);
      month = date.toLocaleString('default', {month: 'short'});
      year = date.getFullYear().toString().slice(-2);
      title = month + ' ' + year;
    }
    return addColumn({
      title: item.value == 'Initial' ? item.value : title,
      dataIndex: item.Week,
      key: item.Week,
      width: 50,
      ...DaysSupplyRender({
        item: item,
        title: title,
      }),
      shouldCellUpdate: (record, prevRecord) => {
        return record[item.Week] !== prevRecord[item.Week];
      },
    });
  });
};
export default DaysSupplyColumn;
