import axios from 'components/services/lib/axios';
import {serviceRiskActions} from '../reducers/serviceRiskSlice';
import {
  GetServiceRiskDataURL,
  GetServiceRiskColumnsURL,
  GetServiceRiskDOSTooltipURL,
  GetServiceRiskMTDSalesTooltipURL,
  SaveServiceRiskCommentURL,
  GetServiceRiskCommentListURL,
  DeleteServiceRiskCommentURL,
} from 'components/services/lib/URLs';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

export const useServiceRiskData = ({location}) => {
  const dispatch = useDispatch();
  const fetchData = () => {
    // const resData = await axios.get(
    //   'https://sbx.jnjux.com/public/serverscript/api_enablement_tool/test2',
    // );
    // const resColumns = await axios.get(
    //   'https://sbx.jnjux.com/public/serverscript/api_enablement_tool/test1',
    // );
    const resData = axios.post(GetServiceRiskDataURL, {location: location});
    const resColumns = axios.post(GetServiceRiskColumnsURL);
    return Promise.all([resData, resColumns]);
    // return {data: resData?.data, columns: resColumns?.data};
  };
  useEffect(() => {
    dispatch(
      serviceRiskActions.updateLoading({
        loading: true,
      }),
    );
    fetchData().then(([res1, res2]) => {
      const data = res1?.data.map((obj) => {
        if (obj.gmc == null) {
          return {...obj, gmc: ''};
        } else {
          return obj;
        }
      });
      dispatch(
        serviceRiskActions.updateData({
          data: data,
          columnData: res2?.data,
        }),
      );
      dispatch(
        serviceRiskActions.updateLoading({
          loading: false,
        }),
      );
    });
  }, []);
};

export const getServiceRiskData = ({location}) => {
  return async (dispatch) => {
    try {
      dispatch(
        serviceRiskActions.updateLoading({
          loading: true,
        }),
      );
      const fetchData = async () => {
        // const resData = await axios.get(
        //   'https://sbx.jnjux.com/public/serverscript/api_enablement_tool/test2',
        // );
        // const resColumns = await axios.get(
        //   'https://sbx.jnjux.com/public/serverscript/api_enablement_tool/test1',
        // );
        //const resData = await axios.post(GetServiceRiskDataURL);
        //const resColumns = await axios.post(GetServiceRiskColumnsURL);
        const [resData, resColumns] = await Promise.all([
          axios.post(GetServiceRiskDataURL, {location: location}),
          axios.post(GetServiceRiskColumnsURL),
        ]);
        return {data: resData?.data, columns: resColumns?.data};
      };
      const res = await fetchData();
      dispatch(
        serviceRiskActions.updateData({
          data: res?.data,
          columnData: res?.columns,
        }),
      );
      dispatch(
        serviceRiskActions.updateLoading({
          loading: false,
        }),
      );
    } catch (e) {
      dispatch(
        serviceRiskActions.updateLoading({
          loading: false,
        }),
      );
    }
  };
};
export const getServiceDOSTooltipData = ({
  material,
  location_id,
  week,
  month,
}) => {
  return async (dispatch) => {
    try {
      const fetchData = async () => {
        const resData = await axios.post(GetServiceRiskDOSTooltipURL, {
          material: material,
          location_id: location_id,
          week: week,
          month: month,
        });
        return {data: resData?.data};
      };
      const res = await fetchData();
      dispatch(
        serviceRiskActions.updateDosTooltip({
          dosTooltipData: res?.data,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const getServiceMTDSalesTooltipData = ({material, location_id}) => {
  return async (dispatch) => {
    try {
      const fetchData = async () => {
        const resData = await axios.post(GetServiceRiskMTDSalesTooltipURL, {
          material: material,
          location_id: location_id,
        });
        return {data: resData?.data};
      };
      const res = await fetchData();
      dispatch(
        serviceRiskActions.updateMTDSalesTooltip({
          mtdSalesTooltipData: res?.data,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const saveServiceRiskComment = ({
  material,
  location,
  type,
  brand,
  comment,
  user,
  assignedTo
}) => {
  return async (dispatch) => {
    try {
      dispatch(
        serviceRiskActions.updateSaveCommentLoading({
          saveCommentLoading: true,
        }),
      );
      const fetchData = async () => {
        const resData = await axios.post(SaveServiceRiskCommentURL, {
          product_id: material,
          location_id: location,
          type: type,
          brand: brand,
          comment: comment,
          user: user,
          assignedTo:assignedTo
        });
        return {data: resData?.data};
      };
      const getCommentList = async () => {
        const resData = await axios.post(GetServiceRiskCommentListURL, {
          product_id: material,
          location_id: location,
        });
        return {data: resData?.data};
      };
      await fetchData();
      const res = await getCommentList();
      dispatch(serviceRiskActions.updateCommentList({commentList: res?.data}));
      dispatch(
        serviceRiskActions.saveCommentInData({
          product_id: material,
          location_id: location,
          brand: brand,
        }),
      );
      dispatch(
        serviceRiskActions.updateSaveCommentLoading({
          saveCommentLoading: false,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const getServiceRiskCommentList = ({material, location}) => {
  return async (dispatch) => {
    try {
      dispatch(serviceRiskActions.updateCommentList({commentList: []}));
      const getCommentList = async () => {
        const resData = await axios.post(GetServiceRiskCommentListURL, {
          product_id: material,
          location_id: location,
        });
        return {data: resData?.data};
      };
      const res = await getCommentList();
      dispatch(serviceRiskActions.updateCommentList({commentList: res?.data}));
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const deleteServiceRiskComment = ({material, location, commentId}) => {
  return async (dispatch) => {
    try {
      const deleteComment = async () => {
        const resData = await axios.post(DeleteServiceRiskCommentURL, {
          product_id: material,
          location_id: location,
          comment_id: commentId,
        });
        return {data: resData?.data};
      };
      const res = await deleteComment();
      dispatch(
        serviceRiskActions.deleteCommentInData({
          product_id: material,
          location_id: location,
          comment_id: commentId,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const updateServiceRiskComment = ({
  comment,
  product_id,
  location_id,
  type,
  brand,
  comment_id,
  user,
  assignedTo
}) => {
  return async (dispatch) => {
    try {
      dispatch(
        serviceRiskActions.updateLoading({
          loading: true,
        }),
      );
      const updateComment = async () => {
        const resData = await axios.post(SaveServiceRiskCommentURL, {
          product_id: product_id,
          location_id: location_id,
          comment_id: comment_id,
          comment: comment,
          type: type,
          brand: brand,
          user: user,
          assignedTo:assignedTo
        });
        return {data: resData?.data};
      };
      const res = await updateComment();
      dispatch(
        serviceRiskActions.updateCommentInData({
          product_id: product_id,
          location_id: location_id,
          comment_id: comment_id,
          comment: comment,
          type: type,
          brand: brand,
          user: user,
          updatedAt: res?.data,
          assignedTo:assignedTo
        }),
      );
      dispatch(
        serviceRiskActions.updateLoading({
          loading: false,
        }),
      );
    } catch (e) {
      dispatch(
        serviceRiskActions.updateLoading({
          loading: false,
        }),
      );
      console.log('error', e);
    }
  };
};
export const getServiceRiskColumns = () => {
  return async (dispatch) => {
    try {
      const fetchData = async () => {
        const [resColumns] = await Promise.all([
          axios.post(GetServiceRiskColumnsURL),
        ]);
        return {columns: resColumns?.data};
      };
      const res = await fetchData();
      dispatch(
        serviceRiskActions.updateColumns({
          columnData: res?.columns,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
export const getServiceRiskDataOnly = ({location}) => {
  return async (dispatch) => {
    try {
      const fetchData = async () => {
        const [resData] = await Promise.all([
          axios.post(GetServiceRiskDataURL, {location: location}),
        ]);
        return {data: resData?.data};
      };
      const res = await fetchData();
      dispatch(
        serviceRiskActions.updateServiceRiskDataOnly({
          data: res?.data,
        }),
      );
    } catch (e) {
      console.log('error', e);
    }
  };
};
