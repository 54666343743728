import React from 'react';
import {configureStore} from '@reduxjs/toolkit';
import serviceRiskSlice from '../reducers/serviceRiskSlice';
import projectionSimulationSlice from 'redux/reducers/projectionSimulationSlice';
export const store = configureStore({
  reducer: {
    // add reducers here
    srCols: serviceRiskSlice,
    prSims: projectionSimulationSlice,
  },
});
